﻿.image-gallery {
	&-wrapper {
		position: relative;
		padding-top: 66.6667%;
	}

	&-img {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		opacity: 0;
		transition: opacity $default-transition;

		&--active {
			z-index: 2;
			opacity: 1;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	&-navigation {
		display: flex;
		flex-wrap: wrap;
		margin-top: 10px;

		li {
			margin: 0 10px 10px 0;
		}

		picture, img {
			position: absolute;
			top: 0;
			left: 50%;
			z-index: 1;
			display: block;
			width: auto;
			height: 60px;
			opacity: 0.5;
			transform: translate(-50%, 0);
			transition: opacity $default-transition;
		}

		&-item {
			display: block;
			position: relative;
			width: 60px;
			height: 60px;
			overflow: hidden;
		}

		&-item:hover, &-item--active {
			picture, img {
				opacity: 1;
			}

			&::after {
				content: '';
				width: 60px;
				height: 1px;
				background-color: $color-red;
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: 2;
			}
		}
	}
}
