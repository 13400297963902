.header {
	position: relative;
	z-index: 10;
	background-color: $color-white;
	border-bottom: 1px solid $color-light-gray;

	@include screen-lg-max {
		padding-top: 59px;
	}

	&-wrapper {
		@include screen-lg-max {
			position: absolute;
			top: 60px;
			left: 0;
			display: flex;
			flex-direction: column;
			width: 100vw;
			height: calc(100vh - 60px); /* fallback */
			height: calc(100dvh - 60px);
			transform: translate(-110%, 0);
			transition: transform 0.3s ease-in;
			background-color: $color-white;
			overflow-y: auto;
		}
	}

	&-topbar, &-main {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 $page-gutter;

		@include screen-lg-max {
			padding: 0;
		}
	}

	&-topbar {
		height: 39px;
		border-bottom: 1px solid $color-light-gray;

		@include screen-lg-max {
			order: 2;
			border: 0;
			display: block;
			position: relative;
		}

		&-brands {
			display: flex;
			align-items: center;
			height: 28px;
			max-width: 690px;
			justify-content: space-between;
			margin-left: auto;
  			margin-right: auto;

			@include screen-lg-max {
				order: 2;
				flex-wrap: wrap;
				width: 100%;
				padding-top: 123px;
				padding-bottom: 162px;		
			}

			& > li {
				padding-right: 14px;
				@include screen-lg-max {
					padding-right: 0;
				}
				&:last-child {
					padding-right: 0px;
				}
				&.hammerhead-logo-inline {
					width: calc(100% * 2);

					@include screen-lg-max {
						width: calc(15% * 1.5);
					}
				}



				@include screen-lg-max {
					margin: 0;
					width: 50%;
					text-align: center;
					height: 82px;
					border-bottom: 1px solid $color-light-gray;
					display: flex;
					align-items: center;
				}
			}
		}

		&-logo {
			display: block;
			text-decoration: none;

			@include screen-lg-max {
				margin: 0 auto;
			}

			svg {
				fill: $color-dark-gray;
				display: block;
				width: 100%;
				height: 100%;
				transition: fill $default-transition;
			}

			&--sram {
				width: 56px;
				height: 8px;

				@include screen-lg-max {
					width: 89px;
					height: 12px;
				}
			}

			&--rockshox {
				width: 47px;
				height: 12px;

				@include screen-lg-max {
					width: 80px;
					height: 20px;
				}
			}

			&--truvativ {
				width: 55px;
				height: 9px;

				@include screen-lg-max {
					width: 101px;
					height: 13px;
				}
			}

			&--zipp {
				width: 51px;
				height: 11px;

				@include screen-lg-max {
					width: 108px;
					height: 18px;
				}
			}

			&--quarq {
				width: 56px;
				height: 11px;

				@include screen-lg-max {
					width: 94px;
					height: 16px;
				}
			}

			&--timesport {
				width: 56px;
				height: 11px;

				@include screen-lg-max {
					width: 94px;
					height: 16px;
				}
			}

			&--hammerhead {
				width: 85px;
				height: 28px;

				@include screen-lg-max {
					width: 120px;
					height: 55px;
					margin-top: 0;
				}
			}

			&--wbr {
				width: 54px;
				height: 28px;

				@include screen-lg-max {
					width: 120px;
					height: 55px;
					margin-top: 0;
				}
			}

			&--velocio {
				width: 45.3px;
  				height: 28px;
				@include screen-lg-max {
					width: 79.3px;
					margin-top: 0;
				}
				svg {
					padding-bottom: 5px;
					@include screen-lg-max {
						padding-bottom: 0px;
					}
				}
			}

			&--active, &:hover {
				svg {
					fill: $color-black;
				}
			}
		}

		&-utility {
			display: flex;
			align-items: center;
			position: absolute;
			right: 0;

			@include screen-lg-max {
				order: 1;
				align-items: flex-start;
				flex-wrap: wrap;
				width: 100%;
				height: 123px;
				position: absolute;
				top: 0;
				left: 0;
			}

			a {
				color: $color-dark-gray;
				font: 10px/1 $font-medium;
				letter-spacing: 1.25px;
				text-transform: uppercase;
				padding-bottom: 2px;
				border-bottom: 2px solid $color-white;

				@include screen-lg-max {
					font: 12px/1 $font-bold;
				}
			}

			li:hover a, a:hover {
				color: $color-black;
				border-color: $color-red;
			}

			& > li {
				margin-right: 0.8rem !important;
				@media only screen and (max-width: 1200px) and (min-width: 992px) {
					margin-right: 0.75rem !important;
				}

				@include screen-lg-max {
					margin: 0;
					width: 50%;
					border-top: 1px solid $color-light-gray;
					border-bottom: 1px solid $color-light-gray;
				}

				&:nth-child(3) {
					margin-right: 31px;

					@include screen-lg-max {
						margin: 0;
						width: 100%;
						border-bottom: 0;

						a {
							display: block;
							padding: 28px 20px;
							color: $color-black;
							font-family: $font-medium;
						}
					}
				}

				&:last-child {
					margin-right: 0;
				}

				.v-cart-header {
					svg {
						width: 16px;
						height: 16px;
					}
				}
			}
		}

		@media (min-width: 1025px) {
			&--dark {
				background-color: $color-xdark-gray;

				.header-topbar-logo {

					svg {
						fill: $color-gray;
					}

					&--active, &:hover {
						svg {
							fill: $color-white;
						}
					}
				}

				.header-topbar-utility {
					svg {
						fill: $color-gray;
					}

					& > li:last-child,
					& > li > a {
						color: $color-gray;
						border-color: $color-xdark-gray;
					}

					& > li:hover > a, & > li > a:hover {
						color: $color-white;
						border-color: $color-red;
					}
				}
			}
		}
	}

	&-main {
		height: 79px;

		@include screen-lg-max {
			display: block;
			height: auto !important;
			order: 1;
		}

		&-nav, &-utility {
			width: 50%;
			display: flex;

			@include screen-lg-max {
				width: 100%;
				display: block;
			}

			li {
				@include screen-lg-max {
					border-bottom: 1px solid $color-light-gray;
				}
			}
		}

		&-nav {
			padding-right: 10%;

			@include screen-lg-max {
				padding-right: 0;
			}

			li {
				margin-right: 34px;

				&:hover {
					div.header-megamenu {
						max-height: 700px;
					}
				}

				a:focus + div.header-megamenu {
					max-height: 700px;
				}

				div.header-megamenu:focus-within {
					max-height: 700px;
				}
			}

			&--service-mobile {
				display: none;

				@include screen-lg-max {
					background-color: $color-xlight-gray;
					display: block;
				}
			}
		}

		&-utility {
			padding-left: 10%;
			justify-content: flex-end;

			@include screen-lg-max {
				justify-content: flex-start;
				padding: 0;
			}

			li {
				margin-left: 34px;

				@include screen-lg-max {
					border-bottom: 1px solid $color-light-gray;
					margin-left: 0;

					&:last-child {
						border: 0;
						height: 69px;
					}
				}
			}

			a.link-dealer {
				color: $color-red;

				@include screen-lg-max {
					color: $color-black;
				}
			}

			&-search {
				a {
					&::before {
						content: $icon-search;
						font-family: $font-icons;
						margin-right: 7px;
						position: relative;
						top: 1px;
					}
				}
			}

			&-search, &-cart {
				@include screen-lg-max {
					display: none;
				}
			}
		}

		&-section-title {
			color: $color-black;
			font: 12px/1 $font-condensed-bold;
			letter-spacing: 10px;
			text-transform: uppercase;
			border-right: 1px solid $color-light-gray;
			padding: 24px 30px 23px 0;
			margin-right: 30px;

			a {
				color: $color-black;
			}

			@include screen-lg-max {
				display: none;
			}
		}

		&--alt {
			height: 59px;

			.header-main-nav {
				width: 100%;
			}
		}
	}

	a.level0{
		display: inline-block;
		color: $color-black;
		font: 12px/1 $font-medium;
		letter-spacing: 1.5px;
		text-transform: uppercase;
		padding: 9px 0 7px;
		border-bottom: 2px solid $color-white;

		@include screen-lg-max {
			padding: 29px $page-gutter-sm 27px $page-gutter-sm;
		}

		&:hover {
			border-color: $color-red;

			@include screen-lg-max {
				border-color: $color-white;
			}
		}
	}

	&-megamenu {
		background-color: $color-white;
		position: absolute;
		left: 0;
		top: 119px;
		width: 100%;
		max-height: 0;
		overflow: hidden;
		box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.08);
		transition: max-height 0.4s ease-in;

		@include screen-lg-max {
			position: static;
			max-height: none;
			width: 100%;
			box-shadow: none;
			overflow: visible;

			.container {
				position: static;
			}
		}

		.outer-container {
			padding: 69px 0 130px;

			@include screen-lg-max {
				padding: 0;
			}
		}

		&-section {
			@include screen-lg-max {
				float: none !important;
				width: 100% !important;
				margin: 0 !important;
			}

			&-title {
				display: block;
				font: 15px/1 $font-condensed-bold;
				text-transform: uppercase;
				letter-spacing: 1.5px;
				color: $color-dark-red;
				margin-bottom: 7px;
				width: 100%;
				position: relative;

				@include screen-lg-max {
					border-top: 1px solid $color-light-gray;
					font: 14px/1 $font-regular;
					text-transform: none;
					letter-spacing: 0.5px;
					color: $color-xdark-gray;
					padding: 24px 0 17px 0;
					cursor: pointer;

					&::after {
						content: $icon-carat;
						font-family: $font-icons;
						font-size: 11px;
						color: $color-black;
						margin-left: 9px;
						position: absolute;
						right: 0;
						top: 24px;
					}
				}
			}

			a.header-megamenu-section-title {
				&::after {
					content: $icon-medium-arrow;
					font-family: $font-icons;
					font-size: 11px;
					color: $color-red;
					margin-left: 9px;
					position: relative;
					top: -1px;

					@include screen-lg-max {
						content: $icon-carat;
						color: $color-black;
						position: absolute;
						right: 0;
						top: 24px;
					}
				}
			}

			&-subtitle {
				font: 12px/1 $font-bold;
				text-transform: uppercase;
				letter-spacing: 1.5px;
				color: $color-dark-gray;
				border-bottom: 1px solid $color-dark-gray;
				padding-bottom: 6px;
				margin: -4px 0 12px 0;

				@include screen-lg-max {
					display: none;
				}
			}

			li:not(:first-child) .header-megamenu-section-subtitle {
				margin-top: 25px;
			}

			&-wrapper {
				@include screen-lg-max {
					position: absolute;
					top: 0;
					left: 0;
					z-index: 10;
					display: block;
					width: 100vw;
					height: calc(100vh - 60px);
					transform: translate(-110%, 0);
					transition: transform 0.3s ease-in;
					background-color: $color-white;
					overflow-y: auto;

					&--open {
						transform: translate(0, 0);
					}
				}
			}

			&-back {
				display: none;

				@include screen-lg-max {
					display: block;
					font: 12px/1 $font-medium;
					text-transform: uppercase;
					letter-spacing: 1.5px;
					color: $color-black;
					border-bottom: 1px solid $color-gray;
					padding: 28px 20px 29px;

					&::before {
						content: $icon-long-arrow;
						font-family: $font-icons;
						transform: rotate(180deg);
						margin-right: 22px;
						display: inline-block;
					}
				}
			}

			&-column {
				@include span-columns(2 of 2);

				@include screen-lg-max {
					//display: none !important;
					float: none !important;
					width: 100% !important;
					margin: 0 !important;
				}
			}

			&.col-4 .header-megamenu-section-column {
				@include span-columns(2 of 4);
			}

			&.col-6 .header-megamenu-section-column {
				@include span-columns(2 of 6);
			}

			&.col-8 .header-megamenu-section-column {
				@include span-columns(2 of 8);
			}

			&.col-10 .header-megamenu-section-column {
				@include span-columns(2 of 10);
			}

			&.col-12 .header-megamenu-section-column {
				@include span-columns(2 of 12);
			}
		}

		ul.level1 {
			font-size: 16px;
			line-height: 1.5em;
			letter-spacing: 1px;
			margin: 14px 0 14px 0;

			@include screen-lg-max {
				margin: 0;
			}

			li {
				margin-bottom: 10px;

				@include screen-lg-max {
					border: 0;
					margin-bottom: 0;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}

			a {
				color: $color-black;
				padding: 8px 8px 8px 0;

				@include screen-lg-max {
					display: block;
					font-size: 14px;
					padding: 19px 20px;
					border-bottom: 1px solid $color-light-gray;
				}

				&:hover {
					color: $color-dark-gray;
				}

				&.strong {
					font-family: $font-medium;
					color: $color-black;

					&:hover {
						color: $color-xdark-gray;
					}
				}

				&.arrow {
					&::after {
						content: $icon-medium-arrow;
						font-family: $font-icons;
						font-size: 11px;
						margin-left: 9px;
						position: relative;
						top: -1px;
					}
				}
			}
		}

		&-button {
			position: absolute;
			top: 16px;
			left: 0;

			@include screen-xl-max {
				left: 30px;
			}

			@include screen-lg-max {
				display: none;
			}
		}
	}

	&-branding {
		position: absolute;
		left: 50%;
		top: 68px;
		transform: translate(-50%, 0);
		z-index: 2;

		@include screen-lg-max {
			top: 22px;
		}

		svg {
			display: block;
			width: 154px;
			height: 20px;
			margin: 0 auto;
			fill: $color-red;

			@include screen-lg-max {
				width: 120px;
				height: 16px;
			}
		}

		&--alt {
			display: none;

			@include screen-lg-max {
				display: block;
				top: 14px;

				a {
					color: $color-black;
					font: 12px/1 $font-condensed-bold;
					letter-spacing: 10px;
					text-transform: uppercase;
				}
			}
		}
	}
	#service-link {
		margin-right: 0;
	}

	&-service-search-bar {
		display: flex;
		flex-grow: 1;
		margin: 0 -30px;

		@include screen-lg-max {
			display: none;
		}

		input[type=text] {
			background-color: $color-xxlight-gray;
			border-bottom: none;
			font-size: 12px;
			font-family: $font-medium;
			color: $color-xdark-gray;
			padding: 0 30px;
			letter-spacing: 3px;

			&::-webkit-input-placeholder {
				opacity: 1;
				text-transform: uppercase;
				color: $color-xdark-gray;
			}

			&::-moz-placeholder {
				opacity: 1;
				text-transform: uppercase;
				color: $color-xdark-gray;
			}

			&:-ms-input-placeholder {
				opacity: 1;
				text-transform: uppercase;
				color: $color-xdark-gray;
			}
		}

		.button-search {
			width: 60px;
			height: 59px;
			background-color: $color-light-gray;
			border: none;

			&::before {
				content: $icon-search;
				font-family: $font-icons;
				font-size: 20px;
				color: $color-black;
				margin-right: 7px;
				position: relative;
				top: 1px;
			}
		}

		p {
			padding: 0 30px;

			a {
				color: $color-red;
				font: 12px/59px $font-medium;
				text-transform: uppercase;
				letter-spacing: 1.5px;
				white-space: nowrap;
			}
		}
	}

	&-service-nav {
		@media (min-width: 1025px) {
			position: relative;
			display: flex;
			background: #f1f3f4;
			height: 100%;
			align-items: center;
			margin-right: auto;
			width: 100%;

			&--append {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: center;
				margin-left: auto;
			}

			.header-main-nav {
				// margin: 0 auto;
				margin-left: 30px;
				// padding: 11px 150px 12px;
				padding-right: 0;
				justify-content: center;
				width: auto;
				li a {
					white-space: nowrap;
					border-bottom: 2px solid #f1f3f4;
				}
				
			}
			.button-search {
				width: 60px;
				height: 59px;
				background-color: #e8eaed;
				border: none;
				&:hover, &:focus, &:active{
					background: $color-light-gray;
				}
				&::before {
					content: $icon-search;
					font-family: $font-icons;
					font-size: 20px;
					color: $color-black;
					position: relative;
					top: 1px;
				}
				

				&.active{
					background: $color-gray;
				}
			}
			.header-service-callout{
				background: #fff;
				white-space: nowrap;
				height: 100%;
				padding: 15px 0 15px 30px;
				display: flex;
				align-items: center;
				a {
					text-decoration: uppercase;
					color: $color-red;
				}
			}
		}

		@include screen-lg-max {
			.link-dealer {
				display: none;
			}
		}
	}

	&-search {
		height: 60px;
		display: none;
		opacity: 0;
		width: 100%;
		position: absolute;
		z-index: 100;
		bottom: -60px;
		overflow: hidden;
		transition: opacity $default-transition;

		input {
			width: 100%;
			height: 60px;
			font-size: 14px;
			padding-left: 64px;
			padding-right: 64px;
			border: 1px solid $color-xdark-gray;
		}

		::-webkit-input-placeholder {
			color: $color-dark-gray;
			opacity: 1;
		}

		::-moz-placeholder {
			color: $color-dark-gray;
			opacity: 1;
		}

		:-ms-input-placeholder {
			color: $color-dark-gray !important;
			opacity: 1;
		}

		&-submit {
			position: absolute;
			left: 19px;
			top: 12px;
			width: 35px;
			height: 35px;
			background-color: $color-white;
			border: none;

			&::before {
				content: $icon-search;
				font-family: $font-icons;
				font-size: 17px;
				color: $color-black;
				position: relative;
			}
		}

		&-cancel {
			display: block;
			position: absolute;
			right: 13px;
			top: 15px;
			width: 35px;
			height: 35px;

			&::before {
				content: $icon-close;
				font-family: $font-icons;
				font-size: 18px;
				color: $color-black;
			}
		}

		&--open {
			opacity: 1;
			display: block;
		}
	}

	&-mobile {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 60px;
		display: none;
		justify-content: space-between;
		align-items: center;
		padding: 0 20px;

		@include screen-lg-max {
			display: flex;
		}

		li {
			&:nth-child(2) {
				margin-left: auto;
			}
		}

		&-toggle {
			display: none;
			cursor: pointer;

			@include screen-lg-max {
				display: block;
			}

			&::before {
				font-family: $font-icons;
				font-size: 18px;
				color: $color-black;
			}

			&--menu::before {
				content: $icon-menu;
			}

			&--search::before {
				content: $icon-search;
			}
		}
	}

	&--menuopen {
		.header-wrapper {
			transform: translate(0, 0);
		}

		.header-mobile-toggle--menu {
			&::before {
				content: $icon-close;
			}
		}
	}

	&--submenuopen {
		.header-wrapper {
			overflow-y: hidden;
		}
	}
}