﻿.overview-feature-promo {
	position: relative;
	width: 100%;
	height: 42.1vw;
	overflow: hidden;
	margin-bottom: -1px;

	@include screen-md-max {
		height: auto !important;
	}
	
	@include screen-xl-max {
		height: 55.1vw;
	}

	&--left {
		.overview-feature-promo-container {
			left: 0;
			transform: translateX(-100%);
		}

		.overview-feature-promo-asset video {
			right: 0;
			left: auto;
		}
	}

	&--right {
		.overview-feature-promo-container {
			right: 0;
			transform: translateX(100%);
		}
	}

	&-asset {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;

		@include screen-md-max {
			position: relative;
			padding-top: 78.4%;
			overflow: hidden;
			height: auto;
		}

		video, img {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: 50% 50%;
		}
	}

	&-container {
		position: absolute;
		z-index: 2;
		display: flex;
		justify-content: space-around;
		flex-direction: column;
		align-items: center;
		width: 40%;
		height: 100%;
		background: $color-red;
		transition: transform 666ms cubic-bezier(0.666, 0, 0.333, 1) 333ms;

		@include screen-md-max {
			position: absolute;
			width: 100%;
			height: auto;
			bottom: 0;
			padding: 50px 0;
			transform: translateX(0) !important;
		}
		@include screen-sm-max {
			padding: 30px 0;
		}
		@include screen-xs-max {
			padding: 20px 0;
		}
		&--show {
			transform: translateX(0) !important;
		}

		h3, img {
			display: block;
			width: 80%;
			margin: 0 auto;
			max-width: 360px;
			opacity: 0;
			text-align: center;
			transition: opacity 0.7s ease-in;

			@include screen-md-max {
				opacity: 1;
			}

			&.show {
				opacity: 1;
			}
		}

		h3 {
			line-height: 1.4em;
		}

		img {
			height: auto;
			position: relative;
		}
	}
}
