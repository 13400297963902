.footer {
	background-color: $color-xxdark-gray;
	font-size: 12px;
	line-height: 16px;
	position: relative;
	z-index: 2;

	a {
		color: $color-gray;
		letter-spacing: 0.64px;

		&:hover {
			text-decoration: underline;
		}
	}

	&-wrapper {
		padding-top: 100px;
		padding-bottom: 80px;

		@include screen-lg-max {
			padding-top: 55px;
			padding-bottom: 0;
		}
	}

	&-branding {
		margin-bottom: 60px;
		display: flex;

		svg {
			display: block;
			fill: $color-white;
			width: 116px;
			height: 15px;
		}

		&-wordmark {
			color: $color-white;
			font: 16px/1 $font-medium;
			letter-spacing: 12px;
			text-transform: uppercase;
			margin-left: 14px;
		}
	}

	&-newsletter {
		margin-bottom: 33px;

		label {
			display: block;
			font: 12px/1 $font-bold;
			color: $color-white;
			letter-spacing: 1.5px;
			text-transform: uppercase;
			margin-bottom: 4px;
		}

		&-wrapper {
			position: relative;
		}

		input {
			color: $color-white;
			background-color: $color-xxdark-gray;
			border-color: $color-dark-gray;
			padding-right: 50px;

			&:focus {
				border-bottom: 2px solid $color-white;
				padding-bottom: 14px;
				outline: 0;
			}

			&.error {
				border-bottom: 2px solid $color-red;
				padding-bottom: 14px;
			}
		}

		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active {
			-webkit-box-shadow: 0 0 0 30px $color-xxdark-gray inset !important;
		}

		input:-webkit-autofill {
			-webkit-text-fill-color: $color-white !important;
		}

		::-webkit-input-placeholder {
			color: $color-dark-gray;
			opacity: 1;
		}

		::-moz-placeholder {
			color: $color-dark-gray;
			opacity: 1;
		}

		:-ms-input-placeholder {
			color: $color-dark-gray !important;
			opacity: 1;
		}

		&-button {
			border: 0;
			padding: 0;
			background-color: transparent;
			width: 36px;
			height: 50px;
			cursor: pointer;
			position: absolute;
			right: 0;
			top: 0;
			outline: 0;

			&::before {
				content: $icon-long-arrow;
				font-family: $font-icons;
				font-size: 11px;
				color: $color-dark-gray;
				transition: color $default-transition;
			}

			&:hover::before {
				color: $color-white;
			}
		}

		&-message {
			padding-top: 10px;
			font-size: 12px;
			color: $color-dark-gray;
			display: none;

			&--error {
				color: $color-red;
			}
		}

		h3, h4 {
			font: 20px $font-bold;
			color: $color-white;
			letter-spacing: 1.75px;
			margin-bottom: 20px;

			@include screen-lg-max {
				margin-bottom: 0;
				padding: 31px 0;
			}
		}
	}

	&-social {
		display: flex;
		max-width: 200px;

		@include screen-lg-max {
			padding: 15px 0 50px;
		}

		li {
			flex-grow: 1;
		}

		&-link {
			&:hover {
				text-decoration: none !important;
				color: $color-white;
			}

			&::before {
				font-family: $font-icons;
			}

			&--facebook::before {
				content: $icon-facebook;
				font-size: 22.1px;
			}

			&--instagram::before {
				content: $icon-instagram;
				font-size: 21.1px;
			}

			&--youtube::before {
				content: $icon-youtube;
				font-size: 15px;
			}
		}
	}

	&-links {
		display: flex;
		justify-content: space-between;

		@include screen-lg-max {
			display: block;
			border-top: 1px solid $color-dark-gray;
			margin: 0 -20px !important;
			width: calc(100% + 40px) !important;

			&-section {
				border-bottom: 1px solid $color-dark-gray;
				padding: 0 20px;
			}

			a {
				font-size: 14px;
			}
		}

		&-section + &-section {
			margin-top: 35px;

			@include screen-lg-max {
				margin-top: 0;
			}
		}

		h4 {
			font: 12px/1 $font-bold;
			color: $color-white;
			letter-spacing: 1.75px;
			margin-bottom: 20px;

			@include screen-lg-max {
				margin-bottom: 0;
				padding: 31px 0;
			}
		}

		li {
			margin-bottom: 12px;

			@include screen-lg-max {
				margin-bottom: 14px;

				&:last-child {
					padding-bottom: 20px;
				}
			}
		}

		.collapse-trigger {
			&::after, &::before {
				background-color: $color-gray;
			}
		}
	}

	&-bottombar {
		background-color: $color-xdark-gray;
		color: $color-gray;

		@include screen-lg-max {
			.container {
				margin: 0 0;
				width: 100%;
			}
		}

		&-content {
			display: flex;
			align-items: center;
			height: 50px;

			@include screen-lg-max {
				height: auto;
				flex-direction: column;
				float: none !important;
				margin: 0 !important;
				width: 100% !important;
			}
		}

		&-copyright, &-utility {
			font-family: $font-bold;
			text-transform: uppercase;
			letter-spacing: 1.5px;
		}

		&-copyright {
			margin-right: 40px;

			@include screen-lg-max {
				order: 2;
				margin-right: 0;
				width: 100%;
				padding: 30px 0 16px;
			}
		}

		&-nav, &-utility {
			display: flex;
		}

		&-nav {
			flex-grow: 1;

			li {
				margin-right: 15px;

				a {
					display: inline-block;
					margin-top: 1px;
				}
			}

			@include screen-lg-max {
				order: 3;
				width: 100%;
				padding: 0 0 35px;
			}
		}

		&-utility {
			@include screen-lg-max {
				order: 1;
				width: calc(100% + 40px);
				margin: 0 -20px;
				padding: 0 20px;
				border-top: 1px solid $color-dark-gray;
				border-bottom: 1px solid $color-dark-gray;
			}

			li {
				margin-left: 30px;

				@include screen-lg-max {
					margin: 0 50px 0 0;
				}
			}
		}
	}
}
