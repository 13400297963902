﻿.qrcode-scanner-simple-modal {
    z-index: 100;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $screen-sm) {
        padding-top: 0;
    }

    #reader {
        width: 700px;

        @media (max-width: $screen-xl) {
            top: 22%;
        }

        @media (max-width: $screen-md) {
            top: 10%;
            width: 500px;
        }

        @media (max-width: $screen-sm) {
            top: 13%;
            width: 500px;
        }
    }
}

.qr-code-scanner-heading {
    position: absolute;
    top: 15%;
    width: 100%;

    @media (max-width: $screen-lg) {
        top: 22%;
    }

    @media (max-width: $screen-md) {
        top: 10%;
    }

    @media (max-width: $screen-sm) {
        top: 13%;
    }

    p {
        position: absolute;
        z-index: 99;
        color: $color-white;
        font-size: 24px;
        text-align: center;
        display: block;
        width: 100%;
    }
}
#close-qr-reader-btn {
    position: absolute;
    display: block;
    height: 24px;
    width: 24px;
    z-index: 99;
    cursor: pointer;
    color: white;
    border: 0;
    margin-top: 0px;
    text-align: right;
    background-color: inherit;
    right: 3%;
    top: 1%;

    &:hover {
        opacity: 0.85;
    }

    &::before, &::after {
        position: absolute;
        content: ' ';
        height: 24px;
        top: 0;
        right: 12px;
        width: 1px;
        background-color: $color-white;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}
.hide {
    display: none;
}
