@font-face {
	font-family: 'ISOCTEUR';
	font-display: swap;
	src: url('/common/fonts/ISOCTEUR.woff2') format('woff2'), url('/common/fonts/ISOCTEUR.woff') format('woff');
}

$font-truvativ-bold: "ISOCTEUR", "Helvetica", "Arial", sans-serif;

body.truvativ {
	h1, h2, h3 {
		font-family: $font-truvativ-bold;
	}

	h1 {
		line-height: 0.9em;
		letter-spacing: normal;
	}

	h2, h1.cms-page-banner-title {
		letter-spacing: -0.69px;
	}

	h3, h1.product-title {
		//
	}
}
