﻿$base-border-color: gainsboro !default;
$base-border-radius: 3px !default;
$base-line-height: 1.5em !default;
$base-spacing: 1.5em !default;
$action-color: #477dca !default;
$dark-gray: #333333 !default;
$light-gray: #dddddd !default;
$medium-screen: 40em !default;
$large-screen: 53.75em !default;
$base-font-color: $dark-gray !default;
$table-border-color: #f9f9f9;
$table-border: 1px solid $table-border-color;
$table-background: #fefefe;
$table-header-background: #f8f8f8;
$table-hover-background: #f9f9f9;
$table-stripe-background: #f1f1f1;
$table-stripe-background-hover: #ececec;
$table-padding: 0.75em 1em;

.table,
.table--stack {
	border: $table-border;
	border-collapse: collapse;
	border-radius: $base-border-radius;
	border-spacing: 0;
	width: 100%;

	tbody {
		background-color: $table-background;

		td {
			border-bottom: 0;
			border-left: 1px solid $table-border-color;
			border-top: 1px solid $table-border-color;
			padding: $table-padding;
		}

		tr:hover > td, tr:hover > th {
			background-color: $table-hover-background;
		}

		tr:nth-child(even) {
			background-color: $table-stripe-background;

			&:hover > td {
				background-color: $table-stripe-background-hover;
			}
		}
	}

	thead:first-of-type {
		tr:first-child > th:first-child {
			border-top-left-radius: $base-border-radius;
		}

		tr:first-child > th:last-child {
			border-top-right-radius: $base-border-radius;
		}
	}

	tbody:last-child {
		tr:last-child > td:first-child {
			border-bottom-left-radius: $base-border-radius;
		}

		tr:last-child > td:last-child {
			border-bottom-right-radius: $base-border-radius;
		}
	}

	thead {
		th {
			background-color: $table-header-background;
			border-bottom: 0;
			border-left: 1px solid $table-border-color;
			padding: $table-padding;
			text-align: left;
		}
	}
}

.table--stack {
	td .label {
		display: none;
	}

	@media screen and (max-width: 768px) {
		thead {
			display: none;
		}

		tfoot {
			display: none;
		}

		tr,
		th,
		td {
			display: block;
		}

		td {
			border-top: 0;
			text-align: right;
		}

		td .label,
		td::before {
			font-weight: bold;
			text-align: center;
			float: left;
		}

		td .label {
			display: inline;
		}

		td::before {
			content: attr(data-label);
		}
	}
}
