﻿
.prod-registration-verify-email-container {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: center;
	max-width: 350px;
	min-width: 350px;
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	background-color: $color-white;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	padding: 0;
	@include screen-xs-max {
		max-width: inherit;
		min-width: inherit;
		width: 90%;
	}
	.prod-registration-verify-email-header {
		background-color: $color-light-gray;
		height: 130px;
		align-items: center;
		display: flex;
		justify-content: center;
		.sram-square-logo-container {
			display: inline-flex;
			width: 82px;
			height: 82px;
			background-color: $color-red;
			svg {
				fill: $color-white;
				width: 68px;
				top: 50%;
				left: 50%;
				-ms-transform: translate(-50%,-50%);
				transform: translate(-50%,-50%);
				display: inline-block;
				position: relative;
			}
		}
	}
	h2 {
		font-family: $font-regular;
		font-size: 21px;
		color: $color-xxdark-gray;
		padding: 112px 0 0 0;
	}
	p {
		color: $color-dark-gray;
		font-size: 16px;
		line-height: 23px;
		padding: 30px 30px 150px 30px;
	}
}