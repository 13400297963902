﻿.step-progressbar {
    color: $color-gray;
    list-style: none;
    counter-reset: step;
    display: flex;
    padding: 0;

    &__item {
        display: flex;
        flex-direction: column;
        flex: 1;
        text-align: center;
        position: relative;

        &:before {
            font-family: 'Icons';
            width: 2.5em;
            height: 2.5em;
            content: '\e90d';
            counter-increment: step;
            align-self: center;
            background: $color-gray;
            color: $color-white;
            border-radius: 100%;
            line-height: 2.5em;
            margin-bottom: 0.5em;
        }

        &:after {
            height: 2px;
            width: calc(100% - 4em);
            content: '';
            background: $color-gray;
            position: absolute;
            top: 1.25em;
            left: calc(50% + 2em);
        }

        &:last-child {
            &:after {
                content: none;
            }
        }

        &--active {
            color: $color-black;

            &:before {
                background: $color-green;
            }

            &:after {
                background: linear-gradient(to right, $color-green 50%, $color-gray 50%);
            }
        }

        &--complete {
            color: $color-black;

            &:before {
                background: $color-green;
            }

            &:after {
                background: $color-green;
            }
        }
    }
}
