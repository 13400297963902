﻿
.panel-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: $color-gray;
    opacity: 0.8;
    z-index: 999;

    &.open {
        left: 0;
    }
}

.slide-in {
    animation: slide-in 1s forwards;
}

.slide-out {
    animation: slide-out 1s forwards;
}

@keyframes slide-in {
    100% {
        transform: translateX(0%);
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(100%);
    }
}

.panel-cart {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 530px;
    z-index: 1000;
    background-color: $color-white;
    overflow-y: scroll;
    transform: translateX(100%);

    @include screen-md-max {
        width: 100%;
    }

    &.open {
        @extend .slide-in;
    }
    &.close {
        @extend .slide-out;
    }
}

.wrap-cart {
    position: relative;
}

.link-cart {
    position: relative;

    .cart-bubble {
        position: absolute;
        top: 0;

        &-mobile {
            position: absolute;
            top: -20px;
        }
    }
}

.header-cart-brands {
    max-width: 480px;
    padding-bottom: 0;
    @include screen-lg-max {
        padding-top: 10px;
        li {
            height: auto;
            width: 15%;
            border-bottom: none;
        }
    }
}

.aside-cart {
    padding: 30px;
    background-color: $color-xlight-gray;

    &--checkout {
        @extend .aside-cart;
        padding: 80px;

        @include screen-xs-max {
            padding: 20px;
        }
    }

    .color-gray {
        color: $color-black;
    }

    .button[disabled] {
        pointer-events: none;
        cursor: default;
    }
}

.aside-cart-input-text {
    background-color: transparent !important;
}

.aside-cart-header,
.item-cart-header {
    color: $color-black !important;
    font-weight: bold;
    text-transform: uppercase;
}

.aside-cart-header {
    font-size: 20px;
}