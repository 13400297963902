﻿.video-gallery {
	position: relative;

	&-player {
		position: relative;
		width: 100%;
		padding-top: 50%;

		iframe {
			position: absolute;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
		}
	}

	&-navigation {
		position: relative;

		.slick-track {
			margin-left: 0 !important;
		}

		&-thumbnails {
			// display: flex;
			background: $color-black;

			li {
				position: relative;
				cursor: pointer;

				&::before {
					position: absolute;
					bottom: 23px;
					left: 23px;
					height: 26px;
					width: 26px;
					z-index: 3;
					background: rgba(0, 0, 0, 0.5);
					border-radius: 100%;
					color: $color-red;
					content: '';

					@include screen-md-max {
						left: 50%;
						bottom: 50%;
						transform: translate(-50%, 50%);
					}
				}

				&::after {
					position: absolute;
					bottom: 21px;
					left: 21px;
					z-index: 4;
					font-size: 30px;
					font-family: $font-icons;
					content: $icon-play;
					color: $color-red;

					@include screen-md-max {
						left: 50%;
						bottom: 50%;
						transform: translate(-50%, 50%);
					}
				}

				&.active {
					&::before {
						display: none;
					}

					&::after {
						content: 'playing';
						text-transform: uppercase;
						color: $color-red;
						letter-spacing: 1.5px;
						font-family: $font-bold;
						font-size: 12px;
						line-height: 1;
						position: absolute;
						left: 37px;
						bottom: 33px;
						z-index: 2;

						@include screen-md-max {
							left: 10px;
							bottom: 10px;
							transform: none;
						}
					}
				}

				img {
					display: block;
					width: 100%;
					height: auto;
				}
			}
		}

		&-arrow {
			position: absolute;
			bottom: 0;
			width: 140px;
			cursor: pointer;
			height: 100%;
			z-index: 10;
			background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
			transition: opacity 0.3s ease-in;

			@include screen-lg-max {
				display: none;
			}

			&::after {
				font-family: $font-icons;
				display: block;
				position: absolute;
				color: $color-white;
				top: 50%;
				content: '';
				left: 50%;
				font-size: 20px;
				transform: translate(-50%, -50%);
			}

			&::before {
				height: 50px;
				width: 50px;
				border: 1px solid $color-white;
				top: 50%;
				position: absolute;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			&--next {
				right: 0;

				&::after {
					content: $icon-short-arrow;
				}
			}

			&--prev {
				left: 0;
				background: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, #000000 100%);

				&::after {
					content: $icon-short-arrow;
					transform: rotate(180deg) translate(50%, 50%);
				}
			}

			&.slick-disabled {
				opacity: 0 !important;
			}
		}
	}
}
