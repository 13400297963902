﻿﻿:root {
	--promo-2-background: var(--page-background, #fff);
}

.product-promo-2-up {
	background: var(--promo-2-background);
	&-wrapper {
		display: flex;
		justify-content: space-between;

		@include screen-md-max {
			display: block;
		}

		& > div {
			position: relative;
			height: 430px;
			@include span-columns(5 of 10);

			@include screen-lg-max {
				@include span-columns(6);
			}

			@include screen-md-max {
				@include span-columns(12);
				margin-bottom: 30px;
			}
		}
	}

	&-item {
		width: 100%;
		height: 100%;
		background-position: center center;
		background-size: cover;

		.product-promo-2-up-item-text {
			position: absolute;
			bottom: 40px;
			left: 40px;

			h3 {
				color: $color-white;
				width: 260px;
				margin-bottom: 40px;
			}
		}

		img {
			height: 100%;
			width: 100%;
		}
	}
}
