.segmented-topic-content {
  padding: 20px;
  display: none;
}

.segmented-topic-content.active {
  display: block;
}

.segmented-topic-nav-container {
  display: flex;
  flex-direction: row;
  padding: 4px;
  background-color: $color-off-white;
}

.segmented-topic-nav-btn {
  padding: 34.5px 0;
  color: $color-xxdark-gray;
  font-family: $font-regular;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  background-color: $color-off-white;
  border: 0;
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: $color-dark-light-gray;
    transition: background-color 0.15s linear; 
    -moz-transition: background-color 0.15s linear;  
    -webkit-transition: background-color 0.15s linear; 
    -ms-transition: background-color 0.15s linear; 
  };
  @media (max-width: $screen-sm) {
    padding: 17.5px 0;
    font-size: 12px;
  }
}

.segmented-topic-nav-btn.active {
  background-color: $color-red;
  color: $color-white;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
}