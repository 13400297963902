﻿.file-download-list {
	h5 {
		margin-bottom: 24px;
	}

	&-item {
		display: grid;
		grid-template-columns: 65% 35%;
		border-bottom: 1px solid $color-light-gray;
		margin-bottom: 35px;

		.caption {
			color: $color-dark-gray;
			font: 12px/16px $font-mono;
			margin-top: 3px;
			letter-spacing: 0.75px;
		}

		.language-grid {
			display: grid;
			font-size: 12px;
			color: $color-dark-gray;
			grid-template-columns: min-content auto;
			column-gap: 5px;
		}

		&-link {
			text-align: right;
			.service-icon-container {
				width: 22px;
				height: 30px;
				display: inline-block;
			}
			.service-external-container {
				width: 22px;
				height: 22px;
				display: inline-block;
			}
			svg {
				width: 100%;
				height: 100%;
			}
		}

		&-button {
			.caption {
				display: block;
				margin-top: 8px;
				@extend .caption;
			}
		}

		&-content {
			margin-bottom: 20px;
			margin-right: 20px;

			.document-title, .caption {
				font-size: 14px;
			}

			a p, a {
				color: $color-xdark-gray;
				line-height: 22px;

				&.caption {
					@extend .caption;
				}
			}
		}
	}
}