﻿.listing--jobs {
	.listing-filters {
		margin-top: 50px;
		@include screen-lg-max {
			margin-top: 0;
		}
	}

	.job-listing {
		&-toolbar {
			border-bottom: 1px solid $color-light-gray;
			padding-bottom: 21px;

			&-content {
				//
			}

			#btn-refine {
				display: none;

				@include screen-lg-max {
					display: block;
					margin: 20px 0;
					text-align: center;
					border-color: $color-xlight-gray;
				}
			}
		}

		&-content {
			margin-top: 50px;
			@include screen-lg-max {
				margin-top: 34px;
			}
		}

		&-item {
			padding: 21px 0 32px;
			border-bottom: 1px solid $color-gray;

			&-dept {
				font: 14px/18px $font-medium;
				color: $color-dark-gray;
				margin-bottom: 1px;
			}

			&-title {
				color: $color-black;
				font-family: $font-medium;
				text-transform: uppercase;
			}

			&-loc {
				color: $color-dark-gray;
				margin: -3px 0 10px;
			}

			&-desc {
				color: $color-xdark-gray;
			}
		}

		&-empty {
			display: none;
			padding: 50px 0;
		}
	}
}
