﻿*, .rockshox, .truvativ, .zipp, .quarq {

    .hero-carousel {
        position: relative;

        &-slides {
            &-slide {
                background-position: center center;
                background-size: cover;
                position: relative;
                height: 51vw;

                @include screen-md-max {
                    height: 160vw;
                }

                &-text {
                    position: absolute;
                    transform: translate(-50%, -50%);
                    left: 50%;
                    top: 50%;
                    width: 80%;
                    text-align: center;

                    h1, h2 {
                        color: $color-white;
                        margin-bottom: 20px;

                        @include screen-md-max {
                            margin-bottom: 14px;
                        }
                    }

                    h1, h2 {
                        font-size: 100px;
                        line-height: 1.1em;
                        letter-spacing: 5px;

                        @include screen-lg-max {
                            font-size: 80px;
                        }

                        @include screen-md-max {
                            font-size: 60px;
                        }

                        @include screen-xs-max {
                            font-size: 40px;
                        }
                    }

                    p {
                        color: $color-white;
                        margin-bottom: 25px;
                        font-size: 20px;
                        line-height: 1.5em;
                        letter-spacing: 1.25px;
                        font-family: $font-medium;

                        @include screen-md-max {
                            font-size: 16px;
                        }

                        @include screen-xs-max {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        &-dots {
            position: absolute;
            top: 50%;
            right: 30px;
            transform: translateY(-50%);

            @include screen-md-max {
                bottom: 21px;
                right: 50%;
                transform: translateX(50%);
                top: auto;
            }

            li {
                margin-bottom: 10px;

                @include screen-md-max {
                    display: inline-block;
                    margin: 0 30px 0 0;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                button {
                    font-size: 0;
                    border: 0;
                    padding: 0;
                    border-radius: 100%;
                    height: 10px;
                    width: 10px;
                    background: $color-gray;
                    transition: background $default-transition;
                    cursor: pointer;

                    &:focus {
                        outline: none;
                    }

                    &:hover {
                        background: $color-red;
                    }
                }

                &.slick-active {
                    button {
                        background: $color-red;
                    }
                }
            }
        }

        &-arrows {
            position: absolute;
            bottom: 0;
            right: 0;
            background: $color-xdark-gray;
            height: 123px;
            width: 392px;
            display: none;

            .arrow {
                border: 1px solid $color-black;
                height: 50px;
                width: 50px;
                background: none;
                margin-top: 50px;
                position: absolute;
                cursor: pointer;
                display: none;

                @include screen-md-max {
                    display: block;
                }

                &-prev {
                    left: 30px;
                }

                &-next {
                    right: 30px;
                }
            }

            &-pager {
                color: $color-white;
                display: flex;
                flex-direction: column;

                &-current {
                    display: block;
                    margin-bottom: 10px;
                }

                &-total {
                    margin-top: 10px;
                    border-top: 1px solid $color-white;
                }
            }
        }

        &-button {
            &:nth-of-type(2) {
                margin-left: 20px;

                @include screen-sm-max {
                    flex: 1 0 100%;
                    margin-left: 0;
                    margin-top: 12px
                }
            }
        }

        @include screen-xl-max {
            min-height: 500px
        }

        @include screen-lg-max {
            min-height: 400px
        }

        @include screen-md-max {
            min-height: 400px
        }

        @include screen-sm-max {
            min-height: 700px
        }

        @include screen-xs-max {
            min-height: 700px
        }
    }
}
