﻿.region {
    margin: 30px 0;
    display: block;

    h3 {
        margin: 20px 0;
    }

    .col-3 {
        margin: 0;
        width: 25%;

        .country {
            margin: 5px 10px 25px 0;


            div {
                display: flex;

                h5 {
                    font-size: 18px;
                    letter-spacing: 0.05rem;
                    margin: -4px 4px 0 5px;
                }

                img {
                    width: 20px;
                    height: 18px;
                    margin-left: 10px;
                }
            }

            h5 {
                font-size: 18px;
                letter-spacing: 0.05rem;
            }

            img {
                width: 20px;
                height: 18px;
                margin-left: 10px;
            }

            ul {
                display: flex;

                li {
                    padding: 0 10px;

                    a {
                        color: #c00;
                    }
                }
            }
        }

        @media screen and (max-width: 1280px) {
            width: 33%;
        }

        @media screen and (max-width: 1024px) {
            width: 50%;
        }

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }
}
