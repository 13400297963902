﻿root {
	--c-three-up-nav-color: var($color-black);
}

.carousel-arrows {
	&-arrow {
		border: 1px solid var(--c-three-up-nav-color);
		height: 50px;
		width: 50px;
		background: none;
		position: absolute;
		left: 0;
		top: 50%;
		cursor: pointer;
		display: none;
		transform: translateY(-50%);
		transition: background $default-transition, border-color $default-transition, color $default-transition;

		&:hover {
			background: $color-black;
			border-color: $color-white;

			&::after {
				color: $color-white;
			}
		}

		&::after {
			font-family: $font-icons;
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			font-size: 20px;
			transform: translate(-50%, -50%);
		}

		&--prev {
			left: 30px;

			@include screen-lg-max {
				left: 20px;
			}

			@include screen-sm-max {
				left: 0;
			}

			&::after {
				content: $icon-short-arrow;
				transform: rotate(180deg) translate(50%, 50%);
			}
		}

		&--next {
			left: auto;
			right: 30px;

			@include screen-lg-max {
				left: auto;
				right: 20px;
			}

			@include screen-sm-max {
				left: auto;
				right: 0;
			}

			&::after {
				content: $icon-short-arrow;
			}
		}

		&-pager {
			display: none;
		}
	}

	&--white {
		.carousel-arrows-arrow {
			border-color: $color-white;
			color: $color-white;

			&:hover {
				background: $color-white;
				border-color: $color-black;

				&::after {
					color: $color-black;
				}
			}
		}
	}

	&--alt {
		@include screen-lg-max {
			display: flex;
			flex-direction: row-reverse;
			justify-content: space-between;
			margin: 20px 20px 0 20px;
			align-items: center;

			&-arrow {
				position: relative;
				transform: none;
				top: unset;
				left: unset;
				right: unset;
			}
		}

		&-pager {
			display: none;
			font-size: 15px;
			line-height: 20px;
			font-family: $font-mono;

			@include screen-lg-max {
				display: flex;
			}

			&-current {
				margin-right: 10px;
			}
		}
	}
}
