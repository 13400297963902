﻿.service-promo {
	h3 {
		padding: 36px 0 36px;
	}

	&-content {
		border-bottom: 1px solid $color-dark-gray;
		padding-bottom: 28px;

		@include screen-lg-max {
			padding-bottom: 0;
		}
	}

	&-description {
		padding-bottom: 36px;

		p {
			margin-bottom: 20px;
			color: $color-dark-gray;

			strong {
				font-family: $font-bold;
				color: $color-black;
				letter-spacing: 1.5px;
				font-size: 12px;
				text-transform: uppercase;
			}
		}
	}
}
