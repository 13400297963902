
:root {
	--multipromo-slider-background: var(--page-background, #fff);
	--multipromo-slider-item-background: var(--page-background);
	--slider-item-color: var(--color-black);
}

.swiper-wrapper figure{
	margin: 0;
}

.swiper-notification {
	display: none;
}

.multipromo-slider-section {
	background: var(--multipromo-slider-background);
}

.multipromo-slider-container-wrapper {
	margin-top: 30px;
	.multipromo-slider-container-header {
		margin: 30px 0;
		@media screen and (max-width: 700px) {
			margin: 23px 0;
		}
		h2,h3 {
			margin: 30px 0;
		}
	}
}

.multipromo-slider-container {
	.swiper {
		grid-column: 2;
		min-width: 350px;
		width: 100%;
		margin-bottom: 10px;
		position: relative;
		padding: 0;

		.multipromo-slider-item {
			box-sizing: border-box;
			scroll-snap-align: center;
			flex: none;
			background: var(--multipromo-slider-item-background);
			border: none;
			padding: 0;
			box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
			width: 288px;
			height:512px;
			font-size: 20px;
			font-family: $font-medium;
			color: var(--slider-item-color);
			text-align: left;
			
			.multipromo-collab-title-container {
				margin: 23px 20px 0 20px;
				height: 32px;
				.multipromo-collab-title {
					font-family: $font-mono;
					font-size: 14px;
					color: #5F6368;
					text-transform: uppercase;
					letter-spacing: 0.42em;
					line-height: 32px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.multipromo-slider-item-content-container {
				margin-top: 30px;
				.multipromo-image-wrapper {
					position: relative;
					height: 200px;
					max-height: 336px;
					width: 100%;		
					aspect-ratio: .526;
					overflow: hidden;
					img {
						width: 100%;
						display: block;
					}
				}
				.multipromo-slider-item-copy {
					padding: 20px 20px 0px 20px;
					overflow: hidden;
					box-sizing: content-box;
					text-transform: none;
					h3 {
						font-size: 22px;
						font-family: $font-condensed-bold;
						font-weight: 700;
						color: var(--color-black);
						line-height: 25.78px;
						letter-spacing: .25px;
					}
					p {
						font-family: $font-regular;
						color: var(--color-black);
						font-size: 16px;
						line-height: 30px;
						width: 250px;
						margin-top: 5px;
						overflow: hidden;
						max-height: 5.5em;
					}
					.multipromo-button {
						position: absolute;
						bottom: 20px;
					}
				}
			}			
		}
	}

	.shadow-right::after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		// z-index: 1;
		box-shadow: 
		inset 0 5px 0px 0px var(--page-background), 
		inset 0 -4px 0px 0px var(--page-background), 
		inset 6px 0 15px -4px transparent,
		inset -6px 0 15px -4px rgba(0, 0, 0, 0.7);
		pointer-events: none;
		margin: -5px 0;
		z-index: 1;
	}

	.shadow-left::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		box-shadow: 
		inset 0 5px 0px 0px var(--page-background), 
		inset 0 -4px 0px 0px var(--page-background), 
		inset 6px 0 15px -4px rgba(0, 0, 0, 0.7),
		inset -6px 0 15px -4px transparent;
		pointer-events: none;
		margin: -5px;
		z-index: 2;
	}

	/*  don't show buttons on mobile */
	.multipromo-swiper-button-prev,
	.multipromo-swiper-button-next {
		display: none;
	}
}


/* desktop */
@media screen and (min-width: 700px) {

	.multipromo-slider-container {
		display: grid;
		grid-template-columns: 40px auto 40px;
		grid-gap: 30px;
		margin-left: -70px;
		margin-right: -70px;

		.multipromo-swiper-button-prev {
			display: block;
			position: inherit;
			grid-column: 1;
			grid-row: 1;
			place-self: center;
			// border: 1px solid var(--item-background);
			padding: 10px 15px;
		}

		.multipromo-swiper-button-next {
			display: block;
			position: inherit;
			grid-column: 3;
			grid-row: 1;
			place-self: center;
			// border: 1px solid var(--item-background);
			padding: 10px 15px;
		}

		.multipromo-swiper-button-next:focus, .multipromo-swiper-button-prev:focus {
			outline: 1px solid var(--color-black);
		}

		.multipromo-swiper-button-next.swiper-button-lock {
			display: none;
		}

		.multipromo-swiper-button-prev.swiper-button-lock {
			display: none;
		}

		.swiper-button-disabled {
			border: 1px solid #ddd;

			&.carousel-arrows-arrow:after {
				color: #ddd;
			}
		}
	}
}
