﻿.compare-table {
    @extend .spec-table;
    background-color: $color-xlight-gray;
    margin-top: 40px;

    h3 {
        background-color: $color-black;
        color: $color-white;
        padding: 20px 40px;
        display: inline-block;
        margin-top: -40px;
    }

    h4 {
        padding: 36px 0;
    }

    &-table {
        margin-top: -10px;
        position: relative;
        width: 100%;
        z-index: 1;
        margin: auto;
        overflow: auto;
    }

    table {
        min-width: 1000px;
        table-layout: fixed;

        tr {
            th, td {
                border-bottom: none;
                text-align: left;
                width:auto;

                &:first-child {
                    width: 200px;
                    position: sticky;
                    left: 0;
                    background-color: $color-xlight-gray;
                    z-index: 1;

                    @include screen-md-max {
                        min-width: auto;
                    }
                }

                &:last-child {
                    width: auto;
                    padding-left: 0;
                }
            }

            &:first-child {
                th, td {
                    border-bottom: none;
                }
            }
        }

        thead {

            tr {
                th {
                    &:not(:first-child) {
                        div {
                            margin-right: 30px;
                            padding-bottom: 10px;
                        }
                    }
                }

                &:last-child {
                    th {
                        &:not(:first-child) {
                            div {
                                border-bottom: 1px solid $color-gray;
                            }
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                th, td {
                    padding-right: 20px;
                }

                th {
                    font-weight: bold;
                    text-transform: uppercase;
                    color: $color-black;
                    text-align: right;
                }
            }
        }
    }

    img {
        text-align: center;
    }
}
