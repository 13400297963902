﻿.service-detail {
	.breadcrumbs {
		margin-left: $page-gutter;
		padding-bottom: 0;

		@include screen-lg-max {
			margin-left: $page-gutter-sm;
		}
	}

	&-hero {
		&-image {
			padding: 83px 0;

			img {
				width: 100%;
			}

			@include screen-md-max {
				padding: 0;
			}
		}

		&-content {
			padding-top: 111px;

			@include screen-md-max {
				padding-top: 40px;
			}
		}

		h1 {
			margin-bottom: 30px;
		}

		.product-title--mobile {
			display: none;
			text-align: center;
			margin-top: 30px;
		}

		@include screen-md-max {
			.product-title {
				display: none;
			}

			.product-title--mobile {
				display: block;
			}
		}

		&-sku {
			font: 12px/1 $font-bold;
			color: $color-black;
			border-bottom: 2px solid $color-xdark-gray;
			text-transform: uppercase;
			padding-bottom: 13px;
			margin-bottom: 38px;
		}

		&-links {
			margin-bottom: 35px;

			p {
				font-size: 14px;
				color: $color-xdark-gray;
				letter-spacing: 0.5px;
				margin-bottom: 8px;
			}

			li {
				margin-bottom: 4px;
			}

			a {
				color: $color-xdark-gray;

				&::after {
					content: $icon-medium-arrow;
					font-family: $font-icons;
					color: $color-red;
					font-size: 10px;
					margin-left: 12px;
					position: relative;
					top: -3px;
				}
			}
		}
	}

	&-intro {
		margin-bottom: 94px;

		h3 {
			margin-bottom: 22px;
		}
	}

	.service-detail-filter-media {

		label {
			color: var(--color-dark-gray);
		}
		/* Allow Transitions */
		.file-download-list-item, .detail-page-video-file {
			transition: all .2s ease;
		}

		input[type="checkbox"] {
			accent-color: var(--color-red);
			/*hide all list items not data-display*/
			~ #document-filter-list .file-download-list-item:not([data-display]) {
				visibility: hidden;
				opacity: 0;
				max-height: 0;
				margin-bottom: 0;
			}

			~ #video-filter-list .detail-page-video-file:not([data-display]) {
				display: none;
			}
			/* show all items when checked*/
			&:checked ~ #document-filter-list .file-download-list-item {
				visibility: visible;
				max-height: 900px;
				opacity: 1;
				margin-bottom: 35px;
			}

			&:checked ~ #video-filter-list .detail-page-video-file {
				display: block;
			}
		}
	}

	&-manuals {
		margin-bottom: 70px;

		@include screen-md-max {
			margin-bottom: 40px;
		}

		h4 {
			color: $color-red;
			font-size: 15px;
			border-bottom: solid 1px $color-dark-gray;
			padding-bottom: 9px;
			margin: 38px 0 20px;
		}
	}

	.video-2up-wrapper {
		margin-top: var(--gutter);
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: var(--gutter);
	}

	@include screen-md-max {
		.video-2up-wrapper {
			grid-template-columns: 1fr;
		}
	}

	.video-2up {
		margin-bottom: 90px;
		padding-top: 0 !important;
		padding-bottom: 0 !important;

		&-item {
			margin-right: 0;
			margin-bottom: 0;
			width: 100%;
		}

		h3 {
			margin-bottom: 0;
			margin-left: 0;
		}

		@include screen-md-max {
			margin-bottom: 40px;
		}
	}

	&-additional {
		margin-bottom: 70px;

		@include screen-md-max {
			margin-bottom: 40px;
		}

		h3 {
			margin-bottom: 22px;
		}

		.rich-text {
			a:not(.button):not(.button--alt-light):not(.button--alt-dark):not(.button--alt-external):not(.button--alt-red) {
				color: $color-xdark-gray;
				border-bottom: 1px solid $color-xdark-gray;
				padding-bottom: 3px;
				transition: color $default-transition, border-color $default-transition;

				&:hover {
					color: $color-black;
					border-color: $color-black;
				}
			}
		}
	}
}
