@font-face {
	font-family: 'Roboto';
	font-display: swap;
	src: url('/common/fonts/Roboto-Thin.woff') format('woff');
}

@font-face {
	font-family: 'Roboto-Regular';
	font-display: swap;
	src: url('/common/fonts/Roboto-Regular.woff2') format('woff2'), url('/common/fonts/Roboto-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Roboto-Medium';
	font-display: swap;
	src: url('/common/fonts/Roboto-Medium.woff2') format('woff2'), url('/common/fonts/Roboto-Medium.woff') format('woff');
}

@font-face {
	font-family: 'Roboto-Bold';
	font-display: swap;
	src: url('/common/fonts/Roboto-Bold.woff2') format('woff2'), url('/common/fonts/Roboto-Bold.woff') format('woff');
}

@font-face {
	font-family: 'RobotoCondensed-Bold';
	font-display: swap;
	src: url('/common/fonts/RobotoCondensed-Bold.woff2') format('woff2'), url('/common/fonts/RobotoCondensed-Bold.woff') format('woff');
}

@font-face {
	font-family: 'RobotoMono-Regular';
	font-display: swap;
	src: url('/common/fonts/RobotoMono-Regular.woff2') format('woff2'), url('/common/fonts/RobotoMono-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Owners-Bold';
	font-display: swap;
	src: url('/common/fonts/Owners-Bold.woff') format('woff');
}

@font-face {
	font-family: 'Owners-Narrow';
	font-display: swap;
	src: url('/common/fonts/owners_narrow_black.woff') format('woff');
}

@font-face {
	font-family: 'Owners-Regular';
	font-display: swap;
	src: url('/common/fonts/owners_regular.woff') format('woff');
}

@font-face {
	font-family: 'Crimson Text';
	font-display: swap;
	src: url('/common/fonts/CrimsonText-Bold.woff') format('woff');
}

$font-thin: "Roboto", "Arial", sans-serif;
$font-regular: "Roboto-Regular", "Helvetica", "Arial", sans-serif;
$font-medium: "Roboto-Medium", "Helvetica", "Arial", sans-serif;
$font-bold: "Roboto-Bold", "Helvetica", "Arial", sans-serif;
$font-condensed-bold: "RobotoCondensed-Bold", "Helvetica", "Arial", sans-serif;
$font-mono: "RobotoMono-Regular", "Helvetica", "Arial", sans-serif;
$font-crimson: "Crimson Text", "Helvetica", "Arial", sans-serif;
$font-owners-bold: "Owners-Bold", "Helvetica", "Arial", sans-serif;
$font-owners-narrow: "Owners-Narrow", "Helvetica", "Arial", sans-serif;
$font-owners-regular: "Owners-Regular", "Helvetica", "Arial", sans-serif;
	
$font-size-base: 16px;
$line-height-base: 1.875em;

html, body {
	font-family: $font-regular;
	font-size: $font-size-base;
	line-height: $line-height-base;
	color: $color-xdark-gray;

	@include screen-xs-max {
		font-size: 14px;
	}
}

a {
	text-decoration: none;
	transition: all $default-transition;

	&::before, &::after {
		transition: all $default-transition;
	}

	&.link-arrow {
		&::after {
			content: $icon-medium-arrow;
			font-family: $font-icons;
			font-size: 10px;
			position: relative;
			top: -1px;
			margin-left: 8px;
		}
	}

	&.link-viewall {
		font-family: $font-bold;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-family: $font-condensed-bold;
	font-weight: normal;
	color: $color-black;
	margin: 0;
}

h1 {
	font-size: 100px;
	line-height: 1.1em;
	letter-spacing: 5px;

	@include screen-lg-max {
		font-size: 80px;
	}

	@include screen-md-max {
		font-size: 60px;
	}

	@include screen-xs-max {
		font-size: 40px;
	}
}

h2, h1.cms-page-banner-title {
	font-size: 55px;
	line-height: 1.090909em;
	letter-spacing: 2px;

	@include screen-lg-max {
		font-size: 48px;
	}

	@include screen-md-max {
		font-size: 36px;
	}

	@include screen-xs-max {
		font-size: 30px;
	}
}

h3, .sub-title, h1.product-title {
	font-size: 40px;
	line-height: 1.25em;
	letter-spacing: 2px;

	@include screen-lg-max {
		font-size: 34px;
	}

	@include screen-md-max {
		font-size: 28px;
	}

	@include screen-xs-max {
		font-size: 22px;
		letter-spacing: 1px;
	}
}

h4 {
	font-size: 24px;
	line-height: 1.5em;
	letter-spacing: 0.6px;

	@include screen-md-max {
		font-size: 20px;
	}

	@include screen-xs-max {
		font-size: 18px;
	}
}

h5 {
	font-family: $font-medium;
	font-size: 20px;
	line-height: 1.5em;
	letter-spacing: 1.25px;
	text-transform: none;

	@include screen-sm-max {
		font-size: 12px;
		letter-spacing: 0.88px;
	}
}

h6 {
	font-family: $font-medium;
	font-size: 16px;
	line-height: 1.25em;
	letter-spacing: 1px;
}

strong, b {
	font-weight: normal;
	font-family: $font-bold;
}

small {
	font-size: 80%;
}

em, i {
	font-style: italic;
}

sup {
	font-size: 50%;
	line-height: 0;
	position: relative;
	top: -1em;
	left: -0.5em;
}

.red {
	color: $color-red !important;

	h1,h2,h3,h4,h5,h6,p,span,li,a {
		color: $color-red !important;
	}

	svg {
		fill: $color-red !important;
	}
}

.white {
	color: $color-white !important;

	h1,h2,h3,h4,h5,h6,p,span,li,a {
		color: $color-white !important;
	}

	svg {
		fill: $color-white !important;
	}
}

.text-shadow {
	text-shadow: 3px 3px $color-xxdark-gray;
}

.font-crimson {
	h1, h2, h3, h4, h5, h6 {
		font-family: $font-crimson !important;
		letter-spacing: 0 !important;
		text-transform: inherit !important;
		font-weight: 700 !important;
	}

	.product-group-overview-content {
		h2 {
			font-family: $font-crimson !important;
			letter-spacing: 0;
			text-transform: inherit;
		}
	}

	.product-highlight-carousel-nav-link {
		h2 {
			padding-top: 5px;
			line-height: 1.1em;

			span {
				padding-top: 8px;
				display:inline-block;
			}
		}

	}
}

.product-group-banner-wrapper {
	h1.font-owners-bold {
		font-family: $font-owners-bold !important;
		font-size: 70px !important;
		letter-spacing: 50px !important;
		@include screen-lg-max {
			font-size: 50px !important;
			letter-spacing: 40px !important;
		}
		@include screen-md-max {
			font-size: 45px !important;
			letter-spacing: 35px !important;
		}
		@include screen-sm-max {
			font-size: 30px !important;
			letter-spacing: 25px !important;
		}
		@include screen-xs-max {
			letter-spacing: 15px !important;
		}
	}
	h1.font-crimson {
		font-family: $font-crimson !important;
	}
}	

.font-roboto {

	h1 {
		font-family: $font-thin !important;
		letter-spacing: 0.5em !important;
		font-size: 50px !important;
		text-transform: uppercase !important;
		font-weight: 100 !important;
		text-align: center;
	}

	h2, h3, h4, h5, h6 {
		font-family: $font-regular !important;
		letter-spacing: 0 !important;
		text-transform: inherit !important;
	}

	.feature-promo-single-content h2 {
		font-size: 30px !important;
		line-height: 1.25em;
		text-transform: inherit;
		margin-bottom: 10px;
	}

	h3 {
		font-size: 24px;
		line-height: 1.5em;
		letter-spacing: .6px;
	}

	.product-highlight-carousel-nav-link {
		h2 {
			padding-top: 5px;
			line-height: 1.1em;

			span {
				padding-top: 8px;
				display: inline-block;
			}
		}
	}

	@include screen-sm-max {
		.product-group-banner-inner-content {
			width: 100%;
			h1.product-title {
				font-size: 30px !important;
				line-height: 1.5em;
				text-align: center;
			}
		}
	}
}

.rockshox {
	.font-crimson {
		h2 {
			font-size: 50px;
			letter-spacing: 0;
			text-transform: inherit;
		}

		h3 {
			font-size: 40px;
		}

		@include screen-sm-max {
			h2 {
				font-size: 40px;
			}

			h3 {
				font-size: 24px;
			}
		}
	}

	.font-roboto {
		h2 {
			font-size: 30px !important;
			letter-spacing: 0;
			text-transform: inherit;
		}

		h3 {
			font-size: 40px;
		}

		.product-group-overview-content h2 {
			font-size: 20px !important;
		}

		@include screen-sm-max {
			h2 {
				font-size: 30px;
			}

			h3 {
				font-size: 24px;
			}
		}
	}
}

