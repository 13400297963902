﻿.service-footer {
	padding: 93px 0 0 0;

	@include screen-md-max {
		border-top: 0;
		padding: 0;

		.container > div:first-child {
			margin-bottom: 50px;
		}
	}

	h4 {
		border-bottom: 1px solid $color-xdark-gray;
		padding-bottom: 10px;
		margin-bottom: 37px;
	}

	&-button {
		margin-top: 45px;
	}
}
