﻿.page-navigation {
	background: $color-xdark-gray;
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	overflow: hidden;
	position: sticky;
	top: 0px;
	z-index: 5;

	&-title {
		font: 24px/1.5em $font-condensed-bold;
		letter-spacing: 0.6px;
		color: $color-white;
		padding-left: 30px;

		@include screen-lg-max {
			display: none;
		}
	}

	&-links {
		display: inline-flex;
		height: 100%;

		@include screen-lg-max {
			display: block;
			overflow: hidden;
			width: 100%;
			margin: 0 21px;
			position: relative;

			&::before {
				content: '';
				border-bottom: 1px solid $color-white;
				width: 100%;
				position: absolute;
				top: 50px;
			}

			&--expand {
				height: auto;

				li {
					display: block !important;

					&:first-child {
						width: 90%;
					}

					&.active {
						z-index: 1 !important;
					}

					a {
						padding: 7px 15px !important;
						margin-bottom: 5px !important;
						display: block !important;

						&:first-child {
							margin-bottom: 0 !important;
							padding: 17px 15px !important;
						}
					}
				}

				.page-navigation-links--cta {
					display: none !important;
				}

				&::before {
					display: none;
				}
			}
		}

		&--cta {
			background: $color-red;
			transition: background $default-transition;
			margin-left: 30px;

			a {
				color: $color-white !important;
				padding: 17px 30px !important;
			}

			&:hover {
				background: $color-red-hover;
			}

			@include screen-lg-max {
				display: none;
			}
		}

		li {
			display: flex;
			align-items: center;

			a {
				display: block;
				color: $color-gray;
				transition: color $default-transition;
				padding: 17px 15px;

				@include screen-lg-max {
					display: none;
				}

				&:hover, &.active {
					color: $color-white;
				}

				@include screen-lg-max {
					&.active {
						display: block;
						z-index: 1;
						position: relative;
					}
				}
			}
		}
	}

	&-collpase {
		display: none;
		width: 0;
		overflow: hidden;

		@include screen-lg-max {
			position: absolute;
			right: 15px;
			height: 63px;
			width: 100%;
			z-index: 5;
			display: block;
			cursor: pointer;

			&::after {
				font-family: $font-icons;
				font-size: 13px;
				content: $icon-carat;
				position: absolute;
				top: 50%;
				right: 10px;
				color: $color-white;
				transform: translateY(-50%) rotate(90deg);

				@include ie11 {
					top: 0;
				}
			}

			&--expand {
				top: 0;
				z-index: 5;
				width: 30px;

				&::after {
					top: 31px;
					transform: translateY(-50%) rotate(-90deg);
				}
			}
		}
	}

	&--service {
		background-color: $color-red;

		.page-navigation-links {
			li {
				a {
					color: $color-white;

					&:hover, &.active {
						color: $color-gray;
					}
				}

				&:last-child a {
					padding-right: 30px;

					@include screen-lg-max {
						padding-right: 20px;
					}
				}
			}
		}
	}
}
