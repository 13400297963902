﻿.breadcrumbs {
	display: flex;
	padding: 24px 0 33px;

	li, a {
		color: $color-dark-gray;
		font-size: 12px;
		letter-spacing: 0.64px;
		line-height: 1.5em;
	}

	li {
		&::after {
			content: ' / ';
			margin: 0 7px 0 4px;
		}

		&:last-child {
			&::after {
				display: none;
			}
		}
	}
}
