﻿.search-results {
	&-header {
		margin-top: 21px;
		margin-bottom: -19px;
		padding-left: 266px;
		padding-right: 118px;

		@include screen-lg-max {
			padding: 0 20px;
			justify-content: center;
			margin-bottom: 0;
			margin-top: 34px;
		}

		ul {
			display: flex;
			position: relative;
			top: 18px;
			z-index: 5;
			width: 300px;

			@include screen-lg-max {
				top: auto;
				margin-bottom: 25px;
			}
		}

		li {
			margin-right: 31px;
		}

		a {
			font: 12px/1 $font-medium;
			color: $color-black;
			text-transform: uppercase;
			border-bottom: 2px solid $color-white;
			letter-spacing: 1.5px;
			padding-bottom: 6px;

			span {
				color: $color-dark-gray;
				margin-left: 5px;
			}
		}

		a.active, a:hover {
			border-color: $color-red;
		}

		&-term {
			margin-bottom: 31px;
		}
	}

	#product-listing-content {
		.product-listing-toolbar {
			justify-content: flex-end;
		}

		.listing-filters {
			margin-top: 30px;
		}
	}

	#service-listing-content {
		display: none;

		.listing-filters {
			margin-top: 30px;
		}

		.listing-content {
			margin-top: 25px;
		}

		.service-listing-table {
			margin-top: 0;
		}
	}
}
