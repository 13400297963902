﻿.paging {
	width: 300px;
	margin: 100px auto 0;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&-arrow {
		display: block;
		height: 40px;
		width: 40px;
		background: none;
		border: 1px solid $color-black;
		cursor: pointer;
		position: relative;
		transition: background $default-transition, border-color $default-transition, color $default-transition;

		&::after {
			content: $icon-short-arrow;
			font-family: $font-icons;
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			font-size: 20px;
			transform: translate(-50%, -50%);
		}

		&:hover {
			background: $color-black;
			border-color: $color-white;

			&::after {
				color: $color-white;
			}
		}

		&--disabled {
			border-color: $color-gray;
			color: $color-gray;
			cursor: default;

			&:hover {
				border-color: $color-gray;
				background-color: $color-white;

				&::after {
					color: $color-gray;
				}
			}
		}

		&--prev {
			&::after {
				transform: rotate(180deg) translate(50%, 50%);
			}
		}
	}

	&-pager {
		font: 15px/20px $font-mono;
	}
}
