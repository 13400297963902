﻿.orders-table,
.order-detail-table {

    table {
        width: 100%;
        margin-bottom: 34px;

        @include screen-md-max {
            thead {
                display: none;
            }
        }

        tr {
            th, td {
                padding: 23px 0 21px;
                text-align: center;
                font-size: 14px;

                a {
                    text-transform: capitalize;
                }
            }

            th {
                text-transform: uppercase;
                font-weight: bold;
                font-size: 13px;
            }

            @include screen-md-max {
                td {
                    display: block;
                    font-size: 12px;
                    text-align: left;
                    margin-left: 0;
                    padding: 13px 0 11px 10px;

                }

                td::before {
                    content: attr(data-label);
                    margin-right: 5px;
                    font-weight: bold;
                    text-transform: uppercase;
                    display: inline-block;
                    width: 50%;
                }
            }
        }
    }
}

.orders-table {

    table {
        tr {
        
            &:nth-child(even) {
                td {
                    background-color: $color-xxlight-gray;
                }
            }

            @include screen-md-max {
                &:nth-child(even) {
                    td {
                        background-color: $color-white;
                    }
                }

                td {
                    &:first-child {
                        border-top: 20px solid $color-light-gray;
                    }

                    &:nth-child(even) {
                        background-color: $color-xxlight-gray;
                    }
                }

            }
        }
    }
}


.order-detail-table {
    table {
        tr {
            th, td {
                max-width: 100px;

                @include screen-md-max {
                    max-width: 100%;
                }

                &.text-align-left {
                    text-align: left;
                }

                &.text-align-right {
                    text-align: right;

                    @include screen-md-max {
                        text-align: left;
                    }
                }
            }

            td {
                border-top: 1px solid $color-gray;
            }
        }

        @include screen-md-max {
            tr {
                &:nth-child(even) {
                    td {
                        background-color: $color-white;
                    }
                }

                td {
                    border-top: none;

                    &:nth-child(even) {
                        background-color: $color-xxlight-gray;
                    }
                }

                &.order-detail-table--item {
                    td:first-child {
                        border-top: 1px solid $color-gray;
                    }

                    td:last-child {
                        border-bottom: 1px solid $color-gray;
                    }
                }

                &:nth-child(odd) {
                    &.order-detail-table--footer {
                        td {
                            background-color: $color-white;
                        }
                    }
                }
            }
        }
    }
}
