﻿.spec-table {
	&-content {
		border-top: 1px solid $color-dark-gray;
		border-bottom: 1px solid $color-dark-gray;
	}

	h3 {
		padding: 36px 0 36px;
	}

	&-table {
		margin-top: -10px;
	}

	table {
		width: 100%;
		margin-bottom: 34px;

		tr {

			th {
				border-bottom: 1px solid $color-light-gray;
				padding: 23px 0 21px;
				line-height: 20px;
				color: $color-dark-gray;
				font-family: $font-condensed-bold;
				font-size: 15px;
				text-align: left;
				line-height: 16px;
				letter-spacing: 1.5px;
				text-transform: uppercase;
				width: 30%;
			}

			td {
				border-bottom: 1px solid $color-light-gray;
				padding: 23px 0 21px;
				line-height: 20px;
				color: $color-dark-gray;

				&:first-child {
					font-family: $font-condensed-bold;
					font-size: 15px;
					line-height: 16px;
					letter-spacing: 1.5px;
					text-transform: uppercase;
					width: 30%;
				}

				&:last-child {
					padding-left: 150px;
					width: 70%;

					@include screen-xl-max {
						padding-left: 75px;
					}

					@include screen-sm-max {
						padding-left: 15px;
						padding-right: 15px;
					}
				}
			}

			&:last-child {
				td {
					border: none;
				}
			}
		}
	}

	&-search {
		margin: 0 0 58px 0;
		position: relative;

		&-input {
			margin-left: 0 !important;
		}

		input {
			padding-right: 56px;
		}

		::-webkit-input-placeholder {
			color: $color-dark-gray;
			opacity: 1;
		}

		::-moz-placeholder {
			color: $color-dark-gray;
			opacity: 1;
		}

		:-ms-input-placeholder {
			color: $color-dark-gray !important;
			opacity: 1;
		}

		&-button {
			border: 0;
			padding: 0;
			background-color: transparent;
			width: 36px;
			height: 50px;
			cursor: pointer;
			position: absolute;
			right: 24px;
			top: 40px;
			z-index: 2;

			&::before {
				content: $icon-long-arrow;
				font-family: $font-icons;
				font-size: 11px;
				color: $color-dark-gray;
				transition: color $default-transition;
			}

			&:hover::before {
				color: $color-black;
			}
		}

		.field {
			padding-top: 10px;
		}

		label {
			display: none;
			padding-left: 3px;
		}

		.focus, .has-value {
			label {
				top: -2px !important;
				display: block;
			}
		}

		.error {
			label {
				color: $color-red !important;
			}

			input {
				border-color: $color-red !important;
			}
		}

		&-error {
			color: $color-red;
			position: relative;
			font-size: 12px;
			padding-left: 0px;
			//padding-right: 2px;
		}

		&--serial-number {
			font-size: 12px;
			color: $color-dark-gray;

			svg {
				font-size: 14px;
			}

			&:hover {
				color: $color-red;
			}
		}
	}

	&--service {
		margin-top: 58px;
		margin-bottom: 58px;

		.spec-table-content {
			border: 0;
		}

		table {
			tr {
				&:last-child {
					td {
						border-bottom: 1px solid $color-light-gray;
					}
				}
			}
		}
	}
}
