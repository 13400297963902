body.truvativ {
	.header {
		&-branding {
			top: 69px;

			@include screen-lg-max {
				top: 30px;
			}

			svg {
				width: 144px;
				height: 19px;
				fill: $color-black;
			}
		}
	}
}
