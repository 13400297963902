﻿.service-prodsearch {
	position: absolute;
	width: 100vw;
	min-height: 100vh;
	top: 0;
	left: 0;
	background-color: $color-white;
	z-index: 999;
	display: none;
	overflow-y: auto;

	&--open {
		display: block;
	}

	&--relative {
		position: relative;
		display: block;
	}

	&-header {
		background-color: $color-red;
		padding: 55px 0;

		@include screen-lg-max {
			padding: 40px 0;
		}

		@include screen-md-max {
			padding: 20px 0;
		}

		&-content {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		h2 {
			color: $color-white;
		}

		&-close {
			display: block;
			height: 60px;
			width: 60px;
			cursor: pointer;
			color: $color-white;
			text-align: right;

			&::after {
				content: $icon-close;
				font-family: $font-icons;
				font-size: 20px;
				display: block;
				margin-top: 15px;
			}
		}
	}

	&-content {
		display: block;
		padding-top: 60px;
		padding-bottom: 90px;
		background-color: $color-white;

		@include screen-sm-max {
			padding-top: 30px;
		}
	}

	&-nav {
		position: relative;

		ul {
			display: flex;

			@include screen-lg-max {
				display: block;
			}
		}

		li {
			width: 33%;
			margin-right: $gutter;

			@include screen-lg-max {
				width: 100%;
				margin-bottom: 20px;
				position: relative;
			}

			&:last-child {
				margin-right: 0;
			}
		}

		&-filter {
			cursor:pointer;
		}

		&-filtergroup {
			display: block;
			height: 42px;
			width: 100%;
			padding: 0 50px 0 19px;
			border: 1px solid $color-light-gray;
			font: 12px/42px $font-bold;
			color: $color-black;
			text-transform: uppercase;
			letter-spacing: 1.5px;
			position: relative;

			&::after {
				content: $icon-carat;
				font-family: $font-icons;
				font-size: 12px;
				display: block;
				position: absolute;
				top: 1px;
				right: 21px;
				transform: rotate(90deg);
				z-index: 0;
				transition: transform $default-transition;
			}

			span {
				color: $color-dark-gray;
			}
		}

		&-filtergroup--open {
			.service-prodsearch-nav-filtergroup {
				border-color: $color-black;

				&::after {
					transform: rotate(270deg);
				}
			}

			.service-prodsearch-nav-dropdown-wrapper {
				max-height: 1000px;
			}
		}

		&-dropdown-wrapper {
			position: absolute;
			z-index: 10;
			top: 42px;
			left: 0;
			width: 100%;
			background-color: $color-white;
			box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.08);
			max-height: 0;
			overflow: hidden;
			transition: max-height $default-transition;
		}

		&-dropdown {
			padding: 2px 30px 23px;

			a {
				font-size: 14px;
				line-height: 18px;
				letter-spacing: 0.5px;
				color: $color-xdark-gray;
				text-transform: none;
				display: block;
				padding: 0 20px 12px 0;
				width: 16.66667%;

				@include screen-lg-max {
					width: 33.3333%;
				}

				@include screen-md-max {
					width: 50%;
				}

				@include screen-xs-max {
					width: 100%;
				}
			}

			&-search {
				position: relative;

				&::after {
					content: $icon-search;
					font-family: $font-icons;
					font-size: 15px;
					color: $color-black;
					position: absolute;
					right: 0;
					top: 10px;
				}

				input {
					width: 100%;
					height: 50px;
					padding-right: 64px;
					border-bottom: 1px solid $color-xdark-gray;
				}

				::-webkit-input-placeholder {
					color: $color-dark-gray;
					opacity: 1;
				}

				::-moz-placeholder {
					color: $color-dark-gray;
					opacity: 1;
				}

				:-ms-input-placeholder {
					color: $color-dark-gray !important;
					opacity: 1;
				}
			}

			&-content {
				display: flex;
				flex-wrap: wrap;
				padding-top: 37px;
			}

			&-noresults {
				display: none;
			}

			&-clear {
				color: $color-red !important;
				cursor: pointer;
			}

		}

		.service-prodsearch-nav-item--brand {
			position: relative;

			.service-prodsearch-nav-dropdown {
				padding: 0;
			}

			.service-prodsearch-nav-dropdown a {
				width: 100% !important;
				padding: 13px 20px;

				&:hover {
					background-color: $color-xlight-gray;
				}
			}

			.service-prodsearch-nav-dropdown-content {
				display: block;
				padding: 0;
			}
		}
	}

	&-results {
		&-toolbar {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 12px;
			line-height: 1.5em;
			margin-top: 20px;

			p {
				color: $color-xdark-gray;
			}

			a {
				font-family: $font-bold;
				text-transform: uppercase;
				color: $color-black;

				&::after {
					content: $icon-clear;
					font-family: $font-icons;
					font-size: 17px;
					margin-left: 10px;
					position: relative;
					top: 2px;
				}
			}
		}

		&-list {
			display: flex;
			flex-wrap: wrap;
			margin-top: 42px;

			a {
				display: flex;
				width: calc(33.3333% - 24px);
				margin-right: $gutter;
				border-bottom: solid 1px $color-light-gray;
				padding-bottom: 30px;
				margin-bottom: 40px;

				&:nth-child(3n) {
					margin-right: 0;
				}

				@include screen-lg-max {
					width: calc(50% - 10px);
					margin-right: $page-gutter-sm;

					&:nth-child(2n) {
						margin-right: 0;
					}
				}

				@include screen-md-max {
					width: 100%;
					margin-right: 0;
				}

				img {
					display: block;
					width: 50px;
					height: auto;
					margin-right: 20px;
				}

				p {
					font: 16px/20px $font-medium;
					color: $color-xdark-gray;

					span {
						font: 14px/20px $font-mono;
						color: $color-dark-gray;
					}
				}
			}
		}
	}

	&-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 99;
		background-color: rgba(245, 245, 245, 0.8);
		display: none;

		img {
			position: absolute;
			top: 200px;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&-content--loading {
		.service-prodsearch-overlay {
			display: block;
		}
	}
	&-clearall{
		cursor: pointer;
	}
}
