﻿.listing {
	position: relative;

	@include screen-lg-max {
		position: static;
	}

	.breadcrumbs {
		margin-left: $page-gutter;

		@include screen-lg-max {
			margin-left: $page-gutter-sm;
		}

		li.listing-breadcrumbs--tag {
			&::after {
				display: none;
			}

			@include screen-lg-max {
				display: none;
			}

			a {
				position: relative;
				margin-right: 20px;
				cursor: pointer;
				white-space: nowrap;

				@include screen-lg-max {
					display: none;
				}

				&::after {
					content: ',';
					margin: 0;
					position: absolute;
					right: -17px;
				}

				&::before {
					content: $icon-remove;
					position: absolute;
					right: -14px;
					font-family: $font-icons;
				}
			}

			&:last-child a::after {
				display: none;
			}
		}
	}

	&-wrapper {
		display: flex;
		justify-content: space-between;
		padding: 0 30px;

		@include screen-lg-max {
			padding: 0 20px;
		}
	}

	&-content {
		width: calc(100% - 236px);
		padding-right: 118px;

		@include screen-xl-max {
			padding-right: 0;
		}

		@include screen-lg-max {
			width: 100%;
		}
	}

	&-filters {
		margin-top: 30px;
		width: 200px;

		@include screen-lg-max {
			width: 100vw;
			height: 100vh;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			z-index: 3;
			margin: 0;
			overflow-y: auto;
			background-color: $color-white;
			transform: translate(-100%, 0);
			transition: transform $default-transition;

			&--open {
				transform: translate(0, 0);
			}
		}

		&-title {
			@include screen-lg-max {
				display: none;
			}

			&--mobile {
				display: none;
				@include screen-lg-max {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 9px 20px;
					border-bottom: 1px solid $color-black;

					#btn-filters-close {
						position: relative;
						display: block;
						width: 30px;
						height: 30px;
						color: $color-black;

						&::after {
							content: $icon-carat;
							font-family: $font-icons;
							font-size: 22px;
							display: block;
							position: absolute;
							top: 0;
							left: 50%;
							transform: rotate(180deg);
						}
					}
				}
			}
		}

		&-sortby {
			display: none;

			@include screen-lg-max {
				display: block;
				padding: 20px;
				border-bottom: 1px solid $color-black;
			}
		}

		&-selected {
			display: none;
			@include screen-lg-max {
				display: block;
			}

			li {
				padding: 0 20px;
			}

			li:first-child {
				padding-top: 20px;
			}

			li:last-child {
				padding-bottom: 20px;
				border-bottom: 1px solid $color-light-gray;
			}

			a {
				display: inline-block;
				color: $color-black;
				border: 1px solid $color-black;
				font-size: 14px;
				line-height: 33px;
				border-radius: 16px;
				padding: 0 18px;
				margin: 0 15px 8px 0;
				white-space: nowrap;

				&::before {
					content: $icon-close;
					font-family: $font-icons;
					font-size: 11px;
					margin-right: 10px;
				}
			}
		}

		&-clear {
			border-top: 1px solid $color-black;
			border-bottom: 1px solid $color-black;
			margin-top: 21px;
			cursor: pointer;

			@include screen-lg-max {
				border-top: 0;
				margin-top: 0;
			}

			a {
				display: block;
				position: relative;
				font-size: 12px;
				font-family: $font-bold;
				letter-spacing: 1.5px;
				text-transform: uppercase;
				padding: 12px 0;
				color: $color-black;

				@include screen-lg-max {
					padding: 12px 20px;
				}

				&::after {
					float: right;
					display: block;
					font-family: $font-icons;
					content: $icon-clear;
					color: $color-black;
					font-size: 16px;
				}
			}
		}

		&-section {
			border-bottom: 1px solid $color-black;
			font-size: 14px;

			@include screen-lg-max {
				padding: 0 20px;
			}

			&-header {
				font-size: 12px;
				font-family: $font-bold;
				letter-spacing: 1.5px;
				padding: 12px 0;
				text-transform: uppercase;
			}

			ul {
				max-height: 150px;
				overflow: hidden;
				margin-bottom: 11px;
				transition: max-height $default-transition;

				li {
					margin-bottom: 13px;
				}
			}

			&-content--open {
				ul {
					max-height: 4000px !important;
				}

				.listing-filters-toggle::before {
					transform: rotate(0);
				}
			}
		}

		&-toggle {
			display: block;
			position: relative;
			padding-left: 45px;
			margin: -17px 0 17px;
			font-size: 14px;
			color: $color-xdark-gray;

			&::after, &::before {
				content: '';
				display: block;
				width: 10px;
				height: 2px;
				position: absolute;
				left: 25px;
				top: 50%;
				margin-top: -2px;
				background-color: $color-dark-gray;
			}

			&::before {
				transform: rotate(90deg);
				transition: transform $default-transition;
			}
		}

		&-done {
			display: none;

			@include screen-lg-max {
				display: block;
				padding: 20px 20px;

				a {
					width: 100%;
					text-align: center;
				}
			}
		}
	}

	&-load-more {
		padding-bottom: 40px;

		button {
			margin: 0 auto;
		}
	}

	.collapse--open {
		.collapse-target {
			max-height: 4000px;
		}
	}

	&-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 99;
		background-color: rgba(245, 245, 245, 0.8);
		display: none;

		img {
			position: absolute;
			top: 200px;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&--loading {
		.listing-overlay {
			display: block;
		}
	}
}
