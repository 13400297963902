﻿body.rockshox {
	.product-group {
		&-banner {
			&-inner {
				display: block;

				&-content {
					text-align: center;

					h3 {
						font-size: 100px;
						line-height: 1em;
						letter-spacing: 10px;

						@include screen-lg-max {
							font-size: 70px;
						}

						@include screen-md-max {
							font-size: 28px;
						}

						@include screen-xs-max {
							font-size: 22px;
						}
					}

					img {
						margin: 0 auto 15px auto;
					}
				}

				&-button {
					text-align: center;
					margin-top: 13px;

					@include screen-md-max {
						margin-top: 0;
					}
				}
			}
		}

		&-overview {
			&-content {
				h2 {
					@extend h5;
					margin-bottom: 6px;
				}
			}
		}
	}
}
