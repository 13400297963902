﻿body.rockshox {
	.product-highlight-carousel {
		&-nav {
			&-link {
				padding: 0 18px;
				margin-bottom: 0;
				@include screen-xl-max {
					padding: 0 18px;
				}

				h2 {
					color: $color-black;
					font-size: 50px;
					line-height: 1em;
					letter-spacing: 4px;

					@include screen-xl-max {
						font-size: 40px;
						line-height: 1em;
					}
				}

				&:hover, &--active {
					span {
						background-color: transparent;
						box-shadow: none;
					}

					h2 {
						color: $color-red;
					}
				}
			}
		}

		&-item {
			&-title {
				@include screen-lg-max {
					padding: 9px 20px 4px;

					h2 {
						color: $color-white;
						letter-spacing: 4px;
					}
				}
			}
		}
	}
}
