:root {
	--full-bleed-gutter-sides: 10%;
	--full-bleed-gutter-top: 8%;
	--full-bleed-headline-color: var(--page-background-color);
}

.full-bleed-image img, .full-bleed-image video {
	display: block;
	height: auto;
	width: 100%;
}

.full-bleed-image {
	position: relative;
	color: var(--full-bleed-headline-color);

	.full-bleed-text {
		position: absolute;
		color: var(--full-bleed-headline-color);
		font-size: 9vw;
		font-family: sans-serif;
		width: 80%;

		&.bottom-left {
			bottom: var(--full-bleed-gutter-top);
			left: var(--full-bleed-gutter-sides);
		}

		&.bottom-right {
			bottom: var(--full-bleed-gutter-top);
			right: var(--full-bleed-gutter-sides);
			text-align: right;
		}

		&.bottom-center {
			bottom: var(--full-bleed-gutter-top);
			left: 50%;
			text-align: center;
			transform: translate(-50%);
		}

		&.top-left {
			top: var(--full-bleed-gutter-top);
			left: var(--full-bleed-gutter-sides);
		}

		&.top-right {
			top: var(--full-bleed-gutter-top);
			right: var(--full-bleed-gutter-sides);
			text-align: right;
		}

		&.top-center {
			top: var(--full-bleed-gutter-top);
			left: 50%;
			transform: translate(-50%, 0);
			text-align: center;
		}

		&.center-left {
			left: var(--full-bleed-gutter-sides);
			bottom: 50%;
			transform: translate(0, 50%);
		}

		&.center-right {
			right: var(--full-bleed-gutter-sides);
			top: 50%;
			transform: translate(0, -50%);
			text-align: right;
		}

		&.center-center {
			left: 50%;
			top: 50%;
			text-align: center;
			transform: translate(-50%, -50%);
		}

		h2, h3 {
			color: var(--full-bleed-headline-color);
		}

		h2 {
			font-size: 9vw;
		}

		h3 {
			font-size: 4vw;
		}
	}
}