﻿.address-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 225px;
    width: 100%;
    color: $color-dark-gray;
    font-size: 14px;

     @include screen-md-max {
         height: auto;
     }

    a {
        font-size: 12px;
    }

    &-header {
        color: $color-black;
    }

    &-border {
        @extend .address-container;
        border: 1px dashed $color-dark-gray;
        align-items: center;
        text-align: center;
        color: $color-dark-gray;
    }
}
