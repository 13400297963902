﻿:root{
	--c-inline-image-color: var(--font-color);
	--c-inline-image-background: var(--page-background, #fff);
}

.inline-image {
	background: var(--c-inline-image-background);
	color: var(--c-inline-image-color);
	img, video {
		width: 100%;
		height: auto;
		display: block;
	}

	p {
		// color: var(--c-inline-image-color);
		font-size: 12px;
		line-height: 1.5em;
		margin-top: 10px;
	}
}
