#video-swiper-modal {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: linear-gradient(
		90deg,
		hsla(351, 80%, 2%, .85),
		hsla(351, 80%, 4%, .85)
	);
	z-index: 100;
}
#video-swiper-modal {
	.video-slider-modal-content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 360px;
		max-width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		iframe{
			max-width: 100%;
			width: 360px;
			margin: auto;
		}
	}
} 
  

:root {
	--slider-item-background: black;
	--slider-item-color: white;
	--video-slider-background: var(--page-background, #fff);
}

.swiper-wrapper figure{
	margin: 0;
}

.swiper-notification {
	display: none;
}

.video-slider-section {
	background: var(--video-slider-background);
	.video-slider-container-wrapper {
		padding-top: 30px;
		.video-slider-container-header {
			margin: 30px 0;
			h2,h3 {
				margin: 30px 0;
			}
		}
	}
}

.video-slider-container{

	.swiper {
		grid-column: 2;
		min-width: 350px;
		width: 100%;
		margin-bottom: 10px;
		position: relative;
		padding: 15px 0;
	}
	.shadow-right::after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		// z-index: 1;
		box-shadow: 
		inset 0 15px 0px 0px var(--video-slider-background), 
		inset 0 -15px 0px 0px var(--video-slider-background), 
		inset 6px 0 15px -4px transparent,
		inset -6px 0 15px -4px rgba(0, 0, 0, 0.7);
		pointer-events: none;
		margin: -5px 0;
		z-index: 1;
	}
	.shadow-left::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		box-shadow: 
		inset 0 15px 0px 0px var(--video-slider-background),
		inset 0 -15px 0px 0px var(--video-slider-background), 
		inset 6px 0 15px -4px rgba(0, 0, 0, 0.7),
		inset -6px 0 15px -4px transparent;
		pointer-events: none;
		margin: -5px;
		z-index: 2;
	}

	.slider-item.swiper-slide {
		box-sizing: border-box;
		scroll-snap-align: center;
		flex: none;
		background: var(--slider-item-background, black);
		font-size: 20px;
		width: var(--slider-item-width, 189px);
		font-family: $font-medium;
		color: var(--slider-item-color);
	}

	button.slider-item {
		border: none;
		padding: 0;
		text-align: left;
	}

	.slider-item-content {
		padding: 20px 20px 0px 20px;
		height: 44px;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical; 
		overflow: hidden;
		border-bottom: 20px solid var(--slider-item-background);
		box-sizing: content-box;
		text-transform: none;
	}

	.slider-item-content h2, h3, h4, h5, p, figcaption {
		margin-top: 0;
		font-family: $font-medium;
		font-size: 18px;
		line-height: 22px;
		font-weight: 600;
		position: relative;
		color: $color-white;
		text-transform: none;
	}

	.slider-item:hover .slider-item-content{
		transition: ease-in-out 0.19s;
		border-bottom: 20px solid hsl(351, 80%, 50%);
	}

	.slider-item img {
		width: 100%;
		display: block;
	}
	.slider-item:hover {
		transition: ease-in-out 0.19s;
		cursor: pointer;
	}

	.slider-item-video {
		position: relative;
	}
	.image-wrapper {
		position: relative;
		width: 100%;
		max-height: 336px;
		aspect-ratio: .526;
		overflow: hidden;
	}
	.slider-item-video .image-wrapper:before,
	.slider-item-video .image-wrapper:after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 46px;
		height: 46px;
		display: block;
		border-radius: 50%;
		background-color: hsla(0, 50%, 100%, 0.8);
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
	}
	.slider-item-video .image-wrapper:after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(calc(50% - 15px), -50%) rotate(45deg);
		width: 0px;
		height: 0px;
		border-radius: 0;
		border-top: 6px solid black;
		border-right: 6px solid black;
		border-left: 6px solid transparent;
		border-bottom: 6px solid transparent;
		background-color: transparent;
		box-shadow: none;
	}
	.slider-item-video {
		background: var(--item-background);
		color: $color-white;
	}
	.slider-item-video:hover .image-wrapper:before {
		background: hsla(353, 96%, 56%, 0.7);
		transition: ease-in-out 0.19s;
	}
	.slider-item-video:hover {
		background: hsl(351, 80%, 50%);
		color: $color-white;
	}

	/*  don't show buttons on mobile */
	.swiper-button-prev,
	.swiper-button-next {
		display: none;
	}
}
@media screen and (min-width: 428px){
	#video-swiper-modal{
		.video-slider-modal-content{
			width: 100%;
			iframe {
				width: 100%;
			}
		}
	}
	.video-slider-container{
		.slider-item {
			width: 210px;
		}
	}
	
}

/* desktop */
@media screen and (min-width: 700px) {
	#video-swiper-modal{
		.video-slider-modal-content{
			width: 100%;
			iframe {
				width: 640px;
			}
		}
	}
	.video-slider-container {
		display: grid;
		grid-template-columns: 40px auto 40px;
		grid-gap: 30px;
		margin-left: -70px;
		margin-right: -70px;

		.swiper-button-prev {
			display: block;
			position: inherit;
			grid-column: 1;
			grid-row: 1;
			place-self: center;
			// border: 1px solid var(--item-background);
			padding: 10px 15px;
		}

		.swiper-button-next {
			display: block;
			position: inherit;
			grid-column: 3;
			grid-row: 1;
			place-self: center;
			// border: 1px solid var(--item-background);
			padding: 10px 15px;
		}

		.slider-item.swiper-slide {
			width: 252px;
		}

		.image-wrapper {
			max-height: 448px;
		}

		.slider-item-content {
			height: 64px;
			box-sizing: content-box;
		}

		.slider-item-content h2, h3, h4, h5, p, figcaption {
			font-size: 24px;
			font-family: $font-medium;
			line-height: 30px;
			font-weight: 600;
		}

		.swiper-button-next:focus, .swiper-button-prev:focus {
			outline: 1px solid $color-black;
		}

		.swiper-button-next.swiper-button-lock {
			display: none;
		}

		.swiper-button-prev.swiper-button-lock {
			display: none;
		}

		.swiper-button-disabled {
			border: 1px solid #ddd;

			&.carousel-arrows-arrow:after {
				color: #ddd;
			}
		}
	}
	
}
