:root{
    --color-black: #000000;
    --color-white: #ffffff;

    --color-green: #00A12D;
    //Grays
    --color-xxdark-gray: #141414;
    --color-xdark-gray: #252626;
    --color-dark-gray: #767676;  /* original color:#797e7f; Updated for contrast accessibility */
    --color-gray: #aab1b3;
    --color-light-gray: #ededee;
    --color-xlight-gray: #f5f5f5;
    --color-xxlight-gray: #f7f7f7;

    // Brand Colors
    --color-red: #e51937;
    --color-red-hover: #f50529;
    --color-dark-red: #bd152e;

    --color-rockshox-red: #ee584c;
    --color-rockshox-green: #888d60;
    --color-rockshox-beige: #f1f0ea;

    --page-width: 1380px;
    --column: 82px;
    --gutter: 36px;
    --grid-columns: 12;
    --page-gutter: 30px;
    --page-gutter-sm: 20px;

    --screen-xl: 1440px;     /* hi-res laptops and desktops */
    --screen-lg: 1024px;     /* big landscape tablets, laptops, and desktops */
    --screen-md: 768px;      /* tablet, landscape iPad, lo-res laptops ands desktops */
    --screen-sm: 640px;      /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
    --screen-xs: 480px;      /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */

    --default-transition: 0.2s ease-in;

    // Theme
    --page-background: var(--color-white);
    --font-color: var(--color-xdark-gray);
    --heading-color: var(--color-black);
}

$color-black: #000000;
$color-white: #ffffff;
$color-off-white: #F8F9FA;

$color-green: #00A12D;

//Grays
$color-xxdark-gray: #141414;
$color-xdark-gray: #252626;
$color-dark-gray: #767676;  /* original color:#797e7f; Updated for contrast accessibility */
$color-dark-light-gray: #d9dbdf;
$color-gray: #aab1b3;
$color-light-gray: #ededee;
$color-xlight-gray: #f5f5f5;
$color-xxlight-gray: #f7f7f7;

// Brand Colors
$color-red: #e51937;
$color-red-hover: #f50529;
$color-dark-red: #bd152e;

$color-rockshox-red: #ee584c;
$color-rockshox-green: #888d60;
$color-rockshox-beige: #f1f0ea;

// Other Colors
$accent-color: #DCF945;

$page-width: 1380px;
$column: 82px;
$gutter: 36px;
$grid-columns: 12;
$page-gutter: 30px;
$page-gutter-sm: 20px;

$screen-xl: 1440px;     /* hi-res laptops and desktops */
$screen-lg: 1024px;     /* big landscape tablets, laptops, and desktops */
$screen-md: 768px;      /* tablet, landscape iPad, lo-res laptops ands desktops */
$screen-sm: 640px;      /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
$screen-xs: 480px;      /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */

$default-transition: 0.2s ease-in;
