﻿input[type=text], input[type=password], textarea,
.dealer-locator #storemapper #storemapper-form #storemapper-zip {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-clip: padding-box;
	background-color: $color-white;
	border: 0;
	border-radius: 0;
	border-bottom: 1px solid $color-xdark-gray;
	color: $color-black;
	font-family: $font-regular;
	font-size: $font-size-base;
	line-height: 1;
	display: block;
	margin: 0;
	padding: 15px 0;
	width: 100%;
	height: auto;
	box-shadow: none;

	&:focus {
		border-bottom: 2px solid $color-black;
		padding-bottom: 14px;
		outline: 0;
	}

	&:focus::-webkit-input-placeholder {
		opacity: 1;
	}

	&:focus::-moz-placeholder {
		opacity: 1;
	}

	&:focus:-ms-input-placeholder {
		opacity: 1;
	}

	&.input-error {
		border-color: $color-red !important;
	}

	&[readonly] {
		color: $color-gray;
	}
}

textarea {
	height: 150px;
	resize: vertical;
}

::-webkit-input-placeholder {
	color: $color-gray;
	opacity: 1;
	transition: opacity $default-transition;
}

::-moz-placeholder {
	color: $color-gray;
	opacity: 1;
	transition: opacity $default-transition;
}

:-ms-input-placeholder {
	color: $color-gray !important;
	opacity: 1;
	transition: opacity $default-transition;
}

p.error {
	color: $color-red !important;
}

form .form-list {
	input[type=text], input[type=password], textarea {
		border: 1px solid $color-light-gray;
		border-radius: .25rem;
		margin-left: -10px;
		padding-left: 10px;
		background-color: transparent;
	}

	.custom-select {
		margin-left: -10px;
		margin-right: 10px;
	}

	list-style-type: none;

	label {
		position: absolute;
		top: 11px;
		left: 0;
		color: $color-dark-gray;

		&.std-label {
			display: inline-block;
			top: 0px;
			left: 0px;
			font-size: 12px;
			position: relative;

			svg {
				color:$color-red;
			}
		}
	}

	.focus, .has-value.error {
		label.std-label {
			font-size: 12px !important;
			top: 0px !important;
		}
	}

	li {
		margin-bottom: 20px;
		padding: 0;

		&::before {
			display: none;
		}
	}

	.field {
		position: relative;

		&.error {
			input {
				border: 1px solid $color-red;
			}

			label {
				color: $color-red;
			}
		}

		&.focus, &.has-value {
			label {
				top: -3px; // -13px;
				font-size: 9px;
			}
		}

		&.has-value-alt {
			label {
			}
		}

		&.focus {
			input[type=text], input[type=password], textarea {
				border: 1px solid $color-xdark-gray;
			}

			label {
				color: $color-xdark-gray;
			}

			p.note {
				color: $color-dark-gray;
			}
		}

		&.has-value {
			label {
				color: $color-dark-gray;
			}
		}

		&-dark {
			@extend .field;

			input[type=text], input[type=password] {
				background-color: $color-white !important;
				border: 0;
				border-radius: 0;
			}

			label {
				color: $color-gray;
			}
		}
	}



	li.fields {
		display: flex;

		@include screen-md-max {
			display: block;
		}

		& > .field,
		& > .field-alt {
			width: calc(50%);
			margin-right: 36px;

			&:last-child {
				margin-right: 0;
			}
		}

		& > .field-alt {
			width: calc(33.3%);
		}

		& > .field,
		& > .field-alt {
			@include screen-md-max {
				width: 100%;
				margin-right: 0;
				margin-bottom: 20px;
			}
		}
	}

	p.error {
		padding: 3px 5px;
	}

	p.note {
		position: absolute;
		bottom: -24px;
		left: 10px;
		font-size: 12px;
		line-height: 1;
		color: $color-gray;
	}
}

.buttons-set {
	text-align: left;
	display: flex;
	align-items: center;

	p.error {
		margin-left: 30px;
	}
}

/*
 * Checkboxes and radios
 */

.field.error > .border {
	border-color: $color-red !important;
}

.control {
	position: relative !important;
	display: inline-block;
	padding-left: 1.5rem;
	color: #555;
	cursor: pointer
}

.control input {
	position: absolute;
	opacity: 0;
	z-index: -1; /* Put the input behind the label so it doesn't overlay text */
}

.control-indicator {
	position: absolute;
	top: .25rem;
	left: 0;
	display: block;
	width: 1rem;
	height: 1rem;
	line-height: 1rem;
	font-size: 65%;
	color: #eee;
	text-align: center;
	background-color: #eee;
	background-size: 50% 50%;
	background-position: center center;
	background-repeat: no-repeat;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hover state */
/* Uncomment if you need it, but be aware of the sticky iOS states.
.control:hover .control-indicator {
  color: #fff;
  background-color: #ccc;
}
*/

/* Focus */
.control input:focus ~ .control-indicator {
	box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;
}

/* Checked state */
.control input:checked ~ .control-indicator {
	color: #fff;
	background-color: #00A12D;
}

/* Active */
.control input:active ~ .control-indicator {
	color: #fff;
	background-color: #84c6ff;
}

.checkbox, .radio {
	color: $color-black !important;
}

/* Checkbox modifiers */
.checkbox .control-indicator {
	border-radius: 100%;
}

.error .checkbox span {
	color: $color-red;
}

.checkbox input:checked ~ .control-indicator {
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
}

/* Radio modifiers */
.radio .control-indicator {
	border-radius: 100%;
}



.radio input:checked ~ .control-indicator {
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTQsMUMyLjMsMSwxLDIuMywxLDRzMS4zLDMsMywzczMtMS4zLDMtM1M1LjcsMSw0LDF6Ii8+DQo8L3N2Zz4NCg==);
}

/* Alternately, use another character */
.control-x input:checked ~ .control-indicator {
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iOHB4IiBoZWlnaHQ9IjhweCIgdmlld0JveD0iMCAwIDggOCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgOCA4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0xLjQsMEwwLDEuNGwwLjcsMC43bDEuOCwxLjhMMC43LDUuN0wwLDYuNGwxLjQsMS40bDAuNy0wLjdsMS44LTEuOGwxLjgsMS44bDAuNywwLjdsMS40LTEuNEw3LjEsNS43DQoJTDUuMywzLjlsMS44LTEuOGwwLjctMC43TDYuNCwwTDUuNywwLjdMMy45LDIuNUwyLjEsMC43QzIuMSwwLjcsMS40LDAsMS40LDB6Ii8+DQo8L3N2Zz4NCg==);
}

.control-dash input:checked ~ .control-indicator {
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iOHB4IiBoZWlnaHQ9IjhweCIgdmlld0JveD0iMCAwIDggOCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgOCA4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0wLDN2Mmg4VjNIMHoiLz4NCjwvc3ZnPg0K)
}

/*
 * Select
 */

.select {
	position: relative;
	display: inline-block;
	color: #555;
}

.select select {
	display: inline-block;
	width: 100%;
	margin: 0;
	padding: .5rem 2.25rem .5rem 1rem;
	line-height: 1.5;
	color: #555;
	background-color: #eee;
	border: 0;
	border-radius: .25rem;
	cursor: pointer;
	outline: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none; 
}
/* Undo the Firefox inner focus ring */
.select select:focus:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #000;
}
/* Dropdown arrow */
.select:after {
	position: absolute;
	top: 50%;
	right: 1.25rem;
	display: inline-block;
	content: "";
	width: 0;
	height: 0;
	margin-top: -.15rem;
	pointer-events: none;
	border-top: .35rem solid;
	border-right: .35rem solid transparent;
	border-bottom: .35rem solid transparent;
	border-left: .35rem solid transparent;
}

/* Hover state */
/* Uncomment if you need it, but be aware of the sticky iOS states.
.select select:hover {
  background-color: #ddd;
}
*/

/* Focus */
.select select:focus {
	box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;
}

/* Active/open */
.select select:active {
	color: #fff;
	background-color: #0074d9;
}

/* Hide the arrow in IE10 and up */
.select select::-ms-expand {
	display: none;
}

/* Media query to target Firefox only */
@-moz-document url-prefix() {
	/* Firefox hack to hide the arrow */
	.select select {
		text-indent: 0.01px;
		text-overflow: '';
		padding-right: 1rem;
	}
	/* <option> elements inherit styles from <select>, so reset them. */
	.select option {
		background-color: #fff;
	}
}

/* IE9 hack to hide the arrow */
@media screen and (min-width:0\0) {
	.select select {
		z-index: 1;
		padding: .5rem 1.5rem .5rem 1rem;
	}

	.select:after {
		z-index: 5;
	}

	.select:before {
		position: absolute;
		top: 0;
		right: 1rem;
		bottom: 0;
		z-index: 2;
		content: "";
		display: block;
		width: 1.5rem;
		background-color: #eee;
	}

	.select select:hover,
	.select select:focus,
	.select select:active {
		color: #555;
		background-color: #eee;
	}
}

.payment-section { 
	iframe {
		width: 350px;
		height: 260px;
		margin-top: 10px;
	}
}
