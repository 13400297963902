﻿.button {
	display: inline-block;
	color: $color-white;
	font: 12px/42px $font-bold;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	text-align: center;
	// white-space: nowrap;
	background-color: $color-red;
	border: 1px solid $color-red;
	padding: 0 21px;
	transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
	text-decoration: none;
	cursor: pointer;

	&:hover {
		text-decoration: none;
		background-color: #f50529;
		border-color: #f50529;
	}

	&--alt-aside {
		@extend .button;
		position: relative;
		color: $color-white;
		border-color: $color-white;
		background-color: $color-gray;
	}

	&--alt-light {
		@extend .button;
		position: relative;
		color: $color-white;
		border-color: $color-white;
		background-color: transparent;

		&:hover {
			color: $color-black !important;
			background-color: $color-white;
			border-color: $color-white;
		}
	}

	&--alt-dark {
		@extend .button;
		color: $color-black;
		border-color: $color-black;
		background-color: transparent;

		&:hover, &.active {
			color: $color-white !important;
			background-color: $color-black;
			border-color: $color-black;
		}

		&::before {
			background-color: $color-black;
			color: $color-white;
		}
	}

	&--alt-dark-underline {
		@extend .button;
		position: relative;
		color: $color-black;
		background-color: transparent;
		border: 1px solid transparent;
		padding: 10px 0;
		line-height: 1;

		&:after {
			content: '';
			position: absolute;
			width: 98%;
			display: block;
			height: 2px;
			background-color: $color-black;
			bottom: 5px;
		}

		&:hover {
			background-color: transparent;
			border: 1px solid transparent;
		}

		&:hover:after {
			background-color: $color-red;
		}
	}

	&--alt-red {
		@extend .button;
		color: $color-dark-red;
		border-color: $color-dark-red;
		background-color: transparent;

		&:hover {
			color: $color-white !important;
			background-color: $color-red;
		}

		&::before {
			background-color: $color-red;
			color: $color-white;
		}
	}

	&--alt-external {
		@extend .button;
		@extend .button--alt-dark;
		position: relative;
		padding-left: 72px;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			display: block;
			width: 44px;
			height: 42px;
			background-color: $color-black;
		}

		&::after {
			content: $icon-long-arrow;
			font-family: $font-icons;
			font-size: 10px;
			color: $color-white;
			position: absolute;
			left: 5px;
			top: 0;
			transform: rotate(-45deg);
		}
	}

	&--icon {
		position: relative;
		padding-left: 72px;

		@include screen-sm-max {
			padding-left: 60px;
		}

		&::before {
			font-family: $font-icons;
			position: absolute;
			font-size: 27px;
			padding: 0 8px;
			left: 0;
			top: 0;
		}

		&-wrench {
			&::before {
				content: $icon-wrench;
			}
		}

		&-question {
			&::before {
				content: $icon-question;
			}
		}
	}

	&--pdf, &--html {
		svg {
			width: 13px;
			height: 15px;
			position: relative;
			top: 2px;
			margin-right: 10px;
		}
	}

	&--vid {
		svg {
			width: 19px;
			height: 15px;
			position: relative;
			top: 3px;
			margin-right: 10px;
		}
	}

	&[disabled] {
		background-color: $color-white;
		border-color: $color-gray;
		color: $color-dark-gray;
		opacity: 0.5;
		cursor: default;

		&:hover {
			background-color: $color-white !important;
			border-color: $color-gray !important;
			color: $color-gray !important;
		}
	}
}

.action-link,
.action-link > a,
.action-link-alt,
.action-link-alt > a,
.action-link-child,
.action-link-child > a,
.action-link--disabled,
.action-link--disabled > a {
	line-height: 43px;
	text-transform: uppercase;
	color: $color-black;
}

.action-link-alt,
.action-link-alt > a {
	color: $color-red;
}

.action-link-child,
.action-link-child > a {
	line-height: 33px;
}

	.action-link--disabled {
		color: $color-gray !important;
	}



	.rich-text {

		p.button {
			all: initial;

			&::before, &::after {
				display:none;
			}

			a {
				@extend .button;
				color: $color-white !important;
				margin: 0 10px 10px 0;
			}

			&--alt-aside {

				a {
					@extend .button;
					position: relative;
					color: $color-white;
					border-color: $color-white;
					background-color: $color-gray;
				}
			}

			&--alt-light {

				a {
					@extend .button;
					position: relative;
					color: $color-white;
					border-color: $color-white;
					background-color: transparent;
				}

				&:hover {
					a {
						color: $color-black !important;
						background-color: $color-white;
						border-color: $color-white;
					}
				}
			}

			&--alt-dark {

				a {
					@extend .button;
					color: $color-black !important;
					border-color: $color-black;
					background-color: transparent;
				}

				&:hover, &.active {
					a {
						color: $color-white !important;
						background-color: $color-black;
						border-color: $color-black;
					}
				}

				&::before {
					a {
						background-color: $color-black;
						color: $color-white;
					}
				}
			}

			&--alt-red {

				a {
					@extend .button;
					color: $color-dark-red !important;
					border-color: $color-dark-red;
					background-color: transparent;
				}

				&:hover {
					a {
						color: $color-white !important;
						background-color: $color-red;
					}
				}

				&::before {
					a {
						background-color: $color-red;
						color: $color-white;
					}
				}
			}

			&--alt-external {

				a {
					@extend .button;
					@extend .button--alt-dark;
					position: relative;
					padding-left: 72px;

					&::before {
						content: '';
						position: absolute;
						left: 0;
						top: 0;
						display: block;
						width: 44px;
						height: 42px;
					}

					&::after {
						content: $icon-long-arrow;
						font-family: $font-icons;
						font-size: 10px;
						color: $color-white;
						position: absolute;
						left: 5px;
						top: 0;
						transform: rotate(-45deg);
					}
				}
			}
		}
	}

.button-switch {

	@extend .button;
	color: $color-white;
	border-color: none;
	font: 12px/12px Roboto-Bold,Helvetica,Arial,sans-serif;
	background-color: rgba(0, 0, 0, 0.1) !important;
	border: 1px solid #777!important;
	padding: 0 0;
	height: 16px;
	outline: none;
	width: 105px;
	text-align: left;

	.contrast {
		position: absolute;
		padding: 1px 5px 1px 30px;
		text-transform: capitalize;
		color: $color-gray;
		letter-spacing: 0.64px;
		font-size: 12px;
		font-family: 'Roboto-Regular';

		&:hover {
			cursor: pointer;
		}
	}

	&:hover {
		border-color: $color-red;
		cursor: pointer;
	}

	.switch {
		position: relative;
		display: inline-block;
		width: 30px;
		height: 14px;
		top: 0;

		input {
			opacity: 0;
			width: 0;
			height: 0;
		}

		input:checked + .slider {
			background-color: $color-gray;
		}

		input:focus + .slider {
			box-shadow: 0 0 1px $color-black;
		}

		input:checked + .slider:before {
			-webkit-transform: translateX(15px);
			-ms-transform: translateX(15px);
			transform: translateX(15px);
			background-color: $color-red;
		}

		.slider {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: $color-gray;
			-webkit-transition: .4s;
			transition: .4s;

			&:before {
				position: absolute;
				content: "";
				height: 14px;
				width: 15px;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: white;
				-webkit-transition: .4s;
				transition: .4s;
			}
		}
	}
}
