﻿.product-swatches {
	a {
		position: relative;
		display: block;
		height: 26px;
		width: 26px;
		border-radius: 50%;
		overflow: hidden;
		text-indent: 110%;

		&.selected::after {
			content: '';
			width: 24px;
			height: 24px;
			border-radius: 50%;
			border: 2px solid $color-white;
			position: absolute;
			top: 1px;
			left: 1px;
		}
	}
}
