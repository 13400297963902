.newsletter-inline-signup {

	label {
		font: 12px/1 Roboto-Bold,Helvetica,Arial,sans-serif;
		letter-spacing: 1.5px;
		text-transform: capitalize;
		display: inline-block;
		margin: 8px 5px 5px;
		overflow-wrap: break-word;
		word-break: break-word;
		border: 0px;
		padding: 0px;
		color: #767676;
		vertical-align: baseline;
	}

	input {
		color: #252626;
		background-color: #fff;
		border: 1px solid #aab1b3;
		padding-right: 20px;
		padding-left: 20px;
		font-family: Roboto-Regular,Helvetica,Arial,sans-serif;
		font-size: 16px;
		line-height: 1;
		display: block;
		margin: 0;
		padding: 15px;
		width: 100%;
		height: auto;
		box-shadow: none;
	}

	.newsletter-inline-signup-footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 20px;
	}
}