﻿:root {
	--c-three-up-background: var(--page-background, #fff);
	--c-three-up-nav-color: var(--font-color);
	--c-three-up-item-description-color: var(--font-color);
	--c-three-up-item-heading-color: var(--heading-color);
}

// Zipp and Quarq Brand Overrides
body.zipp .three-up, body.quarq .three-up, body.rockshox .three-up {
	h2 {
		font-size: 40px;
		@include screen-lg-max {
			font-size: 34px;
		}
		@include screen-md-max {
			font-size: 28px;
		}
		@include screen-xs-max {
			font-size: 22px;
		}
	}
	
	h3 {
		font-size: 16px;
		font-family: $font-medium;
		@include screen-xs-max {
			font-size: 12px;
		}
	}
}

// Styles for 3-Up Blocks
.three-up {
	position: relative;
	background: var(--c-three-up-background);
	color: var(--c-three-up-nav-color);
	
	h1, h2, h3, h4 {
		color: var(--c-three-up-item-heading-color);
	}
	
	h2 {
		font-size: 40px;
		letter-spacing: 2px;
		line-height: 1.25em;
		text-align: center;
		@include screen-lg-max {
			font-size: 34px;
		}
		@include screen-md-max {
			font-size: 28px;
		}
		@include screen-xs-max {
			font-size: 22px;
			letter-spacing: 1px;
		}
	}

	h3 {
		font-size: 24px;
		letter-spacing: .6px;
		line-height: 1.5em;
		margin-bottom: 5px;
		@include screen-md-max {
			font-size: 20px;
		}
		@include screen-xs-max {
			font-size: 18px;
		}
	}

	// Styles for Product Promo 3-Up
	&--product-promo {
		text-align: center;
		h2 {
			font-size: 24px;
			letter-spacing: .6px;
			margin-bottom: 5px;
			@include screen-md-max {
				font-size: 20px;
			}
			@include screen-xs-max {
				font-size: 18px;
			}
		}
		h3 {
			font-size: 16px;
			font-family: $font-medium;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: 1px;
			text-transform: uppercase;
			margin: 30px 0 10px;
			@include screen-xs-max {
				font-size: 12px;
			}
		}
	}

	// Styles for Product Review 3-Up
	&--review {
		h2 {
			text-align: left;
		}
	}

	// Styles for Story Promo 3-Up 
	&--story-promo {
		h2 {
			margin-bottom: 90px;
			@include screen-md-max {
				margin-bottom: 40px;
			}
		}
		h3 {
			margin-bottom: 20px;
		}
	}

	// Styles for Technology Promo 3-Up
	&--technology {
		text-align: left;

		.three-up-header {
			margin-top: 0;
			margin-bottom: 0;

			@include screen-lg-max {
				border-top: 1px solid $color-dark-gray;
			}
		}

		h3 {
			padding: 36px 0 36px;
		}

		.carousel-arrows {
			padding-bottom: 50px;

			@include screen-lg-max {
				padding-bottom: 20px;
			}
		}
	}
		
	&-header {
		margin: 32px 0 50px 0;
	}

	&-subhead {
		color: $color-red;
		font-family: $font-bold;
		font-size: 12px;
		line-height: 10px;
		letter-spacing: 1.5px;
		margin-bottom: 20px;
	}

	&-logo {
		svg {
			display: block;
			max-width: 184px;
			height: auto;
		}
	}

	&-carousel-container {
		overflow: hidden;
		height: 100%;
	}

	&-item {
		margin-right: 35px;

		@include screen-lg-max {
			margin-bottom: 30px;
			margin-right: 50px;
		}

		@include screen-sm-max {
			margin-right: 0;
		}

		h4 {
			margin-bottom: 5px;
		}

		p {
			color: var(--c-three-up-item-description-color);
		}

		&-image {
			margin-bottom: 15px;
			position: relative;

			img {
				display: block;
				width: 90%;
				height: auto;
				margin: 0 auto;
			}
		}

		&-button {
			margin-top: 20px;
		}

		&--product {
			&-image {
				position: relative;
				padding-top: 100%;

				img {
					position: absolute;
					top: 50%;
					left: 0;
					right: 0;
					margin: 0 auto;
					display: block;
					width: 90%;
					height: 90%;
					transform: translate(0, -50%);
				}

				&--horizontal {
					img {
						min-height: 120px;
						height: auto;
						width: 300px;
					}
				}

				&--vertical {
					img {
						height: 300px;
						min-width: 120px;
						width: auto;
					}
				}
			}
		}

		&--feature {
			

			&-image {
				img {
					width: 100%;
				}
			}
		}

		&--technology {
			p {
				color: $color-xdark-gray;
				padding-right: 13px;
			}

			.three-up-item-image {
				min-height: 60px;
				margin-bottom: 25px;

				img {
					max-height: 45px;
					width: auto;
					margin: 0;
					position: absolute;
					bottom: 0;
				}

				&--video {
					cursor: pointer;

					&::before {
						position: absolute;
						top: 50%;
						left: 50%;
						height: 43px;
						width: 43px;
						z-index: 3;
						background: rgba(0, 0, 0, 0.5);
						border-radius: 100%;
						transform: translate(-50%, -50%);
						color: $color-red;
						content: '';
					}

					&::after {
						position: absolute;
						top: 50%;
						left: 50%;
						z-index: 4;
						font-size: 50px;
						transform: translate(-50%, -50%);
						font-family: $font-icons;
						content: $icon-play;
						color: $color-red;
					}
				}
			}
		}

		&--review {
			.three-up-item-image {
				margin-bottom: 37px;

				img {
					width: auto;
					height: auto;
					max-width: 200px;
					max-height: 48px;
					margin: 0;
				}
			}

			.three-up-item-button {
				margin-top: 40px;
			}
		}
	}
}
