﻿.section-landing {
	&-banner {
		position: relative;
		z-index: 2;
		background-position: center center;
		background-size: cover;
		height: 41.667vw;

		@include screen-md-max {
			height: 146.667vw;
		}

		&-wrapper {
			position: absolute;
			bottom: 100px;
			left: 0;
			right: 0;
			z-index: 2;
			margin: 0 auto;

			@include screen-lg-max {
				bottom: 50px;
			}

			@include screen-md-max {
				position: relative;
				top: 0;
				height: 100%;
				transform: none;

				.container {
					position: absolute;
					top: 50%;
					left: 0;
					right: 0;
					margin: 0 auto;
					transform: translate(0, -50%);
					text-align: center;

					img {
						margin-left: auto;
						margin-right: auto;
					}
				}
			}

			h1, h2, h5, p {
				color: $color-white;
			}

			h1, h2 {
				@extend .cms-page-banner-title;
				margin-bottom: 5px;
			}

			p {
				@extend h5;
			}

			a {
				margin-top: 32px;
			}

			img {
				display: block;
				margin-bottom: 42px;
				max-width: 335px;
				max-height: 175px;
			}
		}
	}

	&-intro {
		padding: 100px 0;
		text-align: center;

		@include screen-sm-max {
			padding: 60px 20px;
		}

		h2, h5 {
			font: 15px/1 $font-condensed-bold;
			text-transform: uppercase;
			color: $color-red;
			letter-spacing: 1.5px;
			margin-bottom: 10px;
		}

		p {
			font: 20px/1.5em $font-medium;
			letter-spacing: 1.25px;

			@include screen-sm-max {
				font-size: 14px;
				letter-spacing: 0.88px;
			}
		}
	}
}
