﻿.popular-resources-container {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
}

.service {
	&-banner {
		position: relative;
		z-index: 1;
		background-position: center center;
		background-size: cover;
		height: 500px;

		.page-banner-content {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			width: 100%;
			margin: 0 auto;
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
			text-align: center;

			.page-banner-title {
				margin-bottom: 10px;
			}
		
			p {
				max-width: 525px;
				margin: 0 auto;
				font-size: 20px;
				line-height: 1.5em;
				letter-spacing: 1.25px;
				font-weight: 600;
				padding: 0 10px;
			}
			h1, h2, h3 {
				padding: 0 10px;
			}
		}

		.container {
			position: absolute;
			top: 10px;
			left: 0;
			right: 0;
			width: 100%;
			margin: 0 auto;
		}

		&-notice {
			background-color: $color-white;
			border: solid 1px $color-red;
			border-radius: 1px;
			text-align: left;
			font: 15px/30px $font-mono;
			padding: 14px 45px 13px;
			position: relative;
			z-index: 100;

			&::before {
				content: $icon-alert;
				font-family: $font-icons;
				font-size: 20px;
				color: $color-red;
				position: absolute;
				top: 13px;
				left: 13px;
			}

			&-text {
				color: $color-xdark-gray;
				border-bottom: 1px solid $color-xdark-gray;
				padding-bottom: 3px;
				transition: color $default-transition, border-color $default-transition;

				&:hover {
					color: $color-black;
					border-color: $color-black;
				}
			}

			&-close {
				position: absolute;
				height: 30px;
				width: 30px;
				top: 8px;
				right: 8px;
				z-index: 99;
				cursor: pointer;
				color: $color-black;
				text-align: center;

				&::after {
					content: $icon-close;
					font-family: $font-icons;
					font-size: 14px;
					display: block;
					margin-top: 6px;
				}
			}
		}
	}

	&-search {
		position: absolute;
		width: 90vw;
		max-width: 980px;
		bottom: -56px;
		left: 50%;
		transform: translate(-50%, -50%);
		input {
			&::placeholder{
				color: $color-dark-gray;
				font-size: 14px;
			}
			border: 1px solid $color-black;
			padding: 17px;
			&:focus {
				border-bottom: 1px solid $color-black;
				padding-bottom: 17px;			
			}
		}

		.header-search-submit {
			left: auto;
			right: 1px;
			top: 1px;
			width: 53px;
			height: 53px;
			border: transparent;
			background-color: $color-red;
			cursor: pointer;
			&::before {
				color: $color-white;
			}
		}
	}

	&-resources {
		margin-top: 120px;

		&-heading {
			display: flex;
			margin-bottom: 40px;
		}

		&-col {
			margin-bottom: 40px;

			&-heading {
				border-bottom: solid 1px $color-xdark-gray;
				margin-bottom: 25px;
				padding-bottom: 4px;

				img {
					height: 20px;
				}
			}

			div:last-of-type {
				margin-bottom: 0;
			}

			&-viewall {
				font-size: 12px;
				font-family: $font-bold;
				color: $color-black;
				text-transform: uppercase;
				letter-spacing: 1.5px;
			}
		}
	}

	&-stories {
		background-color: $color-xlight-gray;
		margin-top: 120px;
		padding: 80px 0 130px;

		@include screen-md-max {
			margin-top: 25px;
			margin-bottom: 50px;
			padding: 27px 0 40px;
		}
	}
}

.wcag-contrast .service-banner::before {
	content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $color-black;
    opacity: .8;
}

@media (min-width: 640px){
	.popular-resources-container {
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 55px;

	}
	
}

@media(min-width: 1024px){
	.popular-resources-container{
		grid-template-columns: repeat(4, 1fr);
	}
}

