﻿:root{
	--c-feature-promo-single-background: var(--page-background);
	--c-feature-promo-single-color: var(--font-color);
	--c-feature-promo-single-heading-color: var(--heading-color);
}
.feature-promo-single {
	position: relative;
	background: var(--c-feature-promo-single-background, #fff);
	color: var(--c-feature-promo-single-color);

	h1, h2, h3, h4, h5 {
		color: var(--c-feature-promo-single-heading-color);
	}

	video {
		width: 100%;
	}

	&-asset {
		@include span-columns(6);
		@include shift(1);

		@include screen-lg-max {
			@include span-columns(7);
			@include shift(0);
		}
		@include screen-md-max {
			@include span-columns(12);
			margin-bottom: 12px;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	&-content {
		@include span-columns(4);

		@include screen-lg-max {
			@include span-columns(5);
		}
		@include screen-md-max {
			@include span-columns(12);
		}

		h2 {
			margin-bottom: 30px;
			@include screen-md-max {
				margin-bottom: 12px;
			}
		}
	}

	&-button {
		margin-top: 20px;
	}

	&--left {
		@include direction-context(right-to-left) {
			.feature-promo-single-asset {
				@include span-columns(6);
				@include shift(1);
				@include screen-lg-max {
					@include span-columns(7);
					@include shift(0);
				}
				@include screen-md-max {
					@include span-columns(12);
				}
			}

			.feature-promo-single-content {
				@include span-columns(4);
				@include screen-lg-max {
					@include span-columns(5);
				}
				@include screen-md-max {
					@include span-columns(12);
				}
			}
		}
	}
}
