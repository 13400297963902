*, *::before, *::after {
	box-sizing: border-box;
}

body {
	background: var(--page-background, #fff);
}

body.menu-open {
	overflow: hidden;
}

.content {
	@include screen-md-max {
		padding-bottom: 80px;
	}
}

.container {
	position: relative;
	@include outer-container($page-width);

	@include screen-xl-max {
		max-width: none;
		padding-left: $page-gutter;
		padding-right: $page-gutter;
	}

	@include screen-lg-max {
		padding-left: $page-gutter-sm;
		padding-right: $page-gutter-sm;
	}

	@for $i from 1 through $grid-columns {
		.col-#{$i} {
			@include span-columns($i, no-display);
		}

		.shift-#{$i} {
			@include shift($i);
		}
	}

	.col-2_4 {
		@include span-columns(2.4, no-display);
	}

	@include screen-xl-max {
		@for $i from 1 through $grid-columns {
			.col-xl-#{$i} {
				@include span-columns($i, no-display);
			}

			.shift-xl-#{$i} {
				@include shift($i);
			}
		}

		.hide-shift-xl {
			@include shift(0);
		}
	}

	@include screen-lg-max {
		@for $i from 1 through $grid-columns {
			.col-lg-#{$i} {
				@include span-columns($i, no-display);
			}

			.shift-lg-#{$i} {
				@include shift($i);
			}
		}

		.hide-shift-lg {
			@include shift(0);
		}
	}

	@include screen-md-max {
		@for $i from 1 through $grid-columns {
			.col-md-#{$i} {
				@include span-columns($i, no-display);
			}

			.shift-md-#{$i} {
				@include shift($i);
			}
		}

		.hide-shift-md {
			@include shift(0);
		}
	}

	@include screen-sm-max {
		@for $i from 1 through $grid-columns {
			.col-sm-#{$i} {
				@include span-columns($i, no-display);
			}

			.shift-sm-#{$i} {
				@include shift($i);
			}
		}

		.hide-shift-sm {
			@include shift(0);
		}
	}

	@include screen-xs-max {
		@for $i from 1 through $grid-columns {
			.col-xs-#{$i} {
				@include span-columns($i, no-display);
			}

			.shift-xs-#{$i} {
				@include shift($i);
			}
		}

		.hide-shift-xs {
			@include shift(0);
		}
	}
}

.flex {
	display: flex;
}

.align-left {
	text-align: left;
}

.align-right {
	text-align: right;
}

.align-center {
	text-align: center;
}

.full-padding-top {
	padding-top: 120px;

	@include screen-lg-max {
		padding-top: 100px;
	}

	@include screen-md-max {
		padding-top: 60px;
	}

	@include screen-xs-max {
		padding-top: 40px;
	}
}

.full-padding-bottom {
	padding-bottom: 120px;

	@include screen-lg-max {
		padding-bottom: 100px;
	}

	@include screen-md-max {
		padding-bottom: 60px;
	}

	@include screen-xs-max {
		padding-bottom: 40px;
	}
}

.half-padding-top {
	padding-top: 60px;

	@include screen-lg-max {
		padding-top: 50px;
	}

	@include screen-md-max {
		padding-top: 30px;
	}

	@include screen-xs-max {
		padding-top: 20px;
	}
}

.half-padding-bottom {
	padding-bottom: 60px;

	@include screen-lg-max {
		padding-bottom: 50px;
	}

	@include screen-md-max {
		padding-bottom: 30px;
	}

	@include screen-xs-max {
		padding-bottom: 20px;
	}
}

.full-margin-top {
	margin-top: 200px;

	@include screen-xs-max {
		margin-top: 140px;
	}
}

	//----- hold render element if is not viewport first load
.lazy-loading {
	content-visibility: auto;
	contain-intrinsic-size: 40px;
}

.wcag-contrast {
	//----- contrast to banners

	.banner-contrast {
		background-color: $color-black;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: $color-black;
			opacity: 0.8;
		}
	}

	.banner-contrast-video {

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: $color-black;
			opacity: 0.8;
			z-index: 2;
		}
	}
	//----- contrast to text

	.story-promo-2up-item p,
	.header-topbar-utility a,
	.header-megamenu-section-subtitle,
	.product-listing-item-price,
	.product-listing-item-sku,
	.story-promo-single-story-description,
	.product-highlight-carousel-item-content-feature p,
	.breadcrumbs li {
		color: $color-black !important;
	}

	@media (min-width: 1025px) {
		.header-topbar--dark .header-topbar-utility > li > a {
			color: #ffffff !important;
		}
	}

	.product-highlight-carousel-item-content-feature .section-landing-banner-wrapper p,
	.product-highlight-carousel-item-content-feature h5,
	.section-landing-banner-wrapper .product-highlight-carousel-item-content-feature p {
		color: $color-black !important;
	}
	//----- contrast to product highlight carousel nav

	&.rockshox {
		.product-highlight-carousel-nav-link--active h2,
		.product-highlight-carousel-nav-link:hover h2 {
			color: $color-dark-red;
			background-color: $color-white;
		}

		.product-highlight-carousel-nav-link h2 {
			background-color: $color-white;
		}
	}

	.product-highlight-carousel-nav-link {
		background-color: $color-white;

		&--active {
			background-color: transparent;
		}
	}
	//----- contrast to promo 2 up

	.product-promo-2-up-item .product-promo-2-up-item-text {
		background-color: rgba(0,0,0,0.8) !important;
		padding: 15px;

		h3 {
			width: 100%
		}
	}
	//----- contrast to job posting page sidebar

	.job-detail-promo {
		img {
			filter: brightness(0.2) !important;
		}

		a:after {
			font-size: 60px;
		}
	}
	//----- contrast to social grid

	.social-grid-item-text {
		background-color: $color-black;
	}
	//----- contrast to buttons

	.button {
		background-color: $color-dark-red;
		border: 1px solid $color-dark-red;
		color: $color-white;
	}
	//----- contrast to footer

	footer a,
	.footer,
	.footer-bottombar {
		color: $color-white !important;
		background-color: $color-black !important;
	}

	.parallax-product-promo-buttons-button {
		color: $color-white !important;
		background-color: $color-black !important;
	}
}
