﻿.corporate-resources {
	background-color: $color-xlight-gray;
	padding: 86px 0 120px 0;

	@include screen-md-max {
		padding: 36px 0 0 0;
		margin-bottom: -80px;
	}

	h2 {
		text-align: center;
		margin-bottom: 75px;
		@include screen-md-max {
			margin-bottom: 43px;
		}
	}

	a {
		display: block;
		padding: 0 20px;
		transition: background-color $default-transition;
		position: relative;
		z-index: 1;

		@include screen-md-max {
			background-color: $color-white;
			border-bottom: 1px solid $color-light-gray;
			margin: 0 -20px;
		}

		h4 {
			border-bottom: 1px solid $color-gray;
			padding: 30px 0 29px;
			position: relative;

			@include screen-md-max {
				border: none;
			}

			&::after {
				content: $icon-carat;
				font-family: $font-icons;
				font-size: 20px;
				position: absolute;
				right: 0;
				top: 29px;
			}
		}

		&:hover {
			background-color: $color-white;
			box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.08);
			padding-top: 1px;
			z-index: 2;
			top: -1px;

			h4 {
				border: 0;
			}
		}
	}
}
