﻿.life-listing {
	.cms-page-banner {
		.container {
			text-align: left;

			h5 {
				margin: 0;
			}
		}
	}

	&-title {
		text-align: center;
		margin-bottom: -10px;
		padding-top: 80px;

		@include screen-md-max {
			padding-top: 50px;
			margin-bottom: -30px;
		}
	}

	&-list {
		display: flex;
		flex-wrap: wrap;
		padding-top: 80px;
	}

	&-item {
		background-color: $color-xlight-gray;
		margin-bottom: 2.6086956522%;

		@include screen-md-max {
			margin-right: 0 !important;
			margin-bottom: 20px;
		}

		&--2, &--4, &--7, &--9 {
			margin-right: 0 !important;
		}

		img {
			width: 100%;
			height: auto;
			display: block;
		}

		h4 {
			padding: 0;
			display: block;
			margin: 0 15px;
			line-height: 1em;
			transform: translate(0, -50%);

			@include screen-md-max {
				margin: 0 10px;
			}

			span {
				background-color: $color-white;
				color: $color-black;
				box-shadow: 15px 0 0 $color-white, -15px 0 0 $color-white;

				@include screen-md-max {
					box-shadow: 10px 0 0 $color-white, -10px 0 0 $color-white;
				}
			}
		}

		p {
			padding: 0 15px 30px;
			color: $color-xdark-gray;
		}

		&--athlete {
			background-color: transparent;

			@include screen-md-max {
				&:nth-child(2n) {
					margin-left: 2.6086956522%;
				}
			}

			&:nth-child(4n) {
				margin-right: 0;
			}

			h4 {
				span {
					background-color: $color-red;
					color: $color-white;
					box-shadow: 15px 0 0 $color-red, -15px 0 0 $color-red;

					@include screen-md-max {
						box-shadow: 10px 0 0 $color-red, -10px 0 0 $color-red;
					}
				}
			}
		}
	}

	&-loadmore {
		padding-top: 44px;
		text-align: center;
	}
}
