﻿@font-face {
    font-family: 'Forza';
    font-display: swap;
    src: url('/common/fonts/Forza-Bold.woff2') format('woff2'), url('/common/fonts/Forza-Bold.woff') format('woff');
}

$font-zipp-bold: "Forza", "Helvetica", "Arial", sans-serif;

body.zipp {

    h1, h2, h3, h4 {
        font-family: $font-zipp-bold;
    }

    h2, h1.cms-page-banner-title {
        font-size: 55px;

        @include screen-lg-max {
            font-size: 48px;
        }

        @include screen-md-max {
            font-size: 36px;
        }

        @include screen-xs-max {
            font-size: 30px;
        }
    }

    h3, h3.product-title, h1.product-title {
        font-size: 40px;

        @include screen-lg-max {
            font-size: 34px;
        }

        @include screen-md-max {
            font-size: 28px;
        }

        @include screen-xs-max {
            font-size: 22px;
        }
    }

    h2.page-navigation-title {
        font-size: 22px;
    }
}
