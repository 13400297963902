:root {
	--seatpost-font-color: var(--color-white);
}

.seatpost-tool {
    padding: 50px 0;
    .container {
        background-color: var(--color-black);
        color: var(--seatpost-font-color);
        font-family: $font-owners-regular;
        .seatpost-section {
            padding: 45px 0 0 0;
            h2, h3 {
                font-family: $font-owners-narrow;
                color: var(--seatpost-font-color); 
                text-transform: uppercase; 
            }
            h4{
                color: var(--seatpost-font-color);
                padding-bottom: 15px;
            }
            p, label, select, input {
                letter-spacing: 0.96px;
            }

            p {
                margin-top: 20px;
            }

            label {
                text-transform: uppercase;
                color: $accent-color;
                display: inline-block;
                margin-bottom: -3px;
            }

            select, input {
                font-size: 16px;
                color: var(--seatpost-font-color);
                width: 60%;
                height: 48px;
                padding: 0 0 0 10px;
                background-color: var(--color-black);
                border: 2px solid var(--seatpost-font-color);
                @include screen-lg-max {
                    width: 75%;
                }
                @include screen-sm-max {
                    width: 100%;
                }
            }

            .seatpost-metrics {
                margin-top: 26px;

                .letter-circle {
                    display: inline-block;
                    position: relative;
                    margin-right: 4px;
                    width: 25px; 
                    height: 25px; 
                    font-family: $font-owners-bold;
                    color: $accent-color;
                    font-size: 16px; 
                    text-align: center;
                    line-height: 0;
                }

                .letter-circle:before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    border: 3px solid $accent-color;
                    border-radius: 50%;
                    top: -13px;
                    left: -1px;
                }
            }

            .seatpost-footnotes {
                font-size: 12px;
                letter-spacing: 1.2px;
                line-height: 20px;
            }
        }
        button {
            margin-top: 25px;
        }
    
        .seatpost-recommendation {
            padding-top: 100px;
            @include screen-md-max {
                padding-top: 75px;
            }
            @include screen-xs-max {
                padding-top: 60px;
            }
            
            .seatpost-result {
                padding: 20px 0;
            }
            .seatpost-length-diameter {
                font-size: 50px; 
                color: $accent-color;
                letter-spacing: 4px;
                @include screen-lg-max {
                    font-size: 40px;
                }
                @include screen-md-max {
                    font-size: 35px;
                }
                @include screen-sm-max {
                    font-size: 30px;
                }
                @include screen-xs-max {
                    font-size: 25px;
                }
            }
            .seatpost-part-recommendation {
                font-family: $font-owners-bold;
                text-transform: uppercase;
            }
            button {
                margin-top: 10px;
            }
        }

        .seatpost-video {
            padding: 100px 0 75px 0;
            @include screen-md-max {
                padding: 75px 0 50px 0;
            }
            @include screen-xs-max {
                padding: 60px 0 50px 0;
            }

            .iframe-container {
                padding-top: 56.25%;
    position: relative;
            }

            iframe {
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
            a {
                color: var(--color-red);
            }
        }

        .seatpost-image{
            img {
					width: 100%;
				}
        }
    }
}