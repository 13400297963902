﻿:root {
	--video-promo-2-background: var(--page-background, #fff);
}

.video-2up {
	background: var(--video-promo-2-background);
	h3 {
		margin-bottom: 53px;
		@include span-columns(6);
		@include shift(1);

		@include screen-lg-max {
			@include span-columns(7);
			@include shift(0);
		}

		@include screen-md-max {
			@include span-columns(12);
			margin-bottom: 12px;
		}

		@include screen-md-max {
			margin-bottom: 30px;
		}
	}

	&-wrapper {
		display: flex;
		flex-wrap: wrap;
	}

	&-item {
		width: calc(50% - 18px);
		margin-right: 36px;
		margin-bottom: 30px;

		@include screen-md-max {
			float: none;
			width: 100%;
			margin: 0 0 30px 0;
		}

		&:nth-child(2n) {
			margin-right: 0;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
			margin-bottom: 5px;
		}

		a {
			display: block;
			position: relative;

			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				height: 26px;
				width: 26px;
				z-index: 3;
				background: rgba(0, 0, 0, 0.5);
				border-radius: 100%;
				color: $color-red;
				transform: translate(-50%, -50%);
			}

			&::after {
				position: absolute;
				top: 50%;
				left: 50%;
				z-index: 4;
				font-size: 30px;
				font-family: $font-icons;
				content: $icon-play;
				color: $color-red;
				transform: translate(-50%, -50%);
			}
		}
	}
}
