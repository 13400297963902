﻿.contact-us {
	&-section {
		// padding-top: 75px;
		// padding-bottom: 75px;
		// margin-bottom: 20px;
		// border-bottom: 1px solid $color-gray;
		@include screen-lg-max {
			// padding-top: 42px;
			// padding-bottom: 42px;
			&-column {
				// margin-bottom: 35px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		&:last-child {
			border-bottom: 0;
			padding-bottom: 0;
			margin-bottom: 0;
		}

		h2 {
			text-align: center;
			margin-bottom: 85px;

			@include screen-lg-max {
				margin-bottom: 45px;
			}
		}

		h4 {
			margin-bottom: 11px;
		}

		h5 {
			font: 12px/1 $font-bold;
			color: $color-red;
			text-transform: uppercase;
			margin-bottom: 35px;
		}

		h6 {
			font: 12px/1 $font-bold;
			color: $color-dark-gray;
			text-transform: uppercase;
			margin-bottom: 9px;
		}

		p, address {
			font-size: 14px;
			margin-bottom: 1.1em;

			a {
				color: $color-red;
			}
		}

		strong, b {
			font-family: $font-condensed-bold;
			text-transform: uppercase;
		}

		.rich-text h4 + p {
			margin-top: -16px;
		}

		&-body,
		&-body-footer {
			a {
				color: $color-red;
			}
		}
	}
}
