﻿.product-registration {
	position: relative;
	z-index: 5;

	&-wrapper {
		position: relative;
	}

	.container {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 130px;
		z-index: 2;

		@include screen-lg-max {
			position: static;
			background: $color-xxlight-gray;
			margin-bottom: 0;
		}

		@include screen-sm-max {
			padding: 0;
			background: $color-white;
		}
	}

	&-header {
		background: $color-black;
		color: $color-white;
		padding: 35px 80px;
		position: absolute;
		top: 0;
		z-index: 3;
		order: 1;

		@include screen-xl-max {
			padding: 22px 40px;
		}
	}

	&-story {
		background: $color-white;
		position: absolute;
		padding: 60px;
		z-index: 2;
		bottom: -40px;
		right: 0;
		width: 48.6956521739%;

		@include ie11 {
			right: -270px;
		}

		@include screen-xl-max {
			padding: 48px;
		}

		@include screen-lg-to-xl-max {
			right: 10%;
			width: 46%;
		}

		@include screen-lg-max {
			position: relative;
			bottom: auto;
			right: auto;
			left: auto;
			top: -109px;
			width: 100%;
		}

		@include screen-md-max {
			top: -40px;
		}

		@include screen-sm-max {
			padding: 0 20px 20px 20px;
		}

		&-subhead {
			color: $color-red;
			font-size: 12px;
			font-family: $font-bold;
			margin-bottom: 20px;
			letter-spacing: 1.5px;

			@include screen-lg-max {
				margin-bottom: 10px;
			}
		}

		h3 {
			font-size: 36px;
			color: $color-xxdark-gray;
			margin-bottom: 24px;

			@include screen-md-max {
				font-size: 28px;
			}

			@include screen-sm-max {
				padding-top: 20px;
				margin-bottom: 20px;
				letter-spacing: 1px;
			}

			@include screen-xs-max {
				font-size: 22px;
				letter-spacing: 1px;
			}
		}

		&-description {
			color: $color-xxdark-gray;
			line-height: 30px;
			margin-bottom: 24px;

			@include screen-lg-max {
				margin-bottom: 24px;
			}

			@include screen-md-max {
				margin-bottom: 20px;
			}

			@include screen-xs-max {
				margin-bottom: 16px;
			}
		}
	}

	&-form {
		.serial-number-input-container {
			position: relative;
		}

		label {
			font-size: 14px;
			color: $color-xxdark-gray;

			&.error {
				color: $color-red;
			}
		}

		input {
			font-size: 14px;
			width: 347px;
			color: $color-xxdark-gray;
			border: 1px solid $color-xdark-gray;
			padding: 16px;
			margin-bottom: 3px;
			display: block;

			@include screen-sm-max {
				width: 100%;
			}

			&:focus {
				padding-bottom: 16px;
				border-bottom: 1px solid $color-xdark-gray;
			}

			&.error {
				border: 1px solid $color-red;
			}
		}

		::placeholder {
			color: $color-dark-gray;
			opacity: 1; /* Firefox */
		}

		::-ms-input-placeholder { /* Microsoft Edge */
			color: $color-dark-gray;
		}

		.start-scanner-button {
			position: absolute;
			top: 16px;
			left: 301px;
			border: none;
			height: 30px;
			width: 30px;
			background-color: transparent;

			@include screen-md-max {
				right: 16px;
			}
			@include screen-sm-max{
				left: calc(100% - 50px);
			}
		}

		#SerialNumberError {
			color: $color-red;
			font-size: 14px;
			padding-left: 6px;

			&::before {
				content: url(/common/img/error-icon.svg);
				display: inline-block;
				position: relative;
				width: 20px;
				height: 20px;
				top: 5px;
				left: -6px;
			}
		}

		#registration-error-blurb {
			font-size: 14px;
			line-height: 24px;
			padding-top: 8px;
		}
	}

	a, button {
		padding: 0;
		width: 347px;
		margin-top: 24px;
		cursor: pointer;

		@include screen-sm-max {
			margin-top: 20px;
			width: 100%;
		}
	}

	&-support {
		&-text {
			line-height: 1;
			padding-top: 2px;

			a {
				font-size: 14px;
				color: $color-xxdark-gray;
				font-weight: 700;
				text-decoration: underline;
			}
		}
	}

	&-background {
		position: relative;
		z-index: 1;
		padding-bottom: 130px;
		background: $color-xxlight-gray;

		@include screen-lg-max {
			padding-bottom: 65px;
		}

		@include screen-md-max {
			padding-bottom: 0;
		}

		@include screen-sm-max {
			padding-bottom: 40px;
		}

		&-inner {
			position: relative;
			z-index: 1;
			width: 75%;
			padding-top: 49.725%;
			order: 2;
			background-position: center center;
			background-size: cover;

			@include screen-lg-to-xl-max {
				width: 73%;
			}

			@include screen-lg-max {
				width: 100%;
			}
		}
	}

	&--left {
		.product-registration-header {
			right: 0;
		}

		.product-registration-story {
			right: auto;
			left: 0;

			@include screen-lg-to-xl-max {
				left: 10%;
			}
		}

		.product-registration-background {
			.product-registration-background-inner {
				margin-left: 25%;

				@include screen-lg-max {
					margin-left: 0;
				}
			}
		}
	}
}
