﻿.product-group {
	&-banner {
		position: relative;
		z-index: 2;
		background-position: center center;
		background-size: cover;
		background-color: var(--color-black);
		height: 41.4vw;

		@include screen-md-max {
			height: 100vw;
		}

		video {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			display: block;
			width: 100%;
			height: auto;

			@include screen-md-max {
				display: none;
			}
		}

		&-wrapper {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			z-index: 2;
			margin: 0 auto;
			transform: translate(0, -50%);

			@include screen-md-max {
				position: relative;
				top: 0;
				height: 100%;
				transform: none;

				.container {
					height: 100%;
				}
			}
		}

		&-inner {
			display: flex;
			justify-content: space-between;
			align-items: center;

			@include screen-md-max {
				display: block;
				position: relative;
				height: 100%;
			}

			&-content {
				display: block;
				width: 100%;

				@include screen-md-max {
					position: absolute;
					top: 50%;
					left: 50%;
					z-index: 2;
					transform: translate(-50%, -50%);
				}

				h3 {
					color: $color-white;
				}

				img {
					display: block;
					max-width: 335px;
					max-height: 175px;

					@include screen-xs-max {
						max-width: 165px;
						max-height: 85px;
					}
				}
				
				.hero-cta {
					&-container {
						display: flex;
						flex-wrap: wrap;
					}
					&-item { 
						margin-top: 12px;
						
						&:nth-of-type(2) {
							margin-left: 20px;
							@include screen-sm-max {
								flex: 1 0 100%;
								margin-left: 0;
							}
					  	}
					}
				}
			}

			&-button {
				white-space: nowrap;
			}
		}

		&-breadcrumbs {
			position: absolute;
			bottom: 11px;
			left: 0;
			right: 0;
			z-index: 2;
			margin: 0 auto;

			.breadcrumbs {
				padding: 0;

				a {
					color: $color-white;
				}
			}

			@include screen-lg-max {
				display: none;
			}
		}
	}

	&-overview {
		position: relative;
		z-index: 1;

		.breadcrumbs {
			display: none;

			@include screen-md-max {
				display: none;
				margin: -40px 0 30px 21px;
				padding: 0;
			}
		}

		&-content {
			padding-top: 70px;

			@include screen-lg-max {
				padding-top: 40px;
			}

			h5 {
				margin-bottom: 6px;
			}

			h2 {
				@extend h5;
				margin-bottom: 6px;
			}

			p {
				margin-bottom: 40px;

				@include screen-md-max {
					margin-bottom: 20px;
				}
			}
		}

		&-secondary {
			position: absolute;
			top: 0;
			right: 0;
			width: 46vw;
			display: flex;

			@include screen-md-max {
				position: static;
				width: 100vw;
				// min-height: calc(18.116vw + 100px);
				// background-color: $color-xxdark-gray;
				justify-content: flex-end;
				// padding-bottom: 100px;
				div {
					width: 25vw;
				}
			}

			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
	}
}

.zipp {
	.product-group-overview-content {
		h2 {
			@extend h5;
			margin-bottom: 6px;
		}
	}
}
