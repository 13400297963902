﻿.home-page {

    @include screen-md-max {
        margin-bottom: -80px;
    }

    & > div:last-child {
        padding-bottom: var(--gutter);

        @include screen-md-max {
            padding-bottom: 80px;
        }
    }

    .hero-carousel {
        padding-bottom: 0 !important;
    }
    
    /*this is a temporary fix and should be deleted when we create a real component*/
    @media (min-width:$screen-lg) {
        .life-listing-list {
            width: 84%;
        }
    }

    @media (min-width: $screen-xl) {
        .life-listing-list {
            padding-left: 118px;
            padding-right: 118px;
            width: 100%;
        }
    }
    .life-listing-list {
        padding-top: 0;
    }
    /*End Temp Fix*/
}
