﻿.pull-quote {
	blockquote {
		position: relative;
		padding-left: 42px;
		color: $color-xdark-gray;
		@include screen-md-max {
			padding-left: 27px;
		}

		.blockquote-quote {
			font: 40px/1.25em $font-condensed-bold;
			letter-spacing: 2px;

			@include screen-lg-max {
				font-size: 34px;
			}

			@include screen-md-max {
				font-size: 28px;
			}

			@include screen-xs-max {
				font-size: 22px;
				letter-spacing: 1px;
			}

			&::before, &::after {
				content: $icon-quotes;
				font: 23px/1 $font-icons;
				@include screen-md-max {
					font-size: 16px;
				}
			}

			&::before {
				position: absolute;
				top: 9px;
				left: 0;

				@include screen-lg-max {
					top: 7px;
				}

				@include screen-md-max {
					top: 6px;
				}

				@include screen-xs-max {
					top: 4px;
				}
			}

			&::after {
				display: inline-block;
				position: relative;
				transform: rotate(180deg);
				top: -7px;
				margin-left: 7px;

				@include screen-lg-max {
					top: -2px;
				}

				@include screen-md-max {
					top: -5px;
				}

				@include screen-xs-max {
					top: -1px;
				}
			}
		}

		.blockquote-credit {
			color: $color-black;
			font: 16px/1.25em $font-medium;
			letter-spacing: 1px;
			margin-top: 10px;

			@include screen-xs-max {
				font-size: 12px;
			}
		}
	}
}
