﻿.listing--service {
	.listing-filters {
		margin-top: 5px;
		@include screen-lg-max {
			margin-top: 0;
		}
	}

	.service-listing {
		&-hero, &-submenu {
			h1 {
				font-size: 24px;
				letter-spacing: 0.6px;
			}

			img {
				display: block;
				height: 54px;
				width: auto;
			}
		}

		&-hero {
			display: flex;
			align-items: center;
			margin-top: -12px;
			margin-bottom: 20px;
			padding-left: 266px;
			padding-right: 118px;

			@include screen-lg-max {
				padding: 0 20px;
				justify-content: center;
				margin-top: 20px;
				margin-bottom: 40px;
			}

			img {
				margin-right: 18px;
			}
		}

		&-submenu {
			margin-top: -24px;
			margin-bottom: 67px;

			h1 {
				text-align: center;
				margin-bottom: 53px;
			}

			img {
				margin: 0 auto 10px auto;
			}

			ul {
				display: flex;
				justify-content: center;

				li {
					font: 12px/1.25em $font-mono;
					margin-right: 80px;
					position: relative;
					text-align: center;
					cursor: pointer;

					@include screen-md-max {
						margin-right: 40px;

						&:last-child {
							margin-right: 0;
						}
					}

					&:last-child {
						margin-right: 0;
					}

					a {
						color: $color-red;
					}
				}
			}
		}

		&-toolbar {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 16px;

			&-recent, &-refine {
				@include screen-lg-max {
					width: 50%;
				}
			}

			&-recent {
				@include screen-lg-max {
					padding: 14px 0 0 10px;
					// border: 1px solid $color-light-gray;
					border-left: 0;
					height: 44px;
				}

				.custom-checkbox {
					span {
						font-size: 12px;

						&::before {
							top: -1px;
						}
					}
				}
			}

			&-refine {
				@include screen-lg-max {
					//padding-right: 10px;
				}
			}

			#btn-media-refine {
				display: none;

				@include screen-lg-max {
					display: block;
					width: 100%;
					text-align: center;
					border-color: $color-light-gray;
				}
			}
		}

		&-table {
			margin-top: 10px;
			width: 100%;

			tr {
				cursor: pointer;

				@include screen-lg-max {
					display: flex;
					flex-direction: column;
					border-bottom: solid 1px $color-xdark-gray;
				}
			}

			th, td {
				// padding-right: 36px;
				border-bottom: solid 1px $color-xdark-gray;
				text-align: left;
			}

			th {
				font-size: 12px;
				color: $color-black;
				padding-bottom: 1px;

				@include screen-lg-max {
					display: none;
				}
			}

			td {
				padding-bottom: 35px;
				vertical-align: top;

				@include screen-lg-max {
					display: block;
					border-bottom: 0;
					padding: 0;
				}
			}

			&-button {
				min-width: 118px;
				padding-top: 39px;

				@include screen-lg-max {
					order: 2;
					display: flex !important;
					justify-content: space-between;
					padding-bottom: 10px !important;
					align-items: center;
				}

				span {
					font: 12px/16px $font-mono;
					color: $color-gray;
				}
			}

			&-title {
				width: 100%;
				padding-top: 30px;

				@include screen-lg-max {
					order: 1;
					padding: 28px 0 15px !important;
				}

				a {
					color: $color-xdark-gray;
					font-size: 14px;
					line-height: 18px;
					letter-spacing: 0.5px;

					&::after {
						content: $icon-medium-arrow;
						font-family: $font-icons;
						color: $color-red;
						font-size: 10px;
						margin-left: 12px;
						position: relative;
						top: -1px;
					}
				}
			}

			&-lang, &-brand {
				p {
					padding-top: 36px;
					font-size: 12px;
					line-height: 18px;

					@include screen-lg-max {
						border-top: solid 1px $color-light-gray;
						padding: 10px 0 !important;
						color: $color-dark-gray;
					}
				}
			}

			&-lang {
				min-width: 236px;

				@include screen-lg-max {
					order: 3;
				}
			}

			&-brand {
				min-width: 200px;
				padding-right: 0;

				@include screen-lg-max {
					order: 4;
				}
			}
		}
	}
}
