﻿#pr-reviewdisplay {
    margin-top: 20px;
}

#pr-write {
    margin: 20px;
}

.p-w-r {
    .pr-review-snapshot-header-intro {
        display: none;
    }

    .pr-snippet-stars {
        background-color: transparent !important;
    }

    .pr-star-v4 {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSIjYWFiMWIzIiBzdHJva2U9IiNhYWIxYjMiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItc3RhciI+ICAgIDxwb2x5Z29uIHBvaW50cz0iMTIgMiAxNS4wOSA4LjI2IDIyIDkuMjcgMTcgMTQuMTQgMTguMTggMjEuMDIgMTIgMTcuNzcgNS44MiAyMS4wMiA3IDE0LjE0IDIgOS4yNyA4LjkxIDguMjYgMTIgMiI+PC9wb2x5Z29uPiAgPC9zdmc+) !important;
    }

    .pr-star-v4-100-filled {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSIjZTUxOTM3IiBzdHJva2U9IiNlNTE5MzciIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItc3RhciI+PHBvbHlnb24gcG9pbnRzPSIxMiAyIDE1LjA5IDguMjYgMjIgOS4yNyAxNyAxNC4xNCAxOC4xOCAyMS4wMiAxMiAxNy43NyA1LjgyIDIxLjAyIDcgMTQuMTQgMiA5LjI3IDguOTEgOC4yNiAxMiAyIj48L3BvbHlnb24+PC9zdmc+) !important;
    }

    .pr-star-v4-50-filled {
    }

    .pr-snippet-rating-decimal {
        display: none !important;
    }

    .pr-snippet-review-count {
        color: $color-gray;
        font-size: 16px;
        font-weight: bold;

        &:hover {
            color: $color-red;
            text-decoration: none;
        }
    }

    .pr-snippet-stars-reco-reco {
        display: none !important;
    }

    .pr-subheadline {
        display: none !important;
    }

    .pr-reco {
        span {
            color: $color-green !important;
        }
    }

    .pr-review-snapshot .pr-review-snapshot-simple .pr-reco-to-friend-message {
        display: none !important;
    }

    .pr-snippet-reco-to-friend-green {
        background-color: transparent !important;

        circle {
            fill: transparent;
            stroke: $color-green;
        }
    }

    .pr-ratings-histogram-barValue {
        background-color: $color-red !important;
    }

    .pr-helpful-btn {
        border-color: $color-light-gray !important;
        border-width: 1px !important;

        span {
            color: $color-gray !important;
        }

        &:hover {
            span {
                color: $color-red !important;
            }

            .pr-thumbs-fill,
            .pr-thumbs-cuff-fill {
                fill: $color-red !important;
            }
        }
    }

    .pr-thumbs-icon {
        color: $color-light-gray !important;
    }

    .pr-accessible-btn,
    .pr-btn-review,
    .pr-snippet-write-review-link {
        background-color: $color-white !important;
        border: 1px solid $color-red !important;
        border-radius: 0 !important;
        color: $color-red !important;
    }

    .pr-snippet-read-and-write {
        .pr-snippet-write-review-link {
            display: none;
        }
    }

    .pr-rd-no-reviews {
        .pr-snippet-write-review-link {
            @extend .button;
        }
    }

    a,
    .pr-review-display a,
    .pr-waq-legal-text a {
        color: $color-gray;

        &:hover {
            color: $color-red;
            text-decoration: underline;
        }
    }

    .pr-btn {
        border-bottom-width: 1px !important;

        &.active {
            background-color: $color-red !important;
        }
    }

    .pr-qa-display { 
        .pr-qa-display-text, 
        .pr-qa-display-text {
            color: $color-red !important;
        }
    }


    .pr-qa-display {
        .pr-cross-icon,
        .pr-badging-icon {
            display: none !important;
        }
    }


    // PR Form;
    .pr-alert-container, .pr-war {
        max-width: 100% !important;
    }
}


.special-message {

    svg {
        margin-right: 5px;
    }

    a {
        color: $color-gray;
        font-weight: bold;

        &:hover {
            color: $color-red;
            text-decoration: none;
        }
    }
}