﻿@font-face {
	font-family: 'Icons';
	src: url('/common/fonts/SRAM.woff2') format('woff2'), url('/common/fonts/SRAM.woff') format('woff');
	font-display: swap;
}

$font-icons: 'Icons';

$icon-search: "\e900";
$icon-short-arrow: "\e908";
$icon-medium-arrow: "\e904";
$icon-long-arrow: "\e903";
$icon-carat: "\e907";
$icon-close: "\e91f";
$icon-menu: "\e909";
$icon-remove: "\e90a";
$icon-clear: "\e90b";
$icon-wrench: "\e90c";
$icon-checkmark: "\e90d";
$icon-alert: "\e90e";
$icon-globe: "\e90f";
$icon-location: "\e910";
$icon-play: "\e911";
$icon-quotes: "\e912";
$icon-skip: "\e913";
$icon-question: "\e91a";

$icon-facebook: "\e905";
$icon-instagram: "\e901";
$icon-youtube: "\e902";

.long-arrow-prev {
	font-size: 12px;

	&:before {
		color: $color-red;
		font-family: Icons;
		font-size: 8px;
		content: "\e903";
		display: inline-block;
		transform: rotateY(180deg);
		margin-right: 4px;
	}
}