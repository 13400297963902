.error .custom-select {
	border-color: $color-red;
}

.custom-select {
	position: relative;
	border: 1px solid $color-light-gray;

	&::after {
		content: $icon-carat;
		font-family: $font-icons;
		font-size: 12px;
		display: block;
		position: absolute;
		top: 10px;
		right: 23px;
		transform: rotate(90deg);
		z-index: 0;
	}

	select {
		font-size: 12px;
		line-height: 50px;
		font-family: $font-bold;
		letter-spacing: 1.5px;
		border: 0;
		box-shadow: none;
		background: transparent;
		height: 100%;
		width: 100%;
		cursor: pointer;
		outline: none;
		padding-right: 56px;
		padding-left: 19px;
		-moz-appearance: none;
		-webkit-appearance: none;
		text-transform: uppercase;
		position: relative;
		z-index: 1;

		&:-moz-focusring {
			color: transparent;
			text-shadow: 0 0 0 #000000;
		}

		&::-ms-expand {
			display: none;
		}
	}
}
