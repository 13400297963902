﻿body.quarq {
	.footer {
		&-branding {
			svg {
				width: 150px;
				height: 50px;
			}
		}
	}
}
