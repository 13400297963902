﻿:root {
	--c-story-promo-single-background: var(--page-background, --color-xxlight-gray);
	--c-story-promo-single-story-background: var(--color-white);
	--c-story-promo-single-subhead-color: var(--color-red);
	--c-story-promo-single-headline-color: inherit;
	--c-story-promo-single-description-color: var(--color-dark-gray);
}

.story-promo-single {
	position: relative;

	&-wrapper {
		position: relative;
		padding-top: 60px;

		@include screen-xl-max {
			padding-top: 43px;
		}
	}

	.container {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 130px;
		z-index: 2;

		@include screen-lg-max {
			position: static;
			background: var(--c-story-promo-single-background);
			margin-bottom: -109px;
		}

		@include screen-md-max {
			padding-bottom: 10px;
			margin-bottom: -40px;
		}
	}

	&-header {
		background: $color-black;
		color: $color-white;
		padding: 35px 80px;
		position: absolute;
		top: 0;
		z-index: 3;
		order: 1;

		@include screen-xl-max {
			padding: 22px 40px;
		}
	}

	&-story {
		background: var(--c-story-promo-single-story-background);
		position: absolute;
		padding: 161px 82px 85px 119px;
		z-index: 2;
		bottom: -40px;
		right: 0;
		width: 48.6956521739%;

		@include ie11 {
			right: -270px;
		}

		@include screen-xl-max {
			padding: 50px;
		}

		@include screen-lg-to-xl-max {
			right: 10%;
			width: 46%;
			padding: 8% 8% 5% 6.5%;
		}

		@include screen-lg-max {
			position: relative;
			padding: 50px 50px 50px;
			bottom: auto;
			right: auto;
			left: auto;
			top: -109px;
			width: 100%;
		}

		@include screen-md-max {
			top: -40px;
			padding: 50px 26px 30px;
		}

		&-subhead {
			color: var(--c-story-promo-single-subhead-color);
			font-size: 12px;
			font-family: $font-bold;
			margin-bottom: 20px;
			letter-spacing: 1.5px;

			@include screen-lg-max {
				margin-bottom: 10px;
			}
		}

		h3 {
			margin-bottom: 20px;
			color: var(--c-story-promo-single-headline-color);

			@include screen-lg-max {
				margin-bottom: 10px;
			}
		}

		&-description {
			color: var(--c-story-promo-single-description-color);
			margin-bottom: 30px;

			@include screen-lg-max {
				margin-bottom: 24px;
			}
			ul, ol {
				padding-left: 16px;
			}
			li {
				list-style: disc;
			}
		}
	}

	&-background {
		position: relative;
		z-index: 1;
		padding-bottom: 130px;
		background: var(--c-story-promo-single-background);

		@include screen-lg-max {
			padding-bottom: 65px;
		}

		@include screen-md-max {
			padding-bottom: 0;
		}

		&-inner {
			position: relative;
			z-index: 1;
			width: 75%;
			padding-top: 49.725%;
			order: 2;
			background-position: center center;
			background-size: cover;

			@include screen-lg-to-xl-max {
				width: 73%;
			}

			@include screen-lg-max {
				width: 100%;
			}
		}
	}

	&--left {
		.story-promo-single-header {
			right: 0;
		}

		.story-promo-single-story {
			right: auto;
			left: 0;

			@include screen-lg-to-xl-max {
				left: 10%;
			}
		}

		.story-promo-single-background {
			.story-promo-single-background-inner {
				margin-left: 25%;

				@include screen-lg-max {
					margin-left: 0;
				}
			}
		}
	}
}
