﻿.job-detail {
	padding-top: 69px;

	@include screen-md-max {
		padding-top: 39px;
	}

	h6 {
		font: 12px/1 $font-bold;
		color: $color-dark-gray;
		margin-bottom: 16px;

		@include screen-md-max {
			margin-bottom: 8px;
		}
	}

	h3 {
		@include screen-md-max {
			margin-bottom: 13px;
		}
	}

	h5 {
		margin-bottom: 6px;
	}

	ul {
		li {
			position: relative;
			padding-left: 18px;
			line-height: 26px;
			margin-bottom: 10px;

			&::before {
				position: absolute;
				left: 0;
				content: '';
				height: 4px;
				top: 10px;
				width: 4px;
				background-color: $color-red;
			}
		}
	}

	&-header {
		padding-bottom: 70px;

		@include screen-md-max {
			padding-bottom: 54px;
		}
	}

	&-apply {
		margin-top: 40px;
		padding-bottom: 32px;

		@include screen-md-max {
			margin-top: 32px;
			padding-bottom: 0;
		}
	}

	&-section {
		margin-bottom: 32px;
	}

	&-sidebar {
		@include screen-md-max {
			margin-top: 50px;
		}
	}

	&-promo {
		margin-bottom: 30px;

		img {
			display: block;
			width: 100%;
			height: auto;
			margin-bottom: 10px;
		}

		a {
			display: block;
			position: relative;

			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				height: 26px;
				width: 26px;
				z-index: 3;
				background: rgba(0, 0, 0, 0.5);
				border-radius: 100%;
				color: $color-red;
				transform: translate(-50%, -50%);
			}

			&::after {
				position: absolute;
				top: 50%;
				left: 50%;
				z-index: 4;
				font-size: 30px;
				font-family: $font-icons;
				content: $icon-play;
				color: $color-red;
				transform: translate(-50%, -50%);
			}
		}

		p {
			font-size: 12px;
			line-height: 1.5em;
		}
	}
}
