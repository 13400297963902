﻿.product-listing {
	&-banner {
		position: relative;
		background-size: cover;
		background-repeat: no-repeat;
		height: 18.75vw;

		@include screen-lg-max {
			height: 45.333vw;
		}

		.container {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			width: 100%;
			margin: 0 auto;
			transform: translate(0, -50%);
			text-align: center;
		}

		h2 {
			margin-bottom: 10px;
		}
	}

	&-toolbar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 29px;

		@include screen-lg-max {
			display: block;

			h4, .custom-select {
				display: none;
			}
		}

		.button {
			position: relative;
			border-color: $color-xlight-gray;
			width: 50%;
			text-align: center;
		}

		#btn-product-refine {
			display: none;

			@include screen-lg-max {
				display: block;
				width: 100%;
				margin-bottom: 20px;
			}
		}
	}

	&-content {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -18px;

		@include screen-lg-max {
			margin: 0;
		}
	}

	&-item {
		width: 25%;
		padding: 0 18px;
		margin-bottom: 50px;
		text-align: center;

		@include screen-xl-max {
			width: 33%;
		}

		@include screen-lg-max {
			width: 50%;
		}

		@include screen-md-max {
			padding: 0 20px;
		}

		&-image {
			width: 100%;
			padding-top: 100%;
			position: relative;
			display: none;

			img {
				position: absolute;
				top: 50%;
				left: 0;
				right: 0;
				margin: 0 auto;
				display: block;
				width: 80%;
				height: 80%;
				transform: translate(0, -50%);
			}

			&--vertical {
				img {
					width: auto;
				}
			}

			&--horizontal {
				img {
					height: auto;
				}
			}

			&--selected {
				display: block;
			}
		}

		&-name {
			font: 500 16px/1.25em $font-medium;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: $color-black;
			margin-bottom: 10px;

			@include screen-xs-max {
				font-size: 12px;
			}
		}

		&-sku {
			font-family: $font-mono;
			color: $color-dark-gray;
			font-size: 12px;
			margin: 10px 0 10px 0;
			line-height: 1.4;
		}

		&-price {
			color: $color-dark-gray;
			font-family: $font-mono;
			line-height: 1.4;
		}

		&-swatches {
			min-height: 53px;
			padding: 0 0 27px;
			display: none; //temporary removed - not in use
			.product-swatches {
				display: flex;
				justify-content: center;

				li {
					margin: 0 4px;
				}
			}
		}
	}

	&-card,
	&-card-alt {
		width: 25%;
		padding: 0 18px;
		margin-bottom: 50px;
		text-align: center;

		@include screen-xl-max {
			width: 33%;
		}

		@include screen-lg-max {
			width: 50%;
		}

		@include screen-md-max {
			padding: 0 20px;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	&-card-alt {
		width: 50%;

		@include screen-xl-max {
			width: 66%;
		}

		@include screen-lg-max {
			width: 100%;
		}

		@include screen-md-max {
			width: 100%;
			padding: 0 20px;
		}
	}
}

.no-product-image {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	margin: 0 auto;
	display: block;
	transform: translateY(-50%);
	line-height: 0;

	svg {
		width: 60px;
		height: 60px;
		fill: #BDC1C6;

		@include screen-sm-max {
			height: 45px;
			width: 45px;
		}

		@include screen-xs-max {
			height: 40px;
			width: 40px;
		}
	}
}

.new-product {

	&--active {
		display: block;
		min-height: 32px;
		background-color: $color-black;
		padding: 2px 10px 0 10px;
		margin: 10px auto;
		width: fit-content;

		span {
			font-weight: 700;
			text-transform: uppercase;
			color: $color-white;
		}

		&.left {
			margin: 10px 0;
		}
	}

	&--inactive {
		display: block;
		min-height: 32px;
		padding: 2px 10px 0 10px;
		margin: 10px auto;

		span {
			display:none;
		}
	}
}

.product-detail--vertical .product-detail-gallery {
	.new-product--active {
		display: none;
	}
}
