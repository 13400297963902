﻿body.zipp {
	.footer {
		&-branding {
			svg {
				width: 300px;
				height: 15px;
			}
		}
	}
}
