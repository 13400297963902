﻿:root {
	--video-inline-background: var(--page-background, #fff);
}

.inline-video {
	background: var(--video-inline-background);
	&-wrapper {
		position: relative;
		padding-top: 56.25%;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	h4 {
		margin-top: 15px;
	}
}
