﻿.product-highlight-carousel {
	&-inner {
		position: relative;
		z-index: 1;
		display: flex;

		@include screen-lg-max {
			display: block;
			padding-bottom: 44px;
		}
	}

	&-nav {
		position: absolute;
		top: 0;
		left: 12px;
		z-index: 3;

		@include screen-lg-max {
			left: 0;
			height: 100%;
			width: 100%;
		}

		h4 {
			font: bold 12px/1 $font-bold;
			color: $color-red;
			margin: 0 0 60px 18px;

			@include screen-lg-max {
				margin: 20px 0 0 20px;
			}
		}

		&-link {
			display: inline-block;
			padding: 8px 18px;
			transition: background-color $default-transition;
			margin-bottom: 4px;
			@include screen-xl-max {
				padding: 4px 18px;
				max-width: 30vw;
			}
			@include screen-lg-max {
				display: none;
			}

			h2 {
				color: $color-xdark-gray;
				font-size: 55px;
				line-height: 1.0909em;
				transition: color $default-transition;

				@include screen-xl-max {
					font-size: 40px;
					line-height: 0.938em;
				}
			}

			&:hover, &--active {
				span {
					background-color: $color-xdark-gray;
					box-shadow: 18px 0 0 $color-xdark-gray, -18px 0 0 $color-xdark-gray;
				}

				h2 {
					color: $color-white;
				}
			}
		}

		&-button {
			margin: 82px 0 0 18px;
			@include screen-lg-max {
				position: absolute;
				bottom: 0;
				left: 0;
				margin: 0;
			}
		}
	}

	&-content {
		position: relative;
		width: calc(100% - 260px);
		margin-left: 148px;
		background-color: $color-light-gray;

		@include screen-lg-max {
			width: 100%;
			margin: 0;
		}
	}

	&-item {
		position: relative;
		top: 0;
		left: 0;
		opacity: 0;
		z-index: 1;
		max-height: 0;
		overflow: hidden;
		transition: opacity 0.7s ease-in;

		&--active {
			max-height: 2000px;
			opacity: 1;
			z-index: 2;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}

		&-title {
			display: none;
			@include screen-lg-max {
				display: block;
				background-color: $color-xdark-gray;
				padding: 8px 20px;

				h2 {
					color: $color-white;
				}
			}
		}

		&-content {
			display: flex;
			background-color: $color-xlight-gray;
			padding: 33px 15px 30px;

			@include screen-lg-max {
				display: block;
				padding: 22px 5px 0;
			}

			&-feature {
				padding: 0 15px;
				width: 33%;
				@include screen-lg-max {
					padding-bottom: 22px;
					width: 100%;
				}

				&-title {
					border-bottom: 1px solid $color-xdark-gray;
					padding-bottom: 11px;
					margin-bottom: 11px;
					position: relative;
					min-height: 41px;
				}

				h5 {
					font: 15px/1 $font-mono;
					color: $color-xdark-gray;
					position: absolute;
					bottom: 11px;
				}

				p {
					font-size: 12px;
					line-height: 1.5em;
					color: $color-dark-gray;
				}
			}
		}
	}

	&-sidebar {
		width: 112px;
		background-color: $color-black;
		color: $color-white;
		position: relative;

		@include screen-lg-max {
			position: static;
		}

		&-counter {
			text-align: center;
			margin-top: 50px;

			@include screen-lg-max {
				position: absolute;
				top: 13px;
				right: 20px;
				z-index: 3;
				margin: 0;
			}

			p {
				font: 15px/1.333333 $font-mono;

				@include screen-lg-max {
					font: 12px/1 $font-mono;
					color: $color-black;
					display: inline;
				}
			}
		}

		.carousel-arrows {
			position: absolute;
			bottom: 36px;
			left: 0;
			right: 0;
			margin: 0 auto;

			@include screen-lg-max {
				bottom: auto;
				top: 50vw;
				z-index: 3;
			}

			.carousel-arrows-arrow {
				position: static;
				display: block;
				margin: 0 auto;

				@include screen-lg-max {
					position: absolute;
					margin: 0;
					color: $color-black;
					border-color: $color-black;
					background-color: rgba(255,255,255,0.7);
				}
			}

			.carousel-arrows-arrow--prev {
				transform: rotate(90deg);
				margin-bottom: 30px;

				@include screen-lg-max {
					margin: 0;
					left: 0;
					transform: rotate(0) translate(0, -50%);
				}
			}

			.carousel-arrows-arrow--next {
				transform: rotate(90deg);

				@include screen-lg-max {
					margin: 0;
					right: 0;
					transform: rotate(0) translate(0, -50%);
				}
			}
		}
	}
}
