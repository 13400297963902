﻿.product-detail {
	&-aside-container {
		// position: absolute;
		top: 0;
		bottom: 0;
		right: 0;

		@include screen-xs-max {
			position: initial;
		}
	}

	&-aside {
		background-color: $color-light-gray;
		height: 100%;
		padding: 80px 40px;
	}

	&-gallery {
		position: relative;
		overflow: hidden;
		background-color: $color-white;

		.product-title {
			position: absolute;
			top: 38px;
			left: 0;
			right: 0;
			margin: 0 auto;
			text-align: center;
			z-index: 3;
			padding: 0 $page-gutter;
			transition: opacity $default-transition;

			@include screen-lg-max {
				position: relative;
				display: block;
				top: auto;
				opacity: 1 !important;
				margin-bottom: 10px;
				padding: 20px $page-gutter-sm;
			}
		}

		&-logo {
			display: block;
			position: absolute;
			bottom: 25px;
			right: 30px;
			width: 100px;
			z-index: 3;
			transition: opacity $default-transition;

			img {
				width: 100%;
				max-width: 100px;
				height: auto;
			}

			@include screen-lg-max {
				bottom: 15px;
				opacity: 1 !important;
			}
		}

		&-navigation {
			position: absolute;
			top: 50%;
			left: 30px;
			transform: translateY(-50%);
			width: 60px;
			z-index: 3;

			@include screen-lg-max {
				&-animate {
					animation: bounceLeft 3s normal forwards;
				}
			}

			@include screen-lg-max {
				bottom: 15px;
				left: 20px;
				top: auto;
				transform: none;
				opacity: 1 !important;
				width: auto;
			}

			@include screen-md-max {
				width: 315px;
			}

			@include screen-sm-max {
				width: 260px;
			}

			@include screen-xs-max {
				width: 180px;
			}

			&-thumbnails {
				transition: opacity $default-transition;

				.slick-track {
					width: unset !important;

					@include screen-lg-max {
						max-width: 455px;

						&-animate {
							animation: moveLeft 2.5s normal forwards;
						}
					}

					@include screen-lg-max {
						display: flex;
					}
				}

				li {
					position: relative;
					margin-bottom: 10px;
					cursor: pointer;
					overflow: hidden;
					min-height: 60px !important;
					min-width: 60px;
					max-width: 60px !important;

					@include screen-lg-max {
						margin-bottom: 0;
						margin-right: 5px;
					}

					@include screen-md-max {
						min-height: 40px !important;
						min-width: 40px !important;
						margin-right: 5px;
					}

					img {
						position: absolute;
						left: 50%;
						top: 0;
						width: auto;
						height: 100%;
						transform: translate(-50%, 0);
					}

					&::before {
						content: '';
						top: 0;
						left: 0;
						width: 102%;
						height: 100%;
						position: absolute;
						z-index: 2;
						background: $color-xlight-gray;
						opacity: 0.5;
						transition: opacity $default-transition, background $default-transition;
						border-bottom: 1px solid transparent;
					}

					&.product-detail-gallery-thumbnails-video {
						&::after {
							content: $icon-play;
							font-family: $font-icons;
							font-size: 25px;
							color: $color-black;
							position: absolute;
							z-index: 4;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}
					}
				}

				&-active, li:hover {
					&::before {
						border-bottom: 1px solid $color-red !important;
						background: none !important;
						opacity: 1 !important;
					}
				}
			}

			&-arrow {
				height: 40px;
				position: relative;
				cursor: pointer;
				transition: opacity $default-transition;

				@include screen-lg-max {
					width: 40px;
					display: none;
				}

				&::after {
					position: absolute;
					font-family: $font-icons;
					color: $color-black;
					content: $icon-carat;
					font-size: 20px;
					left: 50%;

					@include screen-lg-max {
						left: auto;
						top: 50%;
					}
				}

				&--alt {
					&::after {
						color: $color-white;
					}
				}

				&--next {
					&::after {
						bottom: 0;
						transform: translateX(-50%) rotate(90deg);

						@include screen-lg-max {
							transform: translateY(-50%);
						}
					}
				}

				&--prev {
					&::after {
						top: 0;
						transform: translateX(-50%) rotate(-90deg);

						@include screen-lg-max {
							transform: translateY(-50%) rotate(-190deg);
						}
					}
				}

				&.slick-disabled {
					opacity: 0;
				}
			}
		}

		&-content {
			position: relative;
			padding-top: 700px;

			@include screen-lg-max {
				//match image height
				padding-top: 85%;
				margin-bottom: 80px;
			}

			@include screen-sm-max {
				padding-top: calc(100% - 20px);
			}
		}

		&-item {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			z-index: 1;
			transition: opacity $default-transition;
			background-position: center center;
			background-size: cover;

			&--active {
				opacity: 1 !important;
				z-index: 2;
			}

			img {
				width: auto;
				height: 500px;
				display: block;
				margin: 120px auto 80px;

				@include screen-lg-max {
					width: 75%;
					height: auto;
					margin: 0 auto;
				}

				@include screen-sm-max {
					width: calc(100% - 40px);
				}
			}

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: none;

				&--show {
					display: block;
				}
			}

			&-play {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				height: 105px;
				width: 105px;
				cursor: pointer;
				z-index: 20;

				&::before {
					position: absolute;
					top: 50%;
					left: 50%;
					height: 105px;
					width: 105px;
					z-index: 3;
					background: rgba(0, 0, 0, 0.5);
					border-radius: 100%;
					transform: translate(-50%, -50%);
					color: $color-red;
					content: '';
				}

				&::after {
					position: absolute;
					top: 50%;
					left: 50%;
					z-index: 4;
					font-size: 100px;
					transform: translate(-50%, -50%);
					font-family: $font-icons;
					content: "\e911";
					color: $color-red;
				}
			}

			&--horizontal {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				min-height: 500px;

				@include screen-xs-max {
					min-height: auto;
				}

				img {
					width: 80%;
					height: auto;
					margin: 0 auto;

					@include screen-md-max {
						width: 100%;
						margin: 15% auto;
					}
				}
			}
		}

		.breadcrumbs {
			position: absolute;
			bottom: 25px;
			left: 30px;
			z-index: 3;
			padding: 0;

			@include screen-lg-max {
				display: none;
			}

			@include screen-lg-max {
				position: relative;
				justify-content: center;
				left: auto;
				bottom: auto;
				opacity: 1 !important;
				padding: 23px $page-gutter-sm 14px;

				li:last-child {
					display: none;
				}
			}
		}

		&-zoom {
			img {
				display: block;
				height: auto;
				margin: 0 auto;
				max-width: 100%;
			}
		}

		.product-swatches {
			position: absolute;
			right: 30px;
			top: 45%;
			transform: translate(-50%, 0);

			li {
				margin-bottom: 16px;
			}
		}
	}

	&-overview {
		padding-top: 100px;
		padding-bottom: 100px;

		@include screen-lg-max {
			padding-top: 50px;
			padding-bottom: 70px;
		}

		&-section-header {
			text-transform: uppercase;
			font-size: 15px;
			line-height: 1.2em;
			letter-spacing: 1.5px;
			font-family: $font-condensed-bold;
			color: $color-dark-gray;
			margin-bottom: 14px;

			&-value {
				color: $color-black;
			}
		}

		&-item-details {
			display: flex;
			margin-top: 52px;

			@include screen-lg-max {
				order: 1;
				justify-content: space-between;
				margin-top: 30px;
				max-width: 460px;
			}

			@include screen-xs-max {
				max-width: 300px;
			}

			&-detail {
				font-family: $font-mono;
				color: $color-black;
				font-size: 20px;
				line-height: 35px;

				.product-detail-overview-section-header {
					margin-bottom: 0;
				}

				p {
					white-space: nowrap;

					@include screen-xs-max {
						font-size: 14px;
					}

					@include screen-md-max {
						margin-top: 0 !important;
					}
				}

				&:first-child {
					margin-right: 193px;

					@include screen-lg-max {
						margin-right: 0;
						margin-bottom: 20px;
					}
				}

				&--color {
					display: none;
				}
			}
		}

		&-description {
			display: flex;
			flex-direction: column;

			&-button {
				display: none;
				text-align: center;
				margin: 40px 0;

				@include screen-lg-max {
					display: block;
					order: 2;
				}
			}

			@include screen-lg-max {
				margin-bottom: 40px;

				p {
					order: 1;
					margin-top: -5px;
				}
			}

			@include screen-sm-max {
				margin-bottom: 10px;
			}

			> .product-detail-overview-section-header {
				@include screen-lg-max {
					display: none;
				}
			}

			&-text {
				transition: all 0.3s ease;

				@include screen-lg-max {
					overflow: hidden;
				}

				&.show-all {
					max-height: unset !important;
				}
			}
		}

		&-cta {
			display: none;

			@include screen-lg-max {
				display: block;
				order: 2;

				a {
					width: 100%;
					text-align: center;
					margin: 40px 0;
				}
			}
		}

		&-list {
			@include screen-lg-max {
				margin-top: 30px;
			}

			ul {
				@include screen-lg-max {
					margin-left: 20px;
				}

				li {
					position: relative;
					font-size: 16px;
					line-height: 26px;
					margin-bottom: 7px;

					&::before {
						position: absolute;
						left: -15px;
						content: '';
						height: 4px;
						top: 10px;
						width: 4px;
						background-color: $color-red;
					}

					@include screen-lg-max {
						display: none;

						&:nth-child(-n+3) {
							display: block;
						}
					}

					@include screen-xs-max {
						font-size: 14px;
					}
				}
			}

			&.show-all {
				display: block;

				ul {
					li {
						display: block;
					}
				}
			}

			&-disclaimer {
				font-size: 12px;
				line-height: 18px;
				letter-spacing: 0.64px;
				color: $color-dark-gray;
				margin-top: 20px;

				a {
					text-decoration: none;
					color: $color-dark-gray;
					border-bottom: 1px solid $color-dark-gray;

					&:hover {
						color: $color-xdark-gray;
						border-color: $color-xdark-gray;
					}
				}

				@include screen-lg-max {
					margin-top: 30px;
				}
			}
		}

		&-readmore {
			color: $color-red;
			margin-top: 15px;
			text-transform: uppercase;
			font-size: 12px;
			line-height: 10px;
			letter-spacing: 1.5px;
			font-family: $font-bold;
			display: none;
			order: 4;
			cursor: pointer;

			&-more,
			&-less {
				display: none;
			}

			@include screen-lg-max {
				display: block;
				order: 1;
				margin-bottom: 4px;

				&-more {
					display: block;
				}
			}
		}
	}

	&--vertical {
		display: flex;
		position: relative;

		@include screen-lg-max {
			display: block;
		}

		.product-detail-gallery {
			width: 59.444%;
			overflow: visible;

			@include screen-lg-max {
				width: 100%;
			}

			.product-title {
				display: none;

				@include screen-lg-max {
					display: block;
					top: auto;
					left: 0;
					right: 0;
					padding: 20px $page-gutter-sm;
					width: 100%;
					text-align: center;
				}
			}

			&-breadcrumbs {
				top: 25px;
				bottom: auto;

				@include screen-lg-max {
					top: auto;
				}
			}

			&-content {
				padding-top: 121.5%;

				@include screen-lg-max {
					margin-bottom: 0;
				}
			}

			&-item {
				img {
					width: auto;
					height: calc(100% - 100px);
					display: block;
					margin: 50px auto;
				}

				&--square {
					img {
						max-width: 500px;
						height: auto;
						position: absolute;
						top: 50%;
						left: 0;
						right: 0;
						transform: translate(0, -50%);
					}
				}
			}

			.product-swatches {
				display: none;
			}
		}

		.product-detail-overview {
			width: 40.556%;
			background-color: $color-xlight-gray;
			padding: 105px 0 70px 0;
			position: relative;

			@include screen-lg-max {
				width: 100%;
				background-color: $color-white;
				padding-top: 50px;

				.product-title {
					display: none;
				}
			}

			.container {
				padding: 0 60px 0 120px;

				@include screen-xl-max {
					padding: 0 40px 0 80px;
				}

				@include screen-lg-max {
					padding: 0 20px;
				}
			}

			&-col {
				width: 100%;
				float: none;
				margin: 0;
				position: relative;
			}

			&-item-details {
				justify-content: space-between;
				width: 100%;
				margin-bottom: 45px;

				&-detail {
					padding-right: 20px;
					width: 50%;
					margin-right: 0;

					&--color {
						display: block;
					}
				}

				&-detail:last-child {
					margin-right: 0;
				}
			}

			.product-swatches {
				display: flex;
				margin-top: 8px;

				li {
					margin-right: 9px;
				}
			}

			&-description {
				display: block;
				padding-bottom: 40px;
			}

			&-model {
				margin-top: 40px;
			}
		}
	}
}
