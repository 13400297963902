﻿.cms-page-banner {
	position: relative;
	z-index: 1;
	background-position: center center;
	background-size: cover;
	height: 41.6667vw;

	@include screen-md-max {
		height: 146.6667vw;
		min-height: 550px;
	}

	.container {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		width: 100%;
		margin: 0 auto;
		transform: translate(0, -50%);
		text-align: center;

		.cms-page-banner-title {
			margin-bottom: 10px;
		}

		h5 {
			max-width: 525px;
			margin: 0 auto;
		}

		p {
			max-width: 525px;
			margin: 0 auto;
			font-size: 20px;
			line-height: 1.5em;
			letter-spacing: 1.25px;
			font-weight: 600;
		}

		.button {
			margin-top: 30px;
		}
	}
			//allow overwrite for 
	&--short, &--short.product-group-banner, &--short.corporate-landing-banner {
		height: 18.75vw;
		overflow: hidden;

		&.center-bottom {
			background-position: 50% 78%;
		}

		@include screen-md-max {
			height: 45.3333vw;
			min-height: auto;

			&.center-bottom {
				background-position: 50% 50%;
			}
		}
	}

}
