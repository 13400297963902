﻿.subsection-landing {

	&-banner {
		position: relative;
		background-size: cover;
		background-repeat: no-repeat;
		background-position-y: center;
		height: 30.75vw;

		.container {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			width: 100%;
			margin: 0 auto;
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
			text-align: center;
		}

		@media (max-width: 1024px) {
			height: 45.333vw;
		}
	}

	.breadcrumbs {
		margin-left: $page-gutter;

		@include screen-lg-max {
			margin-left: $page-gutter-sm;
		}
	}

	&-wrapper {
		display: flex;
		justify-content: space-between;
		padding: 0 30px;

		@include screen-lg-max {
			padding: 0 20px;
		}
	}

	&-sidebar {
		width: 200px;

		@include screen-lg-max {
			display: none;
		}

		&-section {
			border-top: 1px solid $color-dark-gray;
			padding-top: 22px;

			h6 {
				font-size: 12px;
				font-family: $font-bold;
				margin-bottom: 7px;
			}

			ul {
				margin-bottom: 17px;

				li .header-megamenu-section-subtitle {
					margin: 7px 0;
				}

				li:not(:first-child) .header-megamenu-section-subtitle {
					margin-top: 22px;
				}
			}

			a {
				font-size: 14px;
				color: $color-xdark-gray;
			}
		}
	}

	&-content {
		width: calc(100% - 236px);
		padding-right: 118px;

		@include screen-xl-max {
			padding-right: 0;
		}

		@include screen-lg-max {
			width: 100%;
		}

		&-section {
			position: relative;
			background-color: $color-xxlight-gray;
			margin: 40px 0 140px;
			padding-top: 40px;

			&:last-child {
				margin-bottom: 0;
			}

			@include screen-lg-max {
				margin: 60px 0 80px;

				&:first-child {
					margin-top: 15px;
				}
			}

			h3 {
				color: $color-white;
				background-color: $color-red;
				padding: 15px 30px;
				position: absolute;
				top: 0;
				left: 0;
				transform: translate(0, -50%);
			}

			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					width: 25%;
					text-align: center;
					padding: 50px 30px;

					@include screen-xl-max {
						width: 33.3333%;
					}

					@include screen-md-max {
						width: 50%;
						padding: 25px 15px;
					}

					@include screen-xs-max {
						h4 {
							font-size: 12px;
						}
					}

					img {
						display: block;
						width: 100%;
						height: auto;
						margin-bottom: 28px;
					}
				}
			}
		}

		&-offerings {
			margin-top: -40px;

			h3 {
				margin-bottom: 30px;
			}

			img {
				display: block;
				width: 100%;
				height: auto;
			}

			p {
				max-width: 790px;
				margin-top: 30px;
			}
		}
	}
}
