﻿:root {
	--c-social-grid-background: var(--page-background);
	--c-social-grid-header-color: inherit;
	--c-social-grid-follow-us-color: var(--color-red);
}

.social-grid {
	overflow: hidden;
	position: relative;
	margin-top: 130px;
	background: var(--c-social-grid-background);

	&::after {
		background: var(--color-white);
		position: absolute;
		content: '';
		@include span-columns(10);
		margin-left: 0;
		float: none;
		height: 100%;
		left: 0;
		top: 43px;
		z-index: -1;
	}

	@include screen-lg-max {
		&::after {
			width: 100%;
			top: 53px;
		}
	}

	&-header {
		h2 {
			color: var(--c-social-grid-header-color);
			font-family: $font-condensed-bold;

			@include screen-md-max {
				font-size: 40px;
				line-height: 38px;
			}

			@include screen-sm-max {
				font-size: 30px;
				line-height: 38px;
			}
		}

		&-social {
			display: flex;
			height: 130px;
			align-items: flex-end;
			margin-left: 80px;
			justify-content: space-between;

			@include screen-lg-max {
				margin-left: 0;
				height: 55px;
			}

			li {
				color: var(--c-social-grid-follow-us-color);
				font-family: $font-bold;
				font-size: 12px;
				letter-spacing: 1.5px;
				text-transform: uppercase;
			}

			&-icon {
				font-weight: normal;
				text-align: center;
				font-size: 18px;

				a {
					&::before {
						font-family: $font-icons;
						display: block;
						font-size: 20px;
						color: var(--c-social-grid-follow-us-color);
					}
				}

				&--fb {
					a {
						&::before {
							content: $icon-facebook;
						}
					}
				}

				&--ig {
					a {
						&::before {
							content: $icon-instagram;
						}
					}
				}

				&--yt {
					a {
						&::before {
							content: $icon-youtube;
						}
					}
				}
			}
		}
	}

	&-content {
		margin-top: 36px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		@include screen-lg-max {
			justify-content: center;
		}
	}

	&-item {
		position: relative;
		margin-bottom: 30px;

		@include screen-sm-max {
			margin-bottom: 15px;
		}

		img {
			width: 100%;
		}

		a {
			display: block;
			width: 100%;
			height: 100%;
		}

		&-text {
			color: var(--color-white);
			position: absolute;
			bottom: 6px;
			left: 0;
			font-family: $font-bold;
			font-size: 10px;
			line-height: 14px;
			letter-spacing: 1.2px;
			text-transform: uppercase;
			z-index: 2;
			padding: 10px 8px;
			overflow-wrap: anywhere;
			width: 100%;

			&::before {
				font-family: $font-icons;
				display: inline-block;
				position: absolute;
				font-size: 15px;
				top: 1px;
				left: -25px;
			}
			/*  Removing icon classes from social grid. Keeping the original classes in case business decision change on the future.

				&--fb {
				&::before {
					content: $icon-facebook;
				}
			}

			&--ig {
				&::before {
					content: $icon-instagram;
				}
			}

			&--yt {
				&::before {
					content: $icon-youtube;
				}
			}
			*/
		}

		&--wide {
			@include span-columns(4 of 10);
			@include omega();

			@include screen-lg-max {
				@include span-columns(12);
				@include omega();
			}

			img {
				height: auto;
				width: 100%;
			}
		}

		&--narrow {
			@include span-columns(2 of 10);
			@include omega();

			img {
				height: 96.3%;
				width: 100%;
			}

			@include screen-lg-max {
				@include span-columns(5);
				@include omega();

				img {
					height: auto;
				}
			}

			@include screen-sm-max {
				@include span-columns(12);
				@include omega();
			}
		}
	}
}
