﻿.content {
	@include screen-md-max {
		padding-bottom: 0px;
	}
}

.prod-registration-success {
	position: relative;
    padding-top: 120px;
	@include screen-md-max {
		flex-basis: 100%;
		padding-top: 52px;
	}

    h1, h2 {
        color: $color-xxdark-gray;
        text-transform: uppercase;
    }

	.container {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		max-width: 1380px;
		.item {
			flex: 1;
			@include screen-md-max {
				flex-basis: 100%;
			}
		}
	}

	&-asset {
		@include screen-md-max {
			display: none;
		}
		picture {
			width: 100%;
    		display: inline-block;
			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	&-content {
		width: 70%;
		padding-left: 40px;
		@include screen-xl-max {
			width: 75%;
		}
		@include screen-lg-max {
			width: 80%;
			padding-left: 20px;
		}
		@include screen-md-max {
			padding-left: 0px;
			width: inherit;
			h1 {
				padding-bottom: 30px;
			}
		}

		.product-image {
			display: none;
			@include screen-md-max {
				display: inline-block;
				width: 30%;
				img {
					width: 100%;
				}
			}
		}

		.product-content {
			@include screen-md-max {
				display: inline-block;
				width: 68%;
				float: right;
			}
		}

		h1 {
			font-size: 55px;
			letter-spacing: 2px;
			@include screen-lg-max {
				font-size: 48px;
			}
			@include screen-md-max {
				font-size: 36px;
			}
			@include screen-xs-max {
				font-size: 30px;
			}
		}

		h2 {
			font-size: 24px;
			padding-top: 36px;
			letter-spacing: .6px;
			line-height: 1.5em;
			@include screen-md-max {
				font-size: 20px;
				padding-top: 0;
			}
			@include screen-xs-max {
				font-size: 18px;
			}
		}

        .product-registration-serial {
            font-family: $font-condensed-bold;
            color: $color-dark-gray;
            font-size: 24px;
            letter-spacing: .48px;
            line-height: 1;
			@include screen-md-max {
				font-size: 18px;
				letter-spacing: .36px;
			}
        }

		.prod-registration-message {
			font-size: 18px;
			font-weight: 700;
			color: $color-xxdark-gray;
			line-height: 28px;
			padding: 24px 0 0 6px;
		}

		.prod-registration-success {
			&::before {
				content: url(/common/img/confirmation-check.svg);
				display: inline-block;
				position: relative;
				width: 24px;
				height: 24px;
				top: 6px;
				left: -6px;
			  }
		}

		.prod-registration-problem {
			&::before {
				content: url(/common/img/red-x.svg);
				display: inline-block;
				position: relative;
				width: 24px;
				height: 24px;
				top: 6px;
				left: -6px;
			  }
		}

		.prod-registration-problem-helper-txt {
			padding: 12px 0 0 0;
			font-size: 14px;
			color: $color-xxdark-gray;
			text-align: center;
			a {
				font-weight: 700;
				text-decoration: underline;
				color: $color-xxdark-gray;
			}
		}

		.button {
			margin-top: 40px;
			width: 100%;
			@include screen-md-max {
				padding: 0;
			}	
		}

		.prod-registration-contact-us {
			display: block;
			font-size: 14px;
			color: $color-xxdark-gray;
			padding-top: 10px;
			text-align: center;
			a {
				font-weight: 700;
				text-decoration: underline;
			}
		}
	}
}
