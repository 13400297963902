.parallax-product-promo {
	scroll-behavior: smooth;
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;

	@include screen-lg-max {
		background: $color-xxdark-gray;
		height: auto;
	}

	&-section {
		opacity: 0;
		height: 100%;
		width: 100%;
		position: absolute;
		transition: opacity 1s ease-in;

		@include screen-lg-max {
			opacity: 1;
			position: relative;
			height: auto;
		}

		&--show {
			z-index: 2;
			opacity: 1;
		}

		&-container {
			display: block;
			position: absolute;
			z-index: 10;
			color: $color-white;
			top: 50%;
			width: 40%;
			transform: translateY(-50%);
			opacity: 0;
			transition: all 1s ease-in;

			@include screen-lg-max {
				position: static;
				width: 100%;
				opacity: 1;
				transform: translate(0, 0) !important;
				padding: 160px 20px 410px 20px;
			}

			@include screen-xs-max {
				padding-top: 140px;
				padding-bottom: 390px;
			}

			&--left {
				transform: translate(-100%, -50%);
				left: 100px;
			}

			&--right {
				transform: translate(100%, -50%);
				right: 70px;
			}

			&--show {
				opacity: 1;
				transform: translate(0, -50%);
			}

			h3 {
				margin-bottom: 19px;

				@include screen-lg-max {
					margin-bottom: 6px;
				}
			}

			p.description {
				color: $color-gray;
				padding-right: 96px;

				@include screen-lg-max {
					padding-right: 0;
				}
			}
		}

		&-video {
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;

			@include screen-lg-max {
				top: auto;
				bottom: 0;
				height: 350px;
			}

			&--right {
				video {
					right: 0;
				}
			}

			&--left {
				video {
					left: 0;
				}
			}

			video {
				display: block;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}
	}

	&-buttons {
		position: absolute;
		bottom: 19px;
		right: 30px;
		z-index: 10;
		display: flex;

		@include screen-lg-max {
			display: none;
		}

		&--dark {
			.parallax-product-promo-buttons-button {
				color: $color-xdark-gray;

				&::after {
					border-color: $color-xdark-gray;
				}

				&:hover {
					&::before {
						color: $color-white;
					}

					&::after {
						background: $color-xdark-gray;
						border-color: $color-white;
					}
				}
			}
		}

		&-button {
			color: $color-xdark-gray;
			position: relative;
			cursor: pointer;
			font-size: 12px;
			letter-spacing: 0.64px;
			display: block;
			text-align: center;
			padding-top: 40px;
			white-space: nowrap;
			transition: background $default-transition;
			margin-right: 20px;
			min-width: 40px;

			&:last-child {
				margin-right: 0;
			}

			&::after {
				content: '';
				display: block;
				width: 40px;
				height: 40px;
				border: 1px solid $color-xdark-gray;
				position: absolute;
				top: 0;
				left: 50%;
				z-index: 1;
				transform: translate(-50%, 0);
			}

			&::before {
				position: absolute;
				display: block;
				top: 4px;
				left: 50%;
				z-index: 2;
				font-family: $font-icons;
				font-size: 22px;
				transform: translate(-50%, 0);
			}

			&:hover {
				&::before {
					color: $color-xdark-gray;
				}

				&::after {
					background: $color-white;
					border-color: $color-xdark-gray;
				}
			}

			&-skip {
				&::before {
					content: $icon-skip;
				}
			}

			&-top {
				&::before {
					content: $icon-carat;
					transform: rotate(-90deg);
					margin-left: -8px;
				}
			}
		}
	}
}
