@font-face {
	font-family: 'Owners-Regular';
	font-display: swap;
	src: url('/common/fonts/owners_regular.woff') format('woff');
}

@font-face {
	font-family: 'Owners-Narrow-Black';
	font-display: swap;
	src: url('/common/fonts/owners_narrow_black.woff') format('woff');
}

$font-rockshox-regular: "Owners-Regular", "Helvetica", "Arial", sans-serif;
$font-rockshox-bold: "Owners-Narrow-Black", "Helvetica", "Arial", sans-serif;

body.rockshox .content {
	h1, h2, h3, h4, h5 {
		font-family: $font-rockshox-bold;
	}

	h1 {
		line-height: 1.3em;
		letter-spacing: 10px;
	}

	h1.cms-page-banner-title, h1.product-title {
		font-size: 70px;
		line-height: 0.9285em;
		letter-spacing: 5.25px;

		@include screen-lg-max {
			font-size: 60px;
		}

		@include screen-md-max {
			font-size: 46px;
		}

		@include screen-xs-max {
			font-size: 36px;
		}
	}

	h2, h3 {
		font-size: 50px;
		line-height: 1em;
		letter-spacing: 1.25px;

		@include screen-lg-max {
			font-size: 42px;
		}

		@include screen-md-max {
			font-size: 34px;
		}

		@include screen-xs-max {
			font-size: 26px;
		}
	}

	h3.product-title {
		@include screen-xs-max {
			font-size: 34px;
		}
	}

	h2.page-navigation-title {
		font-size: 36px;
	}

	.hero-carousel h2, .hero-carousel h3 {
		font-size: 100px;
		@include screen-lg-max {
			font-size: 80px;
		}
		@include screen-md-max {
			font-size: 60px;
		}
		@include screen-xs-max {
			font-size: 40px;
		}
	}

	p {
		font-family: $font-rockshox-regular;
		letter-spacing: .07em;
	}

	.button {
		font-family: $font-rockshox-regular;
	}


	.footer-newsletter {
		h3 {
			font: 20px Roboto-Bold,Helvetica,Arial,sans-serif;
			color: #fff;
			letter-spacing: 1.75px;
			margin-bottom: 20px;
		}
	}
}
