@mixin screen-xl-max {
	@media (max-width: $screen-xl) { @content; }
}

@mixin screen-lg-max {
	@media (max-width: $screen-lg) { @content; }
}

@mixin screen-md-max {
	@media (max-width: $screen-md) { @content; }
}

@mixin screen-sm-max {
	@media (max-width: $screen-sm) { @content; }
}

@mixin screen-xs-max {
	@media (max-width: $screen-xs) { @content; }
}

@mixin screen-lg-to-xl-max {
	@media (min-width: $screen-lg) and (max-width: $screen-xl) { @content; }
}

@mixin ie11 {
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { @content; }
}
