﻿.story-promo-2up {
	background: $color-xxlight-gray;

	&-item {
		@include screen-md-max {
			margin-bottom: 50px;
		}

		h4 {
			margin-bottom: 2px;
		}

		p {
			color: $color-dark-gray;
		}

		&-button {
			margin-top: 20px;
		}
	}
}
