﻿.collapse {
	&-trigger {
		position: relative;
		cursor: pointer;

		&::after, &::before {
			content: '';
			display: block;
			width: 20px;
			height: 2px;
			position: absolute;
			right: 10px;
			top: 50%;
			margin-top: -2px;
			background-color: $color-red;
		}

		&::before {
			transform: rotate(90deg);
			transition: transform $default-transition;
		}
	}

	&-target {
		overflow: hidden;
		max-height: 0;
		transition: max-height $default-transition;
	}

	&--open {
		.collapse-trigger {
			&::before {
				transform: rotate(0);
			}
		}

		.collapse-target {
			max-height: inherit;
		}
	}

	&--mobile {
		.collapse-trigger {
			cursor: default;

			&::after, &::before {
				display: none;
			}
		}

		.collapse-target {
			max-height: none;
		}

		@include screen-lg-max {
			.collapse-trigger {
				cursor: pointer;

				&::after, &::before {
					display: block;
				}
			}

			.collapse-target {
				max-height: 0;
			}

			&.collapse--open {
				.collapse-target {
					max-height: inherit;
				}
			}
		}
	}
}
