﻿.dealer-locator {
	position: relative;

	&-map {
		h3 {
			position: absolute;
			width: 30%;
			padding: 0 30px;
			top: 34px;
			left: 0;
			z-index: 1;

			@include screen-lg-max {
				position: static;
				width: 100%;
				padding: 36px 20px 20px;
			}
		}

		@include screen-lg-max {
			#storemapper {
				display: flex;
				flex-direction: column;
			}
		}

		#storemapper-left {
			height: calc(100vh - 119px) !important;
			width: 30%;
			padding-top: 88px;
			overflow-y: hidden !important;

			@include screen-lg-max {
				width: 100%;
				height: auto !important;
				order: 2;
				padding-top: 36px !important;
				min-height: 300px;
			}
		}

		#storemapper-right {
			width: 70%;
			height: calc(100vh - 119px) !important;

			@include screen-lg-max {
				width: 100%;
				height: 300px !important;
				order: 1;
			}

			.storemapper-popup {
				a {
					color: $color-red;
				}

				p {
					margin-bottom: 4px;
				}
			}
		}

		#storemapper #storemapper-form {
			padding: 0 30px;
			box-shadow: none;

			@include screen-lg-max {
				padding: 0 20px;
			}

			.storemapper-form-inner {
				position: relative;
			}

			#storemapper-zip {
				padding-right: 60px;
			}

			#storemapper-go {
				position: absolute;
				right: 0;
				top: 0;
				background-color: transparent;
				margin: 0;
				color: $color-gray;
				width: 50px;
				height: 50px;
				padding: 0;
				overflow: hidden;
				white-space: nowrap;
				border: 0;

				&:hover {
					background-color: transparent;
					color: $color-dark-gray;
				}

				&::before {
					content: $icon-long-arrow;
					font-family: $font-icons;
					font-size: 13px;
					position: absolute;
					top: 17px;
					right: 0;
				}
			}

			::-webkit-input-placeholder {
				color: $color-dark-gray;
				opacity: 1;
			}

			::-moz-placeholder {
				color: $color-dark-gray;
				opacity: 1;
			}

			:-ms-input-placeholder {
				color: $color-dark-gray !important;
				opacity: 1;
			}
		}

		#storemapper #storemapper-list {
			background-color: transparent;
			padding: 23px 30px 40px;
			overflow: auto;
			height: calc(100vh - 257px);

			@include screen-lg-max {
				height: auto;
				padding-left: 20px;
				padding-right: 20px;
			}

			li, li.storemapper-selected {
				box-shadow: none;
				padding: 19px 0 14px 0;
				border-bottom: 1px solid $color-light-gray;
				background-color: transparent;
				position: relative;

				h4 {
					color: $color-red;
					font-size: 15px;
					letter-spacing: 1.5px;
					margin-bottom: 11px;
					padding-right: 90px;
				}

				p {
					padding-left: 0;
					color: $color-xdark-gray;
					font-size: 14px;
					font-family: $font-regular;

					a {
						color: $color-xdark-gray;
					}

					svg {
						display: none;
					}
				}

				.storemapper-distance {
					font-size: 15px;
					font-family: $font-mono;
					color: $color-gray;
					top: 14px;
					right: 0;
					position: absolute;
				}

				.storemapper_view .storemapper-storelink {
					color: $color-dark-gray;
					font-size: 12px;
					border-bottom: 1px solid $color-dark-gray;
					padding-bottom: 2px;

					&:hover {
						color: $color-xdark-gray;
						border-color: $color-xdark-gray;
					}
				}
			}
		}

		#storemapper.show_distance #storemapper-form .storemapper-distance,
		// .storemapper-categories,
		#storemapper .storemapper-scroll-top,
		small {
			display: none !important;
			height: 0 !important;
			overflow: hidden !important;
		}

		.storemapper-categories {
			position: absolute;
			top: -12px;
			display: none;
		}

		#storemapper label.checkbox {
			margin-right: 10px;
			font-size: .75rem;

			input {
				margin-right: 5px;
			}
		}
	}

	&-mobile-title {
		display: none;

		@include screen-md-max {
			display: block;
			text-align: center;
		}
	}

	&-partners {
		padding-top: 90px;

		h4 {
			text-align: center;
			margin-bottom: 52px;

			@include screen-md-max {
				span {
					display: none;
				}
			}
		}

		ul, ul > li {
			border-style: solid;
			border-color: $color-light-gray;
		}

		ul {
			display: flex;
			flex-wrap: wrap;
			border-width: 1px 0 0 1px;
		}

		li {
			position: relative;
			width: 25%;
			padding-top: calc(150 / 287 * 25%);
			border-width: 0 1px 1px 0;

			@include screen-md-max {
				width: 50%;
				padding-top: calc(150 / 287 * 50%);
			}
		}
		// want image box to be 205 x 64 at full size (287 x 150)
		a {
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			width: calc(205 / 287 * 100%);
			height: calc(64 / 150 * 100%);
			transform: translate(-50%, -50%);
		}

		img {
			position: absolute;
			display: block;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			max-height: 100%;
			max-width: 200px;
		}
	}

	&-link {
		text-align: right;
		margin-top: 30px;
	}
}
