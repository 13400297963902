﻿:root{
	--c-rich-text-color: var(--font-color);
	--c-rich-text-background: var(--page-background);
	--c-rich-text-heading-color: var(--heading-color);
}
.rich-text {
	background: var(--c-rich-text-background);
	color: var(--c-rich-text-color);

	h1, h2, h3, h4, h5, h6 {
		margin-bottom: 0.5em;
		color: var(--c-rich-text-heading-color);
	}

	p {
		margin-bottom: 1.25em;
		color: var(--c-rich-text-color);
	}

	a:not(.button) {
		color: $color-red;

		&:hover {
			color: #f50529;
		}
	}

	ul, ol {
		margin: 1.5em 0;

		li {
			position: relative;
			line-height: 1.625em;
			margin-bottom: 10px;
			padding-left: 18px;

			&::before {
				position: absolute;
				left: 0;
				content: '';
				height: 4px;
				top: 10px;
				width: 4px;
				background-color: $color-red;
			}
		}
	}
}
