body.rockshox {
	.footer {
		&-branding {
			svg {
				width: 112px;
				height: 28px;
			}
		}
	}
}
