body.modal-open {
	overflow-y: hidden;
}

.modal-content {
	display: none;
}

.modal-trigger {
	//cursor: pointer;
}

#modal {
	background: $color-white;
	display: none;
	width: 100vw;
	height: 100vh;
	margin: 0;
	padding: 0;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 999;
	overflow-x: visible;
	overflow-y: auto;
	opacity: 0;
	transition: opacity 0.3s ease-out;

	&.show {
		opacity: 1;
	}

	&.hide {
		opacity: 0;
	}

	.inner-wrapper {
		position: relative;
	}

	.modal-content {
		position: relative;
		display: block;

		iframe {
			width: 100%;
			height: calc(100vh - 120px);
			position: relative;
			margin-top: 80px;
		}
	}

	.btn-close-modal {
		position: fixed;
		height: 30px;
		width: 30px;
		top: 30px;
		right: 30px;
		z-index: 99;
		cursor: pointer;
		color: $color-black;
		text-align: center;

		&::after {
			content: $icon-close;
			font-family: $font-icons;
			font-size: 16px;
		}
	}
}

//----- simple modal

.simple-modal {
	z-index: 9;
	display: none;
	padding-top: 50px;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0,0,0);
	background-color: rgba(0,0,0,0.6);
	@media (max-width: $screen-sm) { 
		padding-top: 0;
	}


	.simple-modal-container {
		margin: auto;
		background-color: $color-white;
		position: relative;
		padding: 0;
		outline: 0;
		width: 100%;
		max-width: 427px;
		border-radius: 0;
		border: 1px solid rgba(0, 0, 0, 0.3);
		-webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
		-moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
		box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
		-webkit-background-clip: padding-box;
		-moz-background-clip: padding-box;
		@media (max-width: $screen-sm) { 
			max-width: fit-content;
		}

		.simple-modal-content {
			padding: 30px 40px;
			min-height: 300px;
			@media (max-width: $screen-sm) { 
				padding: 20px;
			}

			h2 {
				font-size: 24px;
				text-transform: uppercase;
				color: $color-xxdark-gray;
				padding-top: 8px;
			}

			h3 {
				font-size: 20px;
				text-transform: uppercase;
				color: $color-xxdark-gray;
				margin: 20px 0 0 0;
			}

			.product-registration-image {
				width: 100%;
				height: 300px;
				text-align: center;
				margin-top: 20px;
				img {
					width: 300px;
					height: 300px;	
				}
			}

			.product-registration-serial {
				font-family: $font-condensed-bold;
				color: $color-dark-gray;
				font-size: 18px;
				letter-spacing: .36px;
				line-height: 1;
				margin-top: 6px;
			}

			.btn-close-modal {
				position: relative;
				display: block;
				height: 24px;
				width: 100%;
				z-index: 99;
				cursor: pointer;
				color: $color-black;
				border: 0;
				margin-top: 0;
				text-align: right;
				background-color: inherit;

				&:hover {
					opacity: 0.85;
				  }

				&::before, &::after {
					position: absolute;
					content: ' ';
					height: 24px;
					top: 0;
					right: 12px;
					width: 1px;
					background-color: $color-black;
				  }

				  &::before {
					transform: rotate(45deg);
				  }
				  &::after {
					transform: rotate(-45deg);
				  }
			}

			h4 {
				font-size: 14px;
				text-transform: inherit;
				margin-top: 20px;
				margin-bottom: 0;
			}

			h4, label, p.gray {
				color: $color-dark-gray;
			}

			label {
				text-transform: capitalize;
				display: inline-block;
				margin: 8px 5px 5px 5px;
			}

			input {
				color: $color-xdark-gray;
				background-color: $color-white;
				border: 1px solid $color-gray;
				padding-right: 20px;
				padding-left: 20px;

				&.newsletterCheckBox {
					zoom: 1.5;
					filter: saturate(0.1);
				}

				&:focus {
					border-bottom: 1px solid $color-gray;
					padding-bottom: 14px;
					outline: 0;
				}

				&.error {
					border-bottom: 2px solid $color-red;
					padding-bottom: 14px;
				}
			}

			input:-webkit-autofill,
			input:-webkit-autofill:hover,
			input:-webkit-autofill:focus,
			input:-webkit-autofill:active {
				-webkit-box-shadow: none !important;
			}

			input:-webkit-autofill {
				-webkit-text-fill-color: $color-black !important;
			}

			::-webkit-input-placeholder {
				color: $color-dark-gray;
				opacity: 1;
			}

			::-moz-placeholder {
				color: $color-dark-gray;
				opacity: 1;
			}

			:-ms-input-placeholder {
				color: $color-dark-gray !important;
				opacity: 1;
			}

			.message--error {
				color: $color-red;
			}

			#newsletter-items {
				padding: 20px 0;
			}

			.modal-footer {
				text-align: center;
				.product-registration-footer-txt {
					padding: 12px 0 0 0;
					font-size: 14px;
					color: $color-xxdark-gray;
					a {
						font-weight: 700;
						text-decoration: underline;
						color: $color-xxdark-gray;
					}
				}
			}
		}
	}
}
