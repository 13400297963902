﻿.corporate-landing {
	&-banner {
		position: relative;
		z-index: 2;
		background-position: center center;
		background-size: cover;
		height: 51.389vw;

		@include screen-md-max {
			height: 160vw;

			&--video {
				height: 41.4vw;
			}
		}

		video {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			display: block;
			width: 100%;
			height: auto;
		}

		&-wrapper {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			z-index: 2;
			margin: 0 auto;
			transform: translate(0, -50%);
		}

		&-content {
			text-align: center;

			h2, h1 {
				color: $color-white;
				font-size: 55px;
			}
		}

		&-button {
			margin-top: 13px;
		}
	}

	.story-promo-single {
		&-background {
			background-color: $color-white;
			padding-bottom: 0;
		}

		.container {
			bottom: 0;

			@include screen-lg-max {
				background: $color-white;
			}
		}
	}
}
