﻿.localization-menu {
	position: relative;

	&:hover {
		.localization-menu-popup {
			max-height: 200px;
		}
	}

	&:focus-within {
		.localization-menu-popup {
			max-height: 200px;
		}
	}

	@include screen-lg-max {
		&--active {
			.localization-menu-popup {
				max-height: 200px !important;
			}
		}

		&:hover {
			.localization-menu-popup {
				max-height: 0;
			}
		}

		& > a {
			display: block;
			text-align: center;
			padding: 20px 0;
			border: 0;
			text-decoration: none !important;

			@include screen-lg-max {
				padding: 18px 0 19px;
			}

			&::after {
				content: $icon-carat;
				font-family: $font-icons;
				display: inline-block;
				margin-left: 5px;
				position: relative;
				top: 1px;
				transform: rotate(90deg);
			}
		}
	}

	&-popup {
		position: absolute;
		top: 35px;
		z-index: 999;
		right: -30px;
		min-width: 160px;
		box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.08);
		max-height: 0;
		overflow: hidden;
		transition: max-height $default-transition;

		@include screen-lg-max {
			width: 100%;
			top: 52px;
			left: 0;
			right: auto;
		}

		li {
			margin: 0;
		}

		a {
			display: block;
			font: 14px/44px $font-regular;
			color: $color-black;
			text-transform: none;
			letter-spacing: 0.5px;
			padding: 0 20px;
			border: 0;
			background-color: $color-white;

			&.active, &:hover {
				text-decoration: none;
				background-color: $color-xlight-gray;
			}
		}
	}

	&--footer {
		.localization-menu-popup {
			top: auto;
			bottom: 35px;

			@include screen-lg-max {
				right: auto;
				left: -20px;
				bottom: 58px;
			}
		}
	}
}
