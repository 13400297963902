﻿.custom-checkbox {
	input[type="checkbox"] {
		display: none;
	}

	span {
		position: relative;
		padding-left: 25px;
		line-height: 1.25em;
		display: block;
		cursor: pointer;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 1px;
			height: 14px;
			width: 14px;
			border: 1px solid $color-dark-gray;
		}
	}

	input[type="checkbox"]:checked + span::after {
		content: $icon-checkmark;
		font-family: $font-icons;
		font-size: 8px;
		position: absolute;
		left: 3px;
		top: -1px;
		height: 15px;
		width: 15px;
	}

	&--disabled span {
		opacity: 0.5;
	}
}
