﻿body.timesport {
    .header {
        &-branding {
            top: 60px;

            @include screen-lg-max {
                top: 15px;
            }

            svg {
                width: 144px;
                height: 36px;

                @include screen-lg-max {
                    width: 120px;
                    height: 30px;
                }
            }
        }
    }
}